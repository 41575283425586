import { HgbSelectOption } from '@common/forms';
import { API_URLS } from '@constants/API_URLS';
import { LanguageContext } from '@contexts/LanguageContext';
import { debug } from '@utils/debug';
import { convertObjectToTree, getParentKey } from '@utils/object.utils';
import { joinSafe } from '@utils/text';
import { LocalDataClass } from 'data-class/LocalDataClass';
import exactmath from 'exact-math';
import { isEmpty } from 'lodash';
import { useContext, useMemo } from 'react';
import { useHgbQuery } from 'services/common/query';
import {
  BaseOrganizationListBoxRequest,
  BaseOrganizationListBoxResponse,
} from 'services/types/base';
import {
  GetCategoryListBoxRequest,
  GetCategoryListBoxResponse,
} from 'services/types/category';
import {
  GetScopeListBoxRequest,
  GetScopeListBoxResponse,
} from 'services/types/scope';
import {
  GetFiscalYearMonthListBoxRequest,
  GetFiscalYearMonthListBoxResponse,
  GetLastTenYearListBoxRequest,
  GetLastTenYearListBoxResponse,
} from 'services/types/year';
import { MANUAL_NAMEDDESTS } from '../Support';
import {
  Emission,
  FlowInputDetail,
  InputInfo,
  OffsetFields,
  SelectionInfo,
  Step1Fields,
  Step2Fields,
} from './type';

export const unitByRange = (intensity: InputInfo) => {
  if (!isEmpty(intensity.constraints)) {
    return `${intensity.constraints.MIN_VALUE} - ${intensity.constraints.MAX_VALUE}`.replaceAll(
      'null',
      '',
    );
  }
  return '';
};

export const offsetAmountUnitOptions = [
  { label: 'tCO2', value: 'tCO2' },
  { label: 'kgCO2', value: 'kgCO2' },
];

export const getResultFromFormula = (
  _values: Record<string, any>,
  formula?: null | string,
) => {
  const ExactMath = exactmath;
  ExactMath.pow = Math.pow;
  ExactMath.log = Math.log;
  ExactMath.E = Math.E;
  let variables = getParamsFromFormula(formula || '');
  // formula = formula?.replaceAll('Math.', 'ExactMath.');
  try {
    let result = '';
    eval(
      `   
        const {${variables.join(',')} } = _values;
        variables.forEach(item=>{
          formula = formula.replace(item, eval(item) || document.querySelector('[name="'+item+'"]').value);
        })
        result = eval(formula);
      `,
    );
    if (result.toString() === 'NaN' || result.toString() === 'Infinity') {
      result = '';
    }

    return result;
  } catch (error) {
    debug.log({ error });
    return '';
  }
};

export const getParamsFromFormula = (formula: string) => {
  let arr: string[] = [];
  const charactersToReplace = ['*', '/', '+', '-', '(', ')'];
  charactersToReplace.forEach((char) => {
    formula = `${formula.replaceAll(char, ',')}`;
  });

  arr = formula
    .split(',')
    .map((e) => e.trim())
    .filter(
      (value) =>
        Number(value).toString() === 'NaN' &&
        !value?.includes('Math.') &&
        (value || '').trim() !== '',
    );
  return Array.from(new Set(arr));
};

export const STEP1_DEFAULT_VALUE: Step1Fields = {
  targetYearMonth: '',
  targetFiscalYear: '',
  baseId: '',
  scopeCode: '',
  categoryCode: '',
};

export const STEP2_DEFAULT_VALUE: Step2Fields = {
  emissionUnit: '',
  calculationTargetCode: '',
  calculationMethodCode: '',
  scope2MethodSelected: '',
  fuelCode: '',
  activityVolume: undefined,
  activityVolumeUnit: '',
  emissionIntensity: undefined,
  emissionIntensityUnit: '',
  emissionVolume: '',
  emissionVolumeUnit: '',
  modelCode: '',
  refrigerantNo: '',
  fillingVolume: undefined,
  fillingVolumeUnit: '',
  recoveryVolume: undefined,
  recoveryVolumeUnit: '',
  useEmissionCoefficient: undefined,
  useEmissionCoefficientUnit: '',
  gwp: undefined,
  gwpUnit: '',
  activityName: '',
  supplyMenuCode: '',
  retailElectricCalculationMethodCode: '',
  operatorGraspRate: undefined,
  operatorGraspRateUnit: '',
  heatUsageCode: '',
  departmentCode: '',
  rawActivityVolume: undefined,
  rawEmissionIntensity: undefined,
  rawTransportActivityVolume: undefined,
  rawTransportEmissionIntensity: undefined,
  capitalWasteActivityVolume: undefined,
  capitalWasteEmissionIntensity: undefined,
  energyTypeCode: '',
  electricCompanyNo: '',
  transportTypeCode: '',
  transportDistance: undefined,
  transportDistanceUnit: '',
  transportCargoWeight: undefined,
  transportCargoWeightUnit: '',
  transportMaximumCargoWeight: undefined,
  transportMaximumCargoWeightUnit: '',
  loadPercentageCode: '',
  transportLoadRate: undefined,
  transportLoadRateUnit: '',
  consumptionTransportTypeCode: '',
  consumption: undefined,
  consumptionUnit: '',
  radioButton1: '',
  radioButton2: '',

  numberOfPeople: undefined,
  numberOfPeopleUnit: '',
  numberOfNight: undefined,
  numberOfNightUnit: '',
  numberOfDay: undefined,
  numberOfDayUnit: '',
  businessTravelTypeCode: '',

  transportProduct: '',
  employeeNumberBasedTypeCode: '',
  nightStayTypeCode: '',

  fuelEconomyBasic: undefined,
  fuelEconomyBasicUnit: '',

  officeCityClassificationCode: '',

  buildingUseCode: '',
  distribution: undefined,
  distributionUnit: '',

  expectedLifetimeUsagePer: undefined,
  expectedLifetimeUsagePerUnit: '',
  saleCount: undefined,
  saleCountUnit: '',
  perLessonUsage: undefined,
  perLessonUsageUnit: '',
  capitalGoodsTypeCode: '',
  emissionLifetimeUse: undefined,
  greenHouseGasCode: '',
  businessDaysYear: undefined,
  expectedLifetimeUsage: undefined,
  expectedLifetimeUsagePercentage: undefined,
  unclearActivityVolume1: undefined,
  unclearActivityVolume2: undefined,
  unclearActivityVolume3: undefined,
  unclearActivityVolume4: undefined,
  unclearActivityVolume5: undefined,
  unclearActivityVolume6: undefined,
  unclearActivityVolume7: undefined,
  unclearActivityVolume8: undefined,
  unclearActivityVolume9: undefined,
  unclearActivityVolume10: undefined,
  unclearActivityVolume11: undefined,
  unclearActivityVolume12: undefined,
  unclearEmissionIntensity1: undefined,
  unclearEmissionIntensity2: undefined,
  unclearEmissionIntensity3: undefined,
  unclearEmissionIntensity4: undefined,
  unclearEmissionIntensity5: undefined,
  unclearEmissionIntensity6: undefined,
  manualIdeaInput: undefined,
  emissionIntensityLocation: undefined,
  emissionVolumeLocation: '',
  emissionIntensityLocationUnit: '',
  emissionVolumeLocationUnit: '',
  fuelEfficiencyCategory: undefined,
  companyGasSelected: undefined,
};

export const OFFSET_DEFAULT: OffsetFields = {
  offsetAmount: undefined,
  offsetAmountUnit: '',
  offsetCertificateDate: '',
  offsetTarget: '',
  projectName: '',
  creditType: '',
};

export const useLastTenYearsListBox = () => {
  const lastTenYearsListBoxQuery = useHgbQuery<
    GetLastTenYearListBoxResponse,
    GetLastTenYearListBoxRequest
  >(API_URLS.LAST_TEN_YEAR_API_URL, undefined);

  const options = useMemo(
    () =>
      (lastTenYearsListBoxQuery.data?.result || [])
        .map(({ name: label, value }) => ({ label, value }))
        .filter((word) => Number(word.value) > 2018),
    [JSON.stringify(lastTenYearsListBoxQuery.data?.result)],
  );
  return { options };
};

export const useCategory = (scope: string) => {
  const categoryQuery = useHgbQuery<
    GetCategoryListBoxResponse,
    GetCategoryListBoxRequest
  >(
    API_URLS.CATEGORY_LIST_BOX_API_URL,
    { scopeCode: '3' },
    {
      enabled: scope === '3',
      queryKey: [API_URLS.CATEGORY_LIST_BOX_API_URL],
    },
  );
  const options = useMemo(
    () =>
      (categoryQuery.data?.result || []).map(
        ({ name: label, value, note }) => ({
          label: scope !== '3' ? '' : joinSafe([label, note], ' : '),
          value,
        }),
      ),
    [JSON.stringify(categoryQuery.data?.result), scope],
  );
  return { options };
};

export const useScope = () => {
  const {
    text: { common },
  } = useContext(LanguageContext)!;
  const scopeQuery = useHgbQuery<
    GetScopeListBoxResponse,
    GetScopeListBoxRequest
  >(API_URLS.SCOPE_LIST_BOX_API_URL);
  const options = useMemo(
    () => [
      ...(scopeQuery.data?.result || []).map((item) => ({
        label: joinSafe([item.name, item.note], ' : '),
        value: item.value,
      })),
      { label: common.label.offset, value: '4' },
    ],
    [JSON.stringify(scopeQuery.data?.result), common.label.offset],
  );
  return { options };
};

export const useFiscalYearMonth = (targetYear: string) => {
  const fiscalYearMonthQuery = useHgbQuery<
    GetFiscalYearMonthListBoxResponse,
    GetFiscalYearMonthListBoxRequest
  >(
    API_URLS.FISCAL_YEAR_MONTH,
    {
      targetYear,
      isLCIDBYear: false,
    },
    {
      enabled: !!targetYear,
      queryKey: [API_URLS.FISCAL_YEAR_MONTH, targetYear],
    },
  );

  const options = useMemo(() => {
    let years = fiscalYearMonthQuery.data?.result || [];
    return years.map((item) => ({ ...item, label: item.name }));
  }, [JSON.stringify(fiscalYearMonthQuery.data?.result)]);
  return { options };
};

export const useBase = () => {
  const basesQuery = useHgbQuery<
    BaseOrganizationListBoxResponse,
    BaseOrganizationListBoxRequest
  >(
    API_URLS.ORGANIZATION_BASE,
    {
      isLoadInactive: false,
    },
    {
      queryKey: [API_URLS.ORGANIZATION_BASE],
    },
  );

  const options = useMemo(
    () =>
      convertObjectToTree(
        (basesQuery?.data?.result || []).filter(
          (item) => !getParentKey(item.hierarchicalPath ?? ''),
        ) as any,
        (basesQuery?.data?.result || []) as any,
      ),
    [JSON.stringify(basesQuery?.data?.result)],
  );

  return { options };
};

export const uniqPropertyArray = <T>(arr: T[], key: keyof T) => {
  return arr.filter((item, index, arr) =>
    arr.slice(0, index).every((info) => info[key] !== item[key]),
  );
};

export const isSearchSelect = (
  fieldTarget: string,
  scopeCode: string,
  categoryCode: string,
) => {
  const searchList = [
    'fuelCode',
    'electricCompanyNo',
    'officeCityClassificationCode',
    'greenHouseGasCode',
    'calculationMethodCode',
    'capitalGoodsTypeCode',
    'departmentCode',
    'heatUsageCode',
  ];
  if (scopeCode === '1' || scopeCode === '2') {
    return searchList.includes(fieldTarget);
  } else {
    if (fieldTarget === 'fuelCode') return categoryCode === '01';
    return searchList.includes(fieldTarget);
  }
};

export const createSelectioInfoOptions = (
  options: { value: string; name: string }[] = [],
  fieldTarget: string,
): HgbSelectOption[] => {
  let result = options.map(({ name, value }) => ({ value, label: name }));
  if (fieldTarget === 'supplyMenuCode') {
    result = result.sort((p1, p2) =>
      p1.value > p2.value ? 1 : p1.value < p2.value ? -1 : 0,
    );
  }
  return result;
};

export const getInputType = (targetField: string) => {
  return ['numberOfPeople', 'numberOfDay', 'numberOfNight'].includes(
    targetField,
  )
    ? 'positiveInteger'
    : 'positiveDecimal';
};

export const getInfoLink = (fieldTarget: string) => {
  return fieldTarget === 'scope2MethodSelected'
    ? '/support/manual/' + MANUAL_NAMEDDESTS.about_market_location_base
    : '';
};

export const preventInfinity = (value?: string | number) => {
  const valueStr = value?.toString();
  if (valueStr === 'Infinity' || valueStr === 'NaN') return '';
  return valueStr || '';
};

export const preventNull = (value?: string) => {
  return (value || '').replaceAll('null', '');
};

export const getIntersectionFieldTarget = (
  name: string,
  additionalSelectionInfos: SelectionInfo[],
) => {
  const { length } = additionalSelectionInfos;
  if (length === 0) return name;
  if (additionalSelectionInfos.some((item) => item.fieldTarget === name)) {
    return additionalSelectionInfos.at(-1)!.fieldTarget;
  }
  return name;
};

export const preValidateInputInfoCondition = (
  name: keyof Step2Fields,
  flowInputDetails: FlowInputDetail[],
) => {
  const checkers: InputInfo[] = [];
  flowInputDetails
    .map((flow) => flow.inputGroup)
    .map((input) => {
      input?.forEach((rs) => {
        if (rs.fieldTarget === name) {
          checkers.push(rs);
        }
      });
    });
  return checkers.length > 0;
};

export const preValidateIntensityCondition = (
  name: keyof Step2Fields,
  flowInputDetails: FlowInputDetail[],
) => {
  const checkers: InputInfo[] = [];
  flowInputDetails
    .map((flow) => flow.intensity)
    .filter((s) => s)
    .forEach((intensity) => {
      if (intensity.disabled === false && intensity.fieldTarget === name) {
        checkers.push(intensity);
      }
    });
  return checkers.length > 0;
};

export const unitFlowInputDetails = (flowInputDetails: FlowInputDetail[]) => {
  const extractedUnits: Record<string, string> = {};

  flowInputDetails.forEach((group) => {
    if (group.inputGroup) {
      group.inputGroup.forEach((input) => {
        const key = `${input.fieldTarget}Unit`;
        extractedUnits[key] = input.unit;
      });
    }
    if (group.intensity) {
      const key = `${group.intensity.fieldTarget}Unit`;
      extractedUnits[key] = group.intensity.unit;
    }
  });

  return extractedUnits;
};

export const createEmissionApiLink = (
  scopeCode: string,
  categoryCode: string,
) => {
  if (scopeCode === '4') return API_URLS.OFFSET_API_URL;

  const scopePart = `/scope-${scopeCode}`;
  let categoryPart = '';
  if (categoryCode !== '00')
    categoryPart = `/category-${Number(categoryCode)}/save`;

  return `/api/v1/emission-input${scopePart}${categoryPart}`;
};

export const isValidStep1Submit = (
  targetFiscalYear: string,
  targetYearMonth: string,
  baseId: string,
  scopeCode: string,
  categoryCode: string,
) => {
  return ![
    targetFiscalYear,
    targetYearMonth,
    baseId,
    scopeCode,
    categoryCode,
  ].includes('');
};

export const disableStep2Submit = (
  formula = '',
  emission: Emission,
  checkFlowFinished: boolean,
) => {
  let disabled = false;
  const { scopeCode } = emission;

  if (scopeCode === '4') {
    const { offsetAmount, offsetCertificateDate } = emission;
    if (isEmpty(offsetAmount?.toString()) || isEmpty(offsetCertificateDate)) {
      disabled = true;
    }
    return disabled;
  }

  const keyFormula = getParamsFromFormula(formula);
  const keyEmission = Object.keys(emission);

  keyEmission.forEach((key) => {
    const isEmptyEmissionValue = isEmpty(emission[key]?.toString());
    if (isEmptyEmissionValue) {
      switch (key) {
        case 'emissionVolume':
          disabled = isEmpty(emission?.emissionIntensityLocation?.toString());
          break;
        case 'emissionVolumeLocation':
          disabled =
            emission.scope2MethodSelected === 'ML0002' && scopeCode === '2';
          break;
        case 'activityName':
          disabled = scopeCode === '3';
          break;
        default:
          break;
      }
      if (keyFormula.includes(key)) {
        disabled = true;
      }
    }
  });
  return disabled || !checkFlowFinished;
};

export const createFlowApi = (scope: string, category: string) => {
  return `api/v1/emission-input${scope}/flow${category}?lang=${LocalDataClass.language}`;
};

export const isFullData = (
  selectionInfos: SelectionInfo[],
  params: Record<string, any>,
) => {
  return selectionInfos.every(
    (selection) => (params[selection.fieldTarget] || '') !== '',
  );
};

export const shouldResetNextSelections = (
  currentNextSelections: SelectionInfo[],
  fieldTarget: string,
  nextStepSelectionInfos: SelectionInfo[],
) => {
  // Nếu có nextStepSelectionInfos thì cho phép nối thêm và reset
  if (nextStepSelectionInfos.length > 0) return true;

  // Nếu không có thì check lại fieldTarget vừa chọn có nằm trong đống currentNextSelections hay không, nếu có thì không cho phép reset
  if (
    currentNextSelections.length > 1 &&
    currentNextSelections.map((item) => item.fieldTarget).includes(fieldTarget)
  ) {
    return false;
  }

  // Nếu không nằm trong 2 trường hợp trên thì cho phép reset
  return true;
};
