export const API_URLS = {
  ACCOUNT_MY_ACCOUNT: '/api/account/my-account',
  ACCOUNT_LOGIN: '/api/v1/login',
  ACCOUNT_LOGOUT: '/api/v1/logout',
  ACCOUNT_REGISTER: '/api/account/register',
  USER_API_URL: '/api/v1/users',
  TOKEN_REFRESH: 'api/v1/reissue-token',
  PASSWORD_REISSUE: '/api/v1/reissue-password',
  CHANGE_PASSWORD: '/api/v1/change-password',
  ROLE_API_URL: '/api/v1/list-box/role',
  DEPARTMENT_API_URL: '/api/v1/list-box/department',
  BANK_API_URL: '/api/v1/banks',
  INVOICE_ISSUE_DATE: '/api/v1/list-box/invoice-issue-date',
  EMISSION_TARGETS: '/api/v1/emission-targets',
  ENTERPRISE_API_URL: '/api/v1/enterprises',
  APPROVE_ENTERPRISE_API_URL: '/api/v1/approve-deny-enterprise',
  SCOPE_LIST_BOX_API_URL: '/api/v1/list-box/scope',
  CATEGORY_LIST_BOX_API_URL: '/api/v1/list-box/category',
  SCOPE_3_CATEGORY_LIST_BOX_API_URL: '/api/v1/list-box/scope3-category',
  BASE_ORGANIZATION_API_URL: '/api/v1/bases',
  BASE_LISTBOX_API_URL: '/api/v1/list-box/base',
  BASE_CLASSIFICATION: '/api/v1/radio-button/base-classification',
  ORGANIZATION_LISTBOX_API_URL: '/api/v1/list-box/organization',
  ORGANIZATION_BASE: '/api/v1/list-box/organization-base',
  EMISSION_TARGET_YEAR: '/api/v1/list-box/emission-target-year',
  BANK_LIST_BOX_API_URL: '/api/v1/list-box/management-bank',
  ENTERPRISE_LIST_BOX_API_URL: '/api/v1/list-box/enterprise',
  FISCAL_YEAR_MONTH: '/api/v1/list-box/fiscal-year-month',
  CREATE_EMISSION_TARGET_SCOPE1: '/api/v1/emission-input/scope-1',
  DASHBOARD_API_URL: '/api/v1/emission-output/dashboard',
  EMISSION_DATA_YEAR_LISTBOX: '/api/v1/list-box/emission-data-year',
  EXTERNAL_LINK_API_URL: '/api/v1/external-links',
  OFFSET_API_URL: '/api/v1/offsets',
  LAST_TEN_YEAR_API_URL: '/api/v1/list-box/last-ten-fiscal-year',
  EMISSION_OUTPUT_DATA_LIST: '/api/v1/emission-output/data-list',
  LAST_TEN_FISCAL_YEAR: '/api/v1/list-box/last-ten-fiscal-year',
  FAQ: '/api/v1/support/faq',
  MANUAL_API_URL: '/api/v1/support/manual',
  EMISSION_OUTPUT_REPORT: '/api/v1/emission-output/report',
  ENTERPRISE_OUTPUT_REPORT: '/api/v1/enterprise-output/report',
  PDF_UPLOAD_API_URL: '/api/v1/emission-input/ocr/upload',
  CSV_TEMPLATE_DOWNLOAD_API_URL: '/api/v1/emission-input/download-csv-template',
  LCIDB_TEMPLATE_DOWNLOAD_API_URL: '/api/v1/lci-db',
  CSV_UPLOAD_API_URL: '/api/v1/emission-input/upload-csv',
  BANK_NAME_API_URL: '/api/v1/bank-name',
  RECALCULATION_EMISSION_DATA_API: '/api/v1/emission-input/calculate-again',
  CHECK_LCIDB_YEAR_API_URL: '/api/v1/target-lci-db-year',
  HOME_MESSAGE: '/api/v1/emission-output/home-message-modal',
  DASHBOARD_MONTHLY_CHART: '/api/v1/emission-output/dashboard/column-chart',
  DASHBOARD_MONTHLY_CHART_INSERT: '/api/v1/scope-checkbox',
  MESSAGE_API_URL: '/api/v1/home-message',
  MESSAGE_TYPE_API_URL: '/api/v1/list-box/home-message-type',
  MESSAGE_RANGE_API_URL: '/api/v1/list-box/home-message-range',
  MESSAGE_HISTORY_API_URL: '/api/v1/emission-output/home-message-history',
  MESSAGE_INSERT_API_URL: '/api/v1/emission-output/user-home-message',
  BANK_NAME_LISTBOX: '/api/v1/list-box/bank-area',
  TEMPORARY_REGISTER_API_URL: '/api/v1/temporarily-register',
  PAYMENT_TYPE_LISTBOX: '/api/v1/list-box/method-type',
  HAVE_NONE_LISTBOX: '/api/v1/list-box/have-none',
  CHECK_DURATION_API_URL: '/api/v1/check-temporarily-register-expiration',
  PRIVACY_POLICY_API_URL: '/api/v1/bank-private-policy-url',
  ENTERPRISE_REGISTER_API_URL: '/api/v1/enterprise-landing-page',
  CSRF_API_URL: '/api/v1/csrf-token',
  HIGO_BANK_LANDING_PAGE_API_URL: '/api/v1/presiding-bank',
  ENTERPRISE_STATUS_API_URL: '/api/v1/enterprise-status-filter',
  ENTERPRISE_PENDING_API_URL: '/api/v1/temporary-applications',
  PICID_LISTBOX_API_URL: '/api/v1/list-box/enterprise-admin',
  PICEMAIL_API_URL: '/api/v1/pic-email',
  INSERT_CARD_API_URL: '/api/v1/insert-card',
  FOUR_MEM_ADD_API_URL: '/api/v1/four-mem-add',
  MERCHANT_INFORMATION: 'api/v1/merchant-information',
  SCOPE2_MARKET_LOCATION: '/api/v1/scope2-market-location',
  UPDATE_CARD_API_URL: '/api/v1/update-card-info',
  SEND_EMAIL_BANK_COMPLIMENTARY: 'api/v1/send-email-bank-complimentary',
  LIST_INTRODUCERS: 'api/v1/introducers',
  CREATE_INTRODUCER: 'api/v1/introducers',
  INTRODUCER_NAME: 'api/v1/introducer-name',
  GET_INFO_BUYER: 'api/v1/supply-chain-buyer-name',
  BUYER_CHAIN: 'api/v1/supply-chain-buyers',
  SUPPLIER_CHAIN: 'api/v1/supply-chain-supplier',
  SUPPLIER_PRODUCT: 'api/v1/supplier-products',
  BUYER_SUPPLIER_RESPONSE: 'api/v1/buyer/supplier-responses',
  SUPPLIER_SUPPLIER_RESPONSE: 'api/v1/supplier/supplier-responses',
  LISTBOX_GROUP_BUYER: 'api/v1/list-box/group-buyers',
  LISTBOX_SUPPLY_CHAIN: 'api/v1/list-box/supply-chain-group-names',
  LISTBOX_COMPANY_SUPPLIER: 'api/v1/list-box/company-supplier',
  BUYER_SUPPLIER_EMISSION: 'api/v1/buyer/supplier-emission',
  LISTBOX_CATEGORY_RESPONSE: 'api/v1/list-box/response-category',
  LISTBOX_SUPPLIER_NAME: 'api/v1/list-box/supplier-names',
  LISTBOX_SUPPLIER_PRODUCT_NAME: 'api/v1/list-box/supplier-product-names',
  REGISTER_SUPPLIER_EMISSION: 'api/v1/buyer/supplier-emission/register',
  SUPPLIER_CHAIN_RESPONSE_FLOW: 'api/v1/supply-chain-response/flow',
  SUPPLIER_CHAIN_RESPONSE_SAVE: 'api/v1/supply-chain-response/save',
  SUPPLIER_CHAIN_RESPONSE_UPDATE: 'api/v1/supply-chain-response',
  LIST_ID_REMOVE: 'api/v1/list-id-remove',
  GET_CURRENT_YEAR: '/api/v1/get-current-year',
  CHECK_SUPPLIER_RESPONSE: 'api/v1/check-supplier-response',
  CHECK_BUYER: '/api/v1/check-buyer',
  ENERGY_CERTIFICATE: '/api/v1/energy-certificate',
  DEDUCTION_TARGET:'api/v1/energy-certificate/method-code-by-target-code',
  CERTIFICATE_TYPE:'api/v1/energy-certificate/deduction-target-by-method-code',
  CERTIFICATE_ACTIVITIES: 'api/v1/energy-certificate/certificate-activities'
};

export const QUERY_KEYS = {
  EMISSION_LIST: 'EMISSION_LIST',
  BUYER_CHAIN_LIST: 'BUYER_CHAIN_LIST',
  SUPPLIER_CHAIN_LIST: 'SUPPLIER_CHAIN_LIST',
  LISTBOX_GROUP_BUYER: 'LISTBOX_GROUP_BUYER',
  LISTBOX_SUPPLY_CHAIN: 'LISTBOX_SUPPLY_CHAIN',
  LISTBOX_SUPPLIER_PRODUCT_NAME: 'LISTBOX_SUPPLIER_PRODUCT_NAME',
  D17_D19_H02_TABLE_LIST: 'D17_D19_H02_TABLE_LIST',
  ENERGY_CERTIFICATE: 'ENERGY_CERTIFICATE'
};
