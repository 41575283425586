import { HgbAntdButton } from '@common/antd/HgbAntdButton';
import { HgbAntdModal } from '@common/antd/HgbAntdModal';
import HgbAntdUpload from '@common/antd/HgbAntdUpload';
import HgbInfoLink from '@common/contents/HgbInfoLink';
import { HgbSelect } from '@common/forms';
import { HgbTreeSelect } from '@common/forms/HgbTreeSelect/HgbTreeSelect';
import { API_URLS } from '@constants/API_URLS';
import { REACT_APP_UPLOAD_OCR_URL } from '@constants/consts';
import { routs_en } from '@constants/ROUT';
import { LanguageContext } from '@contexts/LanguageContext';
import { PortalContext } from '@contexts/PortalContext';
import { DataTableTemplate, ManagementTemplate } from '@layouts/templates';
import { hgbAxios } from '@utils/axios';
import { Radio } from 'antd';
import { isEmpty, isUndefined } from 'lodash';
import {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { C02Step1Request } from 'services/types/C02';
import {
  useBase,
  useFiscalYearMonth,
  useLastTenYearsListBox,
} from '../C01/utils';
import { MANUAL_NAMEDDESTS } from '../Support';
import UploadModal, { UploadModalProps } from './UploadModal';
import { currentYearMonth } from '@utils/date.util';

const C02: FunctionComponent = () => {
  const {
    text: { C01, common },
    language,
  } = useContext(LanguageContext)!;
  const { addMessage } = useContext(PortalContext)!;
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  const step1Methods = useForm<C02Step1Request>({
    defaultValues: {
      baseId: '',
      file: undefined,
      radio: '1',
      scopeCode: '',
      targetFiscalYear: '',
      targetYearMonth: '',
    },
  });

  const [uploadModalProps, setUploadModalProps] = useState<UploadModalProps>({
    step1: undefined,
  });

  const targetFiscalYearOptions = useLastTenYearsListBox().options;
  const targetYearMonthOptions = useFiscalYearMonth(
    step1Methods.watch().targetFiscalYear,
  ).options;

  useEffect(() => {
    const value = (targetYearMonthOptions ?? [])
      .map((item) => item.value)
      .find((s) => s === currentYearMonth())
      ? currentYearMonth()
      : targetYearMonthOptions?.[0]?.value;
    step1Methods.setValue('targetYearMonth', value ?? '');
  }, [JSON.stringify(targetYearMonthOptions)]);

  const scopeOptions = useMemo(() => {
    if (step1Methods.watch().radio === '1')
      return [
        { label: common.label.scopeNote1, value: '1' },
        { label: common.label.scopeNote2, value: '2' },
      ];
    return [{ label: common.label.offset, value: '4' }];
  }, [step1Methods.watch().radio, language]);

  const baseOptions = useBase().options;

  const onSubmit = () => {
    setOpenConfirm(true);
  };

  // const { watch, setValue, step1Methods.handleSubmit } = step1Methods;
  // const step1Methods.watch() = watch();

  // const { data: yearLBQuery, isFetching: isYearLBFetching } = useHgbQuery<
  //   GetLastTenYearListBoxResponse,
  //   GetLastTenYearListBoxRequest
  // >(API_URLS.LAST_TEN_YEAR_API_URL, undefined);

  // const { data: monthLBQuery, isFetching: isMonthLBFetching } = useHgbQuery<
  //   GetFiscalYearMonthListBoxResponse,
  //   GetFiscalYearMonthListBoxRequest
  // >(
  //   API_URLS.FISCAL_YEAR_MONTH,
  //   { targetYear: step1Methods.watch().targetFiscalYear, isLCIDBYear: false },
  //   {
  //     queryKey: [
  //       step1Methods.watch().targetFiscalYear,
  //       API_URLS.FISCAL_YEAR_MONTH,
  //     ],
  //     enabled: !isEmpty(step1Methods.watch().targetFiscalYear),
  //   },
  // );

  // const { data: basesQuery, isFetching: isbasesQueryFetching } = useHgbQuery<
  //   BaseOrganizationListBoxResponse,
  //   BaseOrganizationListBoxRequest
  // >(API_URLS.ORGANIZATION_BASE, undefined);

  // const targetFiscalYearOptions = useMemo(() => {
  //   const rs = parseToHgbSelectOptions(yearLBQuery?.result).filter(
  //     (word) => Number(word.value) > 2018,
  //   );
  //   return rs;
  // }, [yearLBQuery?.result, isYearLBFetching]);

  // const targetYearMonthOptions = useMemo(() => {
  //   const value = (monthLBQuery?.result ?? [])
  //     .map((item) => item.value)
  //     .find((s) => s === currentYearMonth())
  //     ? currentYearMonth()
  //     : monthLBQuery?.result?.[0].value;
  //     step1Methods.setValue('targetYearMonth', value ?? '');
  //   return parseToHgbSelectOptions(monthLBQuery?.result);
  // }, [monthLBQuery?.result, isMonthLBFetching]);

  // const baseOptions: HgbTreeSelectOption[] = useMemo(() => {
  //   const bases = basesQuery?.result ?? [];
  //   return convertObjectToTree(
  //     bases.filter((item) => !getParentKey(item.hierarchicalPath ?? '')),
  //     bases,
  //   );
  // }, [basesQuery?.result]);

  const submitFile = async () => {
    setLoading(true);
    const { baseId, targetFiscalYear, targetYearMonth, file, scopeCode } =
      step1Methods.watch();
    const formData = new FormData();
    formData.append('baseId', baseId);
    formData.append('targetFiscalYear', targetFiscalYear);
    formData.append('targetYearMonth', targetYearMonth);
    formData.append('scopeCode', scopeCode ?? '');
    formData.append('file', file!);
    // mutate(formData);
    try {
      const response = await hgbAxios(undefined, REACT_APP_UPLOAD_OCR_URL).post(
        API_URLS.PDF_UPLOAD_API_URL,
        formData,
      );
      if (response) {
        setLoading(false);
        const { result } = response.data;
        setOpen(true);
        setUploadModalProps({
          step1: step1Methods.watch(),
          scope: {
            activityVolume: result?.activityVolume,
            activityVolumeUnit: result?.activityVolumeUnit,
          },
          offset: {
            offsetAmount: result?.offsetAmount,
            offsetCertificateDate: result?.offsetCertificateDate,
            offsetAmountUnit: result?.offsetAmountUnit,
          },
          step1Options: {
            targetFiscalYearOptions,
            targetYearMonthOptions,
            baseOptions,
            scopeOptions,
          },
        });
      }
    } catch (error: any) {
      addMessage('error', error?.response?.data?.message);
      setLoading(false);
    }
  };

  const disableSubmit = useMemo(() => {
    const values = Object.values(step1Methods.watch());
    return !values.every((item) => !isEmpty(item) && !isUndefined(item));
  }, [JSON.stringify(step1Methods.watch())]);

  const navigate = useNavigate();

  const radioOptions = useMemo(
    () => [
      { label: C01.label.invoice, value: '1' },
      { label: C01.label.certificate, value: '2' },
    ],
    [language],
  );

  return (
    <ManagementTemplate>
      <DataTableTemplate title={C01.label.uploadFile}>
        <FormProvider {...step1Methods}>
          <form
            onSubmit={step1Methods.handleSubmit(onSubmit)}
            className="tw-mt-24 tw-flex tw-justify-center tw-overflow-y-scroll"
          >
            <div className="tw-flex tw-flex-col tw-gap-24">
              <div>
                <label
                  htmlFor={'radio'}
                  className="tw-mb-8 tw-block tw-text-paragraph tw-font-bold"
                >
                  {C01.label.fileType}
                </label>
                <Radio.Group
                  value={step1Methods.watch('radio')}
                  className="tw-flex tw-flex-wrap tw-gap-24"
                  onChange={(e) => {
                    step1Methods.setValue('radio', e.target.value);
                  }}
                >
                  {radioOptions.map((item) => (
                    <Radio value={item.value} key={`item-${item.value}`}>
                      <div className="-tw-mx-8 -tw-ml-4 tw-flex tw-w-180 tw-gap-8">
                        {item.label}
                        <HgbInfoLink
                          to={
                            '/support/manual/' +
                            (item.value === '1'
                              ? MANUAL_NAMEDDESTS.about_upload_invoice
                              : MANUAL_NAMEDDESTS.about_upload_offset)
                          }
                          target="_blank"
                        />
                      </div>
                    </Radio>
                  ))}
                </Radio.Group>
              </div>

              <div className="tw-grid tw-grid-cols-2 tw-gap-24 pc:tw-grid-cols-4">
                <HgbSelect
                  name={'targetFiscalYear'}
                  // placeholder={C01.label.fiscalYear}
                  className="tw-col-span-2 pc:tw-col-span-1"
                  label={C01.label.fiscalYear}
                  options={targetFiscalYearOptions}
                  defaultActiveFirstOption
                />
                <HgbSelect
                  name={'targetYearMonth'}
                  // placeholder={C01.label.targetYearMonth}
                  className="tw-col-span-2 pc:tw-col-span-1"
                  label={C01.label.targetYearMonth}
                  options={targetYearMonthOptions}
                  defaultActiveFirstOption
                />
                <HgbTreeSelect
                  name={'baseId'}
                  // placeholder={C01.label.baseId}
                  className="tw-col-span-2"
                  label={C01.label.baseId}
                  treeData={baseOptions}
                  required
                  showSearch
                />
                <HgbSelect
                  name={'scopeCode'}
                  // placeholder={C01.label.scopeCode}
                  className="tw-col-span-2"
                  label={C01.label.scopeCode}
                  options={scopeOptions}
                  // defaultActiveFirstOption
                  disabled={step1Methods.watch().radio === '2'}
                  autoInitValue
                />
              </div>
              <HgbAntdUpload
                disabled={isEmpty(step1Methods.watch('scopeCode'))}
                name="file"
                size={5}
              />
              <div
                className={`tw-mt-24 tw-grid tw-grid-cols-1 tw-gap-24 tw-self-center pc:tw-grid-cols-2`}
              >
                <HgbAntdButton
                  onClick={() => {
                    return navigate(routs_en['/data-entry'].link);
                  }}
                  type="primary"
                  ghost
                  htmlType="submit"
                >
                  {C01.label.returnToDataList}
                </HgbAntdButton>
                <HgbAntdButton
                  type="primary"
                  htmlType="submit"
                  disabled={disableSubmit}
                  loading={loading}
                  className="-tw-order-1 pc:tw-order-1"
                >
                  {C01.label.upload}
                </HgbAntdButton>
              </div>
            </div>
          </form>
        </FormProvider>
        {open && (
          <UploadModal open={open} setOpen={setOpen} {...uploadModalProps} />
        )}
        {openConfirm && (
          <HgbAntdModal
            title={C01.modal.uploadConfirmModalTitle}
            okText={C01.modal.uploadConfirmModalOkButton}
            cancelText={common.button.cancel}
            onCancel={() => setOpenConfirm(false)}
            open
            onOk={() => {
              setOpenConfirm(false);
              submitFile();
            }}
          >
            <div className="tw-text-center">
              {C01.modal.uploadConfirmModalContent}
            </div>
          </HgbAntdModal>
        )}
      </DataTableTemplate>
    </ManagementTemplate>
  );
};

export default C02;
