import { HgbAntdModal, HgbAntdModalProps } from '@common/antd/HgbAntdModal';
import { API_URLS } from '@constants/API_URLS';
import { LanguageContext } from '@contexts/LanguageContext';
import { useDelete } from '@hooks/useApi';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { useHgbQuery } from 'services/common/query';

export type EmissionDeleteAllData = {
  id: string;
  scopeCode: string;
  categoryCode: string;
  insertMethodCode: string;
  hasSupplierEmission: boolean;
};

export interface EmissionDeleteAllModalProps extends HgbAntdModalProps {
  data?: EmissionDeleteAllData;
  onSuccess?: () => void;
  quantities: string;
  hasSupplierEmission: boolean;
}

export const EmissionDeleteAllModal: React.FunctionComponent<
  EmissionDeleteAllModalProps
> = ({ data, onSuccess, quantities, hasSupplierEmission, ...props }) => {
  const {
    text: { C01, common },
    language,
  } = React.useContext(LanguageContext)!;

  const [state, deleteEmission] = useDelete<undefined, string>(() => {
    onSuccess?.();
  });

  const [, deleteOffset, stateExtra] = useDelete<undefined, string>(() => {
    onSuccess?.();
  });

  const removeListIdQuery = useHgbQuery<string[], { listId: string }>(
    API_URLS.LIST_ID_REMOVE,
    { listId: data?.id || '' },
    {
      enabled: !isEmpty(data?.id) && data?.scopeCode !== '4',
      queryKey: [API_URLS.LIST_ID_REMOVE, data?.id],
    },
  );

  const deleteHandler = () => {
    const { scopeCode, categoryCode, id } = data ?? {
      scopeCode: '',
      categoryCode: '',
      id: '',
    };

    if (scopeCode === '4') {
      const url = `/api/v1/offsets?lang=${language}&listOffsetId=${(
        removeListIdQuery.data?.result || [id]
      ).toString()}`;
      !stateExtra.isLoading &&
        deleteOffset(url, (removeListIdQuery.data?.result || [id]).toString());
    } else {
      const url = `/api/v1/emission-input/scope-${scopeCode}${
        categoryCode !== '00' ? `/category-${Number(categoryCode)}` : ''
      }?lang=${language}&listActivityId=${(
        removeListIdQuery.data?.result || []
      ).toString()}`;
      !state.isLoading &&
        deleteEmission(url, (removeListIdQuery.data?.result || []).toString());
    }
  };

  return (
    <HgbAntdModal
      title={C01.modal.emissionDeleteAllTitle}
      cancelText={common.button.cancel}
      okText={common.button.delete}
      onOk={deleteHandler}
      {...props}
      width={460}
    >
      {hasSupplierEmission
        ? C01.modal.supplierConfirmModalSubTitle(
            (removeListIdQuery.data?.result || []).length.toString(),
          )
        : C01.message.deleteAllEmissionConfirm(
            (removeListIdQuery.data?.result || []).length.toString(),
          )}
    </HgbAntdModal>
  );
};
