import { cn } from '@utils/cn';
import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';

type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  loading?: boolean;
  endIcon?: React.ReactNode;
};

export const createPrimaryButton = (props?: Partial<ButtonProps>) =>
  cn(
    'tw-flex tw-min-w-100 tw-cursor-pointer tw-items-center tw-justify-center',
    'tw-gap-8 tw-rounded-24 tw-px-20 tw-py-[10px] tw-text-[14px]',
    'tw-border tw-border-solid tw-border-transparent ',
    'tw-bg-primary-6 tw-font-bold tw-text-white',
    'hover:tw-bg-primary-5 active:tw-bg-primary-7 disabled:tw-cursor-not-allowed disabled:tw-bg-neutral-3',
    { 'tw-pointer-events-none': props?.loading },
  );

export const PrimaryButton: React.FunctionComponent<ButtonProps> = (props) => {
  const {
    children,
    leftIcon,
    rightIcon,
    loading = false,
    className,
    endIcon,
    ...restProps
  } = props;
  return (
    <button
      className={cn(createPrimaryButton(props), className)}
      {...restProps}
    >
      {leftIcon}
      {children}
      {rightIcon}
      {endIcon && (
        <div
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          {endIcon}
        </div>
      )}
    </button>
  );
};

export const createSecondaryButton = (props?: Partial<ButtonProps>) =>
  cn(
    'tw-flex tw-min-w-100 tw-cursor-pointer tw-items-center tw-justify-center',
    'tw-gap-8 tw-rounded-24 tw-px-20 tw-py-[10px] tw-text-[14px]',
    'tw-border tw-border-solid tw-border-primary-6',
    'tw-bg-white tw-font-bold tw-text-primary-6',
    'hover:tw-border-primary-5 hover:tw-text-primary-5 active:tw-border-primary-7 active:tw-text-primary-5 disabled:tw-cursor-not-allowed disabled:tw-border-neutral-4 disabled:tw-text-neutral-4',
    { 'tw-pointer-events-none': props?.loading },
  );

export const SecondaryButton: React.FunctionComponent<ButtonProps> = (
  props,
) => {
  const {
    children,
    leftIcon,
    rightIcon,
    loading = false,
    className,
    endIcon,
    ...restProps
  } = props;

  return (
    <button
      className={cn(createSecondaryButton(props), className)}
      {...restProps}
    >
      {leftIcon}
      {children}
      {rightIcon}
      {endIcon && (
        <div
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          {endIcon}
        </div>
      )}
    </button>
  );
};

export const createTextButtonClass = (props?: Partial<ButtonProps>) =>
  cn(
    'tw-flex tw-cursor-pointer tw-items-center tw-justify-between tw-gap-8 tw-text-[14px] tw-font-bold tw-text-primary-6 hover:tw-border-primary-5 hover:tw-text-primary-5 active:tw-border-primary-7 active:tw-text-primary-7  disabled:tw-cursor-not-allowed disabled:tw-text-neutral-3',
    { 'tw-pointer-events-none': props?.loading },
  );

export const TextButton: React.FunctionComponent<ButtonProps> = (props) => {
  const {
    children,
    leftIcon,
    rightIcon,
    className,
    loading,
    endIcon,
    ...restProps
  } = props;

  return (
    <button
      className={cn(createTextButtonClass(props), className)}
      {...restProps}
    >
      {leftIcon}
      {children}
      {rightIcon}
      {endIcon && (
        <div
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          {endIcon}
        </div>
      )}
    </button>
  );
};

type LinkButtonProps = LinkProps & { loading?: boolean };
export const createLinkButtonClass = (props?: Partial<LinkButtonProps>) =>
  cn(
    'tw-cursor-pointer tw-text-[14px] tw-font-bold tw-underline-offset-4 tw-text-[#1677ff] visited:tw-text-[#1677ff] hover:tw-text-[#69b1ff] active:tw-text-[#0958d9] disabled:tw-cursor-not-allowed disabled:tw-text-neutral-3',
    { 'tw-pointer-events-none': props?.loading },
  );

export const LinkButton: React.FC<LinkButtonProps> = (props) => {
  const { className, ...restProps } = props;
  return (
    <Link
      className={cn(createLinkButtonClass(props), className)}
      {...restProps}
    />
  );
};
