import { HgbAntdButton } from '@common/antd/HgbAntdButton';
import { HgbAntdModal } from '@common/antd/HgbAntdModal';
import { HgbAntdRadioGroup } from '@common/antd/HgbAntdRadioGroup';
import HgbInfoLink from '@common/contents/HgbInfoLink';
import { HgbDatePicker, HgbSelect, HgbSelectOption } from '@common/forms';
import { HgbAntdInput } from '@common/forms/HgbAntdInput/HgbAntdInput';
import { HgbTreeSelect } from '@common/forms/HgbTreeSelect/HgbTreeSelect';
import { API_URLS } from '@constants/API_URLS';
import { STATUS_CODE } from '@constants/consts';
import { LanguageContext } from '@contexts/LanguageContext';
import { PortalContext } from '@contexts/PortalContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGet, usePost, usePut } from '@hooks/useApi';
import {
  currentYearMonth,
  getFiscalYearFromMonthDirectInput,
} from '@utils/date.util';
import { debug } from '@utils/debug';
import { formatFormula, toBigDecimal } from '@utils/input.utils';
import {
  convertObjectToTree,
  getFieldsFromFormula,
  getParentKey,
} from '@utils/object.utils';
import { JSON_TO_KEY, joinSafe } from '@utils/text';
import BigNumber from 'bignumber.js';
import parse from 'html-react-parser';
import { isEmpty, isEqual, isNaN } from 'lodash';
import * as React from 'react';
import {
  FunctionComponent,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormProvider, SubmitErrorHandler, useForm } from 'react-hook-form';
import { useHgbQuery } from 'services/common/query';
import {
  BaseOrganizationListBoxRequest,
  BaseOrganizationListBoxResponse,
} from 'services/types/base';
import {
  GetCategoryListBoxRequest,
  GetCategoryListBoxResponse,
} from 'services/types/category';
import {
  GetScopeListBoxRequest,
  GetScopeListBoxResponse,
} from 'services/types/scope';
import {
  GetFiscalYearMonthListBoxRequest,
  GetFiscalYearMonthListBoxResponse,
  GetLastTenYearListBoxRequest,
  GetLastTenYearListBoxResponse,
} from 'services/types/year';
import {
  Emission,
  EmissionInputScope1Request,
  EmissionInputScope1Response,
  EmissionOffset,
  FlowInputDetail,
  InputInfo,
  OffsetFields,
  SelectionInfo,
  Step1Fields,
  Step2Fields,
} from 'types/api/emission-input/emission-input.type';
import * as yup from 'yup';
import { MANUAL_NAMEDDESTS } from '../Support';
import { getResultFromFormula } from './utils';

type ModalType = 'STEP_1' | 'STEP_2';
type ModalStatus = {
  isOpen: boolean;
  type: ModalType;
};

type ObjectModal = {
  title: ReactNode;
  content: ReactNode;
  cancel: ReactNode;
  ok: ReactNode;
};

const STEP1_DEFAULT_VALUE: Step1Fields = {
  targetYearMonth: '',
  targetFiscalYear: '',
  baseId: '',
  scopeCode: '',
  categoryCode: '',
  checkedId: null,
};

const STEP2_DEFAULT_VALUE: Step2Fields = {
  emissionUnit: '',
  calculationTargetCode: '',
  calculationMethodCode: '',
  scope2MethodSelected: '',
  fuelCode: '',
  activityVolume: undefined,
  activityVolumeUnit: '',
  emissionIntensity: undefined,
  emissionIntensityUnit: '',
  emissionVolume: '',
  emissionVolumeUnit: '',
  modelCode: '',
  refrigerantNo: '',
  fillingVolume: undefined,
  fillingVolumeUnit: '',
  recoveryVolume: undefined,
  recoveryVolumeUnit: '',
  useEmissionCoefficient: undefined,
  useEmissionCoefficientUnit: '',
  gwp: undefined,
  gwpUnit: '',
  activityName: '',
  supplyMenuCode: '',
  retailElectricCalculationMethodCode: '',
  operatorGraspRate: undefined,
  operatorGraspRateUnit: '',
  heatUsageCode: '',
  departmentCode: '',
  rawActivityVolume: undefined,
  rawEmissionIntensity: undefined,
  rawTransportActivityVolume: undefined,
  rawTransportEmissionIntensity: undefined,
  capitalWasteActivityVolume: undefined,
  capitalWasteEmissionIntensity: undefined,
  energyTypeCode: '',
  electricCompanyNo: '',
  transportTypeCode: '',
  transportDistance: undefined,
  transportDistanceUnit: '',
  transportCargoWeight: undefined,
  transportCargoWeightUnit: '',
  transportMaximumCargoWeight: undefined,
  transportMaximumCargoWeightUnit: '',
  loadPercentageCode: '',
  transportLoadRate: undefined,
  transportLoadRateUnit: '',
  consumptionTransportTypeCode: '',
  consumption: undefined,
  consumptionUnit: '',
  radioButton1: '',
  radioButton2: '',

  numberOfPeople: undefined,
  numberOfPeopleUnit: '',
  numberOfNight: undefined,
  numberOfNightUnit: '',
  numberOfDay: undefined,
  numberOfDayUnit: '',
  businessTravelTypeCode: '',

  transportProduct: '',
  employeeNumberBasedTypeCode: '',
  nightStayTypeCode: '',

  fuelEconomyBasic: undefined,
  fuelEconomyBasicUnit: '',

  officeCityClassificationCode: '',

  buildingUseCode: '',
  distribution: undefined,
  distributionUnit: '',

  expectedLifetimeUsagePer: undefined,
  expectedLifetimeUsagePerUnit: '',
  saleCount: undefined,
  saleCountUnit: '',
  perLessonUsage: undefined,
  perLessonUsageUnit: '',
  capitalGoodsTypeCode: '',
  emissionLifetimeUse: undefined,
  greenHouseGasCode: '',
  businessDaysYear: undefined,
  expectedLifetimeUsage: undefined,
  expectedLifetimeUsagePercentage: undefined,
  unclearActivityVolume1: undefined,
  unclearActivityVolume2: undefined,
  unclearActivityVolume3: undefined,
  unclearActivityVolume4: undefined,
  unclearActivityVolume5: undefined,
  unclearActivityVolume6: undefined,
  unclearActivityVolume7: undefined,
  unclearActivityVolume8: undefined,
  unclearActivityVolume9: undefined,
  unclearActivityVolume10: undefined,
  unclearActivityVolume11: undefined,
  unclearActivityVolume12: undefined,
  unclearEmissionIntensity1: undefined,
  unclearEmissionIntensity2: undefined,
  unclearEmissionIntensity3: undefined,
  unclearEmissionIntensity4: undefined,
  unclearEmissionIntensity5: undefined,
  unclearEmissionIntensity6: undefined,
  manualIdeaInput: undefined,
  emissionIntensityLocation: undefined,
  emissionVolumeLocation: '',
  emissionIntensityLocationUnit: '',
  emissionVolumeLocationUnit: '',
  fuelEfficiencyCategory: '',
  companyGasSelected: undefined,
  cityGasOptionSelected: undefined,
  heatSelected: undefined,
  useOfCertificate: undefined,
  certificateSelected: undefined,
  supplyCompanyNo: undefined,
  greenEnergyCertificateVolume: undefined,
  greenEnergyCertificateVolumeUnit: '',
};
export const OFFSET_DEFAULT: OffsetFields = {
  offsetAmount: undefined,
  offsetAmountUnit: '',
  offsetCertificateDate: '',
  offsetTarget: '',
  projectName: '',
  creditType: '',
};

interface EmissionUpdateModalProp {
  scopeEdit?: EmissionInputScope1Response;
  step1?: Step1Fields;
  activityName?: string;
  id?: number;
  reset?: () => void;
  offset?: OffsetFields;
  scopeEditCloned: string[];
}

export const EmissionUpdateModal: FunctionComponent<
  EmissionUpdateModalProp
> = ({
  scopeEdit,
  step1,
  activityName,
  id,
  offset,
  reset,
  scopeEditCloned,
}) => {
  const {
    text: {
      C01,
      common: { button, ...common },
      E0000,
      E0113,
      E0116,
    },
  } = useContext(LanguageContext)!;

  const [isEdit, setIsEdit] = useState(false);

  const { addMessage } = useContext(PortalContext)!;

  const confirmModalText: ObjectModal = {
    title:
      id !== 0
        ? C01.modal.emissionUpdateConfirmTitle
        : C01.modal.emissionCreateConfirmTitle,
    content:
      id !== 0
        ? C01.message.emissionUpdateConfirm
        : C01.message.emissionCreateConfirm,
    ok: id !== 0 ? button.change : button.add,
    cancel: button.cancel,
  };

  const [categoryListBox, setCategoryListBox] = useState<HgbSelectOption[]>([]);
  const [modalStatus, setModalStatus] = useState<ModalStatus>({
    isOpen: false,
    type: 'STEP_1',
  });

  const [confirmModalStatus, setConfirmModalStatus] = useState(false);

  const okText: Record<ModalType, ReactNode> = {
    STEP_1: button.next,
    STEP_2: id !== 0 ? button.change : button.add,
  };
  const [selectionInfos, setSelectionInfos] = useState<SelectionInfo[]>([]);
  const [params, setParams] = useState<EmissionInputScope1Request>({});
  const [LCIDBYear, setLCIDBYear] = useState<string>('');
  const [formualCargoWeight, setFormualCargoWeight] = useState<string | null>(
    '',
  );
  const [defaultActiveFirstOption, setDefaultActiveFirstOption] =
    useState<boolean>(false);

  const [scope1Result, requestScope1] = usePost<
    EmissionInputScope1Response,
    EmissionInputScope1Request
  >(
    (data) => {
      setSelectionInfos((prev) => [
        ...prev,
        ...(data?.nextStepSelectionInfos?.map((item) => ({
          ...item,
        })) ?? []),
      ]);

      setModalStatus((prev) => ({ ...prev, type: 'STEP_2' }));
    },
    (err) => {
      if (err.code === STATUS_CODE.dataNotExitEmission) {
        step2Methods.setError('fuelEfficiencyCategory', { message: err.text });
      }
    },
    { isAlert: false },
  );

  const resetModal = () => {
    setSelectionInfos([...[selectionInfos[0]]]);
    step1Reset(STEP1_DEFAULT_VALUE);
    offsetReset(OFFSET_DEFAULT);
    step2Reset(STEP2_DEFAULT_VALUE);
    setModalStatus({ isOpen: false, type: 'STEP_1' });
    setParams({});
    reset?.();
    // setConfirmModalStatus(({ ...prev }) => ({ ...prev, isOpen: false }));
    setConfirmModalStatus(false);
    setIsEdit(false);
  };

  const resetModalCancel = () => {
    setSelectionInfos([...[selectionInfos[0]]]);
    step1Reset(STEP1_DEFAULT_VALUE);
    offsetReset(OFFSET_DEFAULT);
    step2Reset(STEP2_DEFAULT_VALUE);
    setModalStatus({ isOpen: false, type: 'STEP_1' });
    setParams({});
    setIsEdit(false);
  };

  const [, submitScope1] = usePost<
    undefined,
    Emission & { targetLciDbYear?: string; targetYearMonth?: string }
  >(
    (data) => {
      resetModal();
    },
    (err) => {
      addMessage('error', err.text);
      resetModal();
    },
  );

  const [lastTenYearResult, getLastTenYearResult] = useGet<
    GetLastTenYearListBoxResponse,
    GetLastTenYearListBoxRequest
  >(() => {
    if (step1?.targetFiscalYear)
      step1SetValues('targetFiscalYear', step1.targetFiscalYear);
  });

  const { data: baseQuery } = useHgbQuery<
    BaseOrganizationListBoxResponse,
    BaseOrganizationListBoxRequest
  >(
    API_URLS.ORGANIZATION_BASE,
    {
      isLoadInactive: id !== 0,
    },
    {
      onSuccess(data) {
        if (step1?.baseId) {
          step1SetValues('baseId', step1.baseId);
        }
      },
      queryKey: [API_URLS.ORGANIZATION_BASE, 'update'],
    },
  );

  const [scopeListBoxResult, getScopeListBoxResult] = useGet<
    GetScopeListBoxResponse,
    GetScopeListBoxRequest
  >(() => {
    if (step1?.scopeCode) {
      step1SetValues('scopeCode', step1.scopeCode);
    }
  });

  const [, updateEmissionData] = usePut<
    undefined,
    Emission & { id: number; targetLciDbYear?: string }
  >(() => {
    resetModal();
  });
  const [, updateOffset] = usePut<
    undefined,
    OffsetFields & Step1Fields & { id: number }
  >(() => {
    resetModal();
  });

  const step1Schema = yup.object({
    targetFiscalYear: yup.string().required(E0000(C01.label.targetFiscalYear)),
    targetYearMonth: yup.string().required(E0000(C01.label.targetYearMonth)),
    scopeCode: yup.string().required(E0000(C01.label.scopeCode)),
    categoryCode: yup.string().when({
      is: () => Emission.scopeCode !== '4',
      then: (schema) => schema.required(E0000(common.label.category)),
    }),
    baseId: yup.string().required(E0000(C01.label.baseId)),
  });
  const step2Schema = yup.object({
    activityName: yup.string().when({
      is: () => step1Watch('scopeCode') === '3',
      then: (schema) => schema.required(E0000(C01.label.activityName)),
      otherwise: (schema) => schema,
    }),
    activityVolume: yup.number().when({
      is: () => preValidateInputInfoCondition('activityVolume'),
      then: (schema) =>
        schema
          .required(E0000(C01.label.activityVolume))
          .min(0, E0000(C01.label.activityVolume))
          .typeError(E0000(C01.label.activityVolume)),

      otherwise: (schema) => schema,
    }),
    greenEnergyCertificateVolume: yup.number().when({
      is: () => !isEmpty(validateByRange('greenEnergyCertificateVolume')),
      then: () =>
        validateByRange('greenEnergyCertificateVolume') as yup.NumberSchema<
          number | undefined,
          yup.AnyObject,
          undefined,
          ''
        >,
      otherwise: (schema) => schema,
    }),
    fillingVolume: yup.number().when({
      is: () => preValidateInputInfoCondition('fillingVolume'),
      then: (schema) =>
        schema
          .required(E0000(C01.label.fillingVolume))
          .min(0, E0000(C01.label.fillingVolume))
          .typeError(E0000(C01.label.fillingVolume)),
      otherwise: (schema) => schema,
    }),
    recoveryVolume: yup.number().when({
      is: () => preValidateInputInfoCondition('recoveryVolume'),
      then: (schema) =>
        schema
          .min(0, C01.message.recoveryVolumeFormat)
          .required(C01.message.recoveryVolume)
          .typeError(C01.message.recoveryVolumeFormat),
      otherwise: (schema) => schema,
    }),
    emissionVolume: yup.string().when({
      is: () => step2Watch('emissionIntensityLocation') === undefined,
      then: (schema) =>
        schema
          .min(0, C01.message.emissionVolumeNegativeUpdate)
          .required(E0000(common.label.emissions))
          .typeError(E0000(common.label.emissions)),
      otherwise: (schema) => schema,
    }),
    emissionIntensity: yup.number().when({
      is: () => preValidateIntensityCondition('emissionIntensity'),
      then: (schema) =>
        schema
          .required(E0000(C01.label.emissionIntensity))
          .min(0, E0000(C01.label.emissionIntensity))
          .typeError(E0000(C01.label.emissionIntensity)),
      otherwise: (schema) => schema,
    }),
    rawActivityVolume: yup.number().when({
      is: () => preValidateInputInfoCondition('rawActivityVolume'),
      then: (schema) =>
        schema
          .required(E0000(C01.label.rawActivityVolume))
          .min(0, E0000(C01.label.rawActivityVolume))
          .typeError(E0000(C01.label.rawActivityVolume)),
      otherwise: (schema) => schema,
    }),
    rawEmissionIntensity: yup.number().when({
      is: () => preValidateIntensityCondition('rawEmissionIntensity'),
      then: (schema) =>
        schema
          .required(E0000(C01.label.rawEmissionIntensity))
          .min(0, E0000(C01.label.rawEmissionIntensity))
          .typeError(E0000(C01.label.rawEmissionIntensity)),
      otherwise: (schema) => schema,
    }),
    rawTransportActivityVolume: yup.number().when({
      is: () => preValidateInputInfoCondition('rawTransportActivityVolume'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.rawTransportActivityVolume))
          .required(E0000(C01.label.rawTransportActivityVolume))
          .typeError(E0000(C01.label.rawTransportActivityVolume)),
      otherwise: (schema) => schema,
    }),
    rawTransportEmissionIntensity: yup.number().when({
      is: () => preValidateIntensityCondition('rawTransportEmissionIntensity'),
      then: (schema) =>
        schema
          .required(E0000(C01.label.rawTransportEmissionIntensity))
          .min(0, E0000(C01.label.rawTransportEmissionIntensity))
          .typeError(E0000(C01.label.rawTransportEmissionIntensity)),
      otherwise: (schema) => schema,
    }),
    capitalWasteActivityVolume: yup.number().when({
      is: () => preValidateInputInfoCondition('capitalWasteActivityVolume'),
      then: (schema) =>
        schema
          .required(E0000(C01.label.capitalWasteActivityVolume))
          .min(0, C01.message.capitalWasteActivityVolumeFormat)
          .typeError(C01.message.capitalWasteActivityVolumeFormat),
      otherwise: (schema) => schema,
    }),
    capitalWasteEmissionIntensity: yup.number().when({
      is: () => preValidateIntensityCondition('capitalWasteEmissionIntensity'),
      then: (schema) =>
        schema
          .required(E0000(C01.label.capitalWasteEmissionIntensity))
          .min(0, E0000(C01.label.capitalWasteEmissionIntensity))
          .typeError(E0000(C01.label.capitalWasteEmissionIntensity)),
      otherwise: (schema) => schema,
    }),
    transportCargoWeight: yup.number().when({
      is: () => preValidateInputInfoCondition('transportCargoWeight'),
      then: (schema) =>
        schema
          .required(E0000(C01.label.transportCargoWeight))
          .min(0, E0000(C01.label.transportCargoWeight))
          .typeError(E0000(C01.label.transportCargoWeight)),
      otherwise: (schema) => schema,
    }),
    transportMaximumCargoWeight: yup
      .number()
      .transform((value, originalValue) =>
        originalValue === '' ? undefined : value,
      )
      .when({
        is: () => preValidateInputInfoCondition('transportMaximumCargoWeight'),
        then: (schema) =>
          schema
            .required(E0000(C01.label.transportMaximumCargoWeight))
            .min(0, E0000(C01.label.transportMaximumCargoWeight))
            .typeError(E0000(C01.label.transportMaximumCargoWeight)),
        otherwise: (schema) => schema,
      }),
    transportDistance: yup.number().when({
      is: () => preValidateInputInfoCondition('transportDistance'),
      then: (schema) =>
        schema
          .required(E0000(C01.label.transportDistance))
          .min(0, E0000(C01.label.transportDistance))
          .typeError(E0000(C01.label.transportDistance)),
      otherwise: (schema) => schema,
    }),
    numberOfPeople: yup.number().when({
      is: () => preValidateInputInfoCondition('numberOfPeople'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.numberOfPeople))
          .required(E0000(C01.label.numberOfPeople))
          .typeError(E0000(C01.label.numberOfPeople)),
      otherwise: (schema) => schema,
    }),
    numberOfNight: yup.number().when({
      is: () => preValidateInputInfoCondition('numberOfNight'),
      then: (schema) =>
        schema
          .required(E0000(C01.label.numberOfNight))
          .min(0, E0000(C01.label.numberOfNight))
          .typeError(E0000(C01.label.numberOfNight)),
      otherwise: (schema) => schema,
    }),
    numberOfDay: yup.number().when({
      is: () => preValidateInputInfoCondition('numberOfDay'),
      then: (schema) =>
        schema
          .required(E0000(C01.label.numberOfDay))
          .min(0, E0000(C01.label.numberOfDay))
          .typeError(E0000(C01.label.numberOfDay)),
      otherwise: (schema) => schema,
    }),
    expectedLifetimeUsagePer: yup.number().when({
      is: () => preValidateInputInfoCondition('expectedLifetimeUsagePer'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.expectedLifetimeUsagePer))
          .required(E0000(C01.label.expectedLifetimeUsagePer))
          .typeError(E0000(C01.label.expectedLifetimeUsagePer)),
      otherwise: (schema) => schema,
    }),
    saleCount: yup.number().when({
      is: () => preValidateInputInfoCondition('saleCount'),
      then: (schema) =>
        schema
          .required(E0000(C01.label.saleCount))
          .min(0, E0000(C01.label.saleCount))
          .typeError(E0000(C01.label.saleCount)),
      otherwise: (schema) => schema,
    }),
    perLessonUsage: yup.number().when({
      is: () => preValidateInputInfoCondition('perLessonUsage'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.perLessonUsage))
          .required(E0000(C01.label.perLessonUsage))
          .typeError(E0000(C01.label.perLessonUsage)),
      otherwise: (schema) => schema,
    }),
    emissionLifetimeUse: yup.number().when({
      is: () => preValidateInputInfoCondition('emissionLifetimeUse'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.emissionLifetimeUse))
          .required(E0000(C01.label.emissionLifetimeUse))
          .typeError(E0000(C01.label.emissionLifetimeUse)),
      otherwise: (schema) => schema,
    }),
    distribution: yup.number().when({
      is: () => !isEmpty(validateByRange('distribution')),
      then: () =>
        validateByRange('distribution') as yup.NumberSchema<
          number | undefined,
          yup.AnyObject,
          undefined,
          ''
        >,
      otherwise: (schema) => schema,
    }),
    fuelEconomyBasic: yup.number().when({
      is: () => preValidateInputInfoCondition('fuelEconomyBasic'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.fuelEconomy))
          .required(E0000(C01.label.fuelEconomy))
          .typeError(E0000(C01.label.fuelEconomy)),
      otherwise: (schema) => schema,
    }),
    transportLoadRate: yup.number().when({
      is: () => preValidateInputInfoCondition('transportLoadRate'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.transportLoadRate))
          .required(E0000(C01.label.transportLoadRate))
          .typeError(E0000(C01.label.transportLoadRate)),
      otherwise: (schema) => schema,
    }),
    businessDaysYear: yup.number().when({
      is: () => preValidateInputInfoCondition('businessDaysYear'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.businessDaysYear))
          .required(E0000(C01.label.businessDaysYear))
          .typeError(E0000(C01.label.businessDaysYear)),
      otherwise: (schema) => schema,
    }),
    expectedLifetimeUsage: yup.number().when({
      is: () => preValidateInputInfoCondition('expectedLifetimeUsage'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.expectedLifetimeUsage))
          .required(E0000(C01.label.expectedLifetimeUsage))
          .typeError(E0000(C01.label.expectedLifetimeUsage)),
      otherwise: (schema) => schema,
    }),
    expectedLifetimeUsagePercentage: yup.number().when({
      is: () =>
        preValidateInputInfoCondition('expectedLifetimeUsagePercentage'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.expectedLifetimeUsagePercentage))
          .required(E0000(C01.label.expectedLifetimeUsagePercentage))
          .typeError(E0000(C01.label.expectedLifetimeUsagePercentage)),
      otherwise: (schema) => schema,
    }),
    useEmissionCoefficient: yup.number().when({
      is: () => !isEmpty(validateByRange('useEmissionCoefficient')),
      then: () =>
        validateByRange('useEmissionCoefficient') as yup.NumberSchema<
          number | undefined,
          yup.AnyObject,
          undefined,
          ''
        >,
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume1: yup.number().when({
      is: () => preValidateInputInfoCondition('unclearActivityVolume1'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearActivityVolume1))
          .required(E0000(C01.label.unclearActivityVolume1))
          .typeError(E0000(C01.label.unclearActivityVolume1)),
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume2: yup.number().when({
      is: () => preValidateInputInfoCondition('unclearActivityVolume2'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearActivityVolume2))
          .required(E0000(C01.label.unclearActivityVolume2))
          .typeError(E0000(C01.label.unclearActivityVolume2)),
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume3: yup.number().when({
      is: () => preValidateInputInfoCondition('unclearActivityVolume3'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearActivityVolume3))
          .required(E0000(C01.label.unclearActivityVolume3))
          .typeError(E0000(C01.label.unclearActivityVolume3)),
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume4: yup.number().when({
      is: () => preValidateInputInfoCondition('unclearActivityVolume4'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearActivityVolume4))
          .required(E0000(C01.label.unclearActivityVolume4))
          .typeError(E0000(C01.label.unclearActivityVolume4)),
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume5: yup.number().when({
      is: () => preValidateInputInfoCondition('unclearActivityVolume5'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearActivityVolume5))
          .required(E0000(C01.label.unclearActivityVolume5))
          .typeError(E0000(C01.label.unclearActivityVolume5)),
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume6: yup.number().when({
      is: () => preValidateInputInfoCondition('unclearActivityVolume6'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearActivityVolume6))
          .required(E0000(C01.label.unclearActivityVolume6))
          .typeError(E0000(C01.label.unclearActivityVolume6)),
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume7: yup.number().when({
      is: () => preValidateInputInfoCondition('unclearActivityVolume7'),
      then: (schema) =>
        schema
          .min(0, C01.message.unclearActivityVolume7Format)
          .required(C01.message.unclearActivityVolume7)
          .typeError(C01.message.unclearActivityVolume7Format),
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume8: yup.number().when({
      is: () => preValidateInputInfoCondition('unclearActivityVolume8'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearActivityVolume8))
          .required(E0000(C01.label.unclearActivityVolume8))
          .typeError(E0000(C01.label.unclearActivityVolume8)),
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume9: yup.number().when({
      is: () => preValidateInputInfoCondition('unclearActivityVolume9'),
      then: (schema) =>
        schema
          .min(0, C01.message.unclearActivityVolume9Format)
          .required(C01.message.unclearActivityVolume9)
          .typeError(C01.message.unclearActivityVolume9Format),
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume10: yup.number().when({
      is: () => preValidateInputInfoCondition('unclearActivityVolume10'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearActivityVolume10))
          .required(E0000(C01.label.unclearActivityVolume10))
          .typeError(E0000(C01.label.unclearActivityVolume10)),
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume11: yup.number().when({
      is: () => preValidateInputInfoCondition('unclearActivityVolume11'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearActivityVolume11))
          .required(E0000(C01.label.unclearActivityVolume11))
          .typeError(E0000(C01.label.unclearActivityVolume11)),
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume12: yup.number().when({
      is: () => preValidateInputInfoCondition('unclearActivityVolume12'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearActivityVolume12))
          .required(E0000(C01.label.unclearActivityVolume12))
          .typeError(E0000(C01.label.unclearActivityVolume12)),
      otherwise: (schema) => schema,
    }),
    unclearEmissionIntensity1: yup.number().when({
      is: () => preValidateIntensityCondition('unclearEmissionIntensity1'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearEmissionIntensity1))
          .required(E0000(C01.label.unclearEmissionIntensity1))
          .typeError(E0000(C01.label.unclearEmissionIntensity1)),
      otherwise: (schema) => schema,
    }),
    unclearEmissionIntensity2: yup.number().when({
      is: () => preValidateIntensityCondition('unclearEmissionIntensity2'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearEmissionIntensity2))
          .required(E0000(C01.label.unclearEmissionIntensity2))
          .typeError(E0000(C01.label.unclearEmissionIntensity2)),
      otherwise: (schema) => schema,
    }),
    unclearEmissionIntensity3: yup.number().when({
      is: () => preValidateIntensityCondition('unclearEmissionIntensity3'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearEmissionIntensity3))
          .required(E0000(C01.label.unclearEmissionIntensity3))
          .typeError(E0000(C01.label.unclearEmissionIntensity3)),
      otherwise: (schema) => schema,
    }),
    unclearEmissionIntensity4: yup.number().when({
      is: () => preValidateIntensityCondition('unclearEmissionIntensity4'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearEmissionIntensity4))
          .required(E0000(C01.label.unclearEmissionIntensity4))
          .typeError(E0000(C01.label.unclearEmissionIntensity4)),
      otherwise: (schema) => schema,
    }),
    unclearEmissionIntensity5: yup.number().when({
      is: () => preValidateIntensityCondition('unclearEmissionIntensity5'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearEmissionIntensity5))
          .required(E0000(C01.label.unclearEmissionIntensity5))
          .typeError(E0000(C01.label.unclearEmissionIntensity5)),
      otherwise: (schema) => schema,
    }),
    unclearEmissionIntensity6: yup.number().when({
      is: () => preValidateIntensityCondition('unclearEmissionIntensity6'),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearEmissionIntensity6))
          .required(E0000(C01.label.unclearEmissionIntensity6))
          .typeError(E0000(C01.label.unclearEmissionIntensity6)),
      otherwise: (schema) => schema,
    }),
    manualIdeaInput: yup.number().when({
      is: () => preValidateInputInfoCondition('manualIdeaInput'),
      then: (schema) =>
        schema
          .min(0, C01.message.manualIdeaInput)
          .required(C01.message.manualIdeaInput)
          .typeError(C01.message.manualIdeaInput),
      otherwise: (schema) => schema,
    }),
  });

  const offsetSchema = yup.object({
    offsetCertificateDate: yup
      .string()
      .required(E0000(C01.label.offsetCertificateDate))
      .typeError(E0000(C01.label.offsetCertificateDate)),
    offsetAmount: yup
      .number()
      .min(0, E0000(C01.label.offsetAmount))
      .required(E0000(C01.label.offsetAmount))
      .typeError(E0000(C01.label.offsetAmount)),
  });

  // tách các fields thành 2 step
  const step1Methods = useForm<Step1Fields>({
    defaultValues: STEP1_DEFAULT_VALUE,
    resolver: yupResolver(step1Schema),
  });
  const {
    register: step1Register,
    watch: step1Watch,
    handleSubmit: step1HandleSubmit,
    setValue: step1SetValues,
    reset: step1Reset,
  } = step1Methods;

  const step2Methods = useForm<Step2Fields>({
    values: STEP2_DEFAULT_VALUE,
    resolver: yupResolver(step2Schema),
  });
  const {
    register: step2Register,
    watch: step2Watch,
    handleSubmit: step2HandleSubmit,
    setValue: step2SetValues,
    reset: step2Reset,
  } = step2Methods;

  const offsetMethod = useForm<OffsetFields>({
    defaultValues: OFFSET_DEFAULT,
    resolver: yupResolver(offsetSchema),
  });
  const {
    register: offsetRegister,
    watch: offsetWatch,
    handleSubmit: offsetSubmitHandler,
    reset: offsetReset,
  } = offsetMethod;

  const Emission: any = { ...step1Watch(), ...step2Watch() };
  const [, submitOffset] = usePost<undefined, EmissionOffset>(() => {
    resetModal();
  });

  const [, getCategoryListBox] = useGet<
    GetCategoryListBoxResponse,
    GetCategoryListBoxRequest
  >(
    (data) => {
      setCategoryListBox([
        ...data?.map(({ name: label, value, note }) => ({
          label:
            step1Watch('scopeCode') !== '3'
              ? '-'
              : joinSafe([label, note], ' : '),
          value,
        })),
      ]);

      if (step1?.categoryCode) {
        step1SetValues('categoryCode', step1?.categoryCode);
      } else {
        if (step1Watch('scopeCode') !== '3') {
          step1SetValues('categoryCode', '00');
        }
      }
    },
    undefined,
    { keepPreviousData: false },
  );

  const { data: lciDBYear, refetch: checkLcidbYear } = useHgbQuery<
    string,
    { lciDBYear: string }
  >(
    API_URLS.CHECK_LCIDB_YEAR_API_URL,
    {
      lciDBYear: step1Watch('targetYearMonth'),
    },
    {
      enabled: false,
      isAlert: false,
      onSuccess: (data) => {
        if (isEmpty(data?.result)) {
          addMessage('error', data?.message ?? '');
          return;
        }
        setLCIDBYear(data?.result ?? '');
        const targetLciDbYear = data.result ?? '';
        const targetYearMonth = step1Methods.watch('targetYearMonth');
        const checkedId = id !== 0 ? id?.toString() : null;

        if (Emission.scopeCode !== '4') {
          let objectParam: Record<string, any> = {};
          scopeEdit?.nextStepSelectionInfos?.forEach((s, id) => {
            objectParam[s.fieldTarget] =
              s.selectedValue ??
              (!isEmpty(scopeEditCloned)
                ? scopeEditCloned[id]
                : Emission[s.fieldTarget as keyof Step2Fields]);
            step2Methods.setValue(
              s.fieldTarget as keyof Step2Fields,
              scopeEditCloned[id],
            );
          });
          objectParam.targetLciDbYear = targetLciDbYear;
          objectParam.targetYearMonth = targetYearMonth;
          objectParam.checkedId = checkedId;

          // if (!isEmpty(params)) {
          //   objectParam = {
          //     ...params,
          //     ...objectParam,
          //     targetLciDbYear,
          //     targetYearMonth,
          //     checkedId,
          //   };
          // }

          if (selectionInfos.length <= Object.values(objectParam).length)
            if (
              getFiscalYearFromMonthDirectInput(
                step1?.targetYearMonth ?? '',
              ).toString() !== data?.result
            ) {
              setParams({ targetLciDbYear, targetYearMonth, checkedId });
              step2Reset(STEP2_DEFAULT_VALUE);
              setSelectionInfos([]);
            } else {
              setSelectionInfos(scopeEdit?.nextStepSelectionInfos ?? []);
            }
          requestScope1(
            `api/v1/emission-input/scope-${step1Watch('scopeCode')}/flow${
              step1Watch('categoryCode') !== '00'
                ? `/category-${Number(step1Watch('categoryCode'))}`
                : ''
            }`,
            getFiscalYearFromMonthDirectInput(
              step1?.targetYearMonth ?? '',
            ).toString() !== data?.result
              ? { targetLciDbYear, targetYearMonth, checkedId }
              : objectParam,
          );
          setModalStatus((prev) => ({ ...prev, type: 'STEP_2' }));
        } else {
          setModalStatus((prev) => ({ ...prev, type: 'STEP_2' }));
        }
      },
      queryKey: [
        API_URLS.CHECK_LCIDB_YEAR_API_URL,
        step1Watch('targetFiscalYear'),
      ],
    },
  );

  useEffect(() => {
    getLastTenYearResult(API_URLS.LAST_TEN_YEAR_API_URL);
    getScopeListBoxResult(API_URLS.SCOPE_LIST_BOX_API_URL);
  }, []);

  const [yearResult, getYearResult] = useGet<
    GetFiscalYearMonthListBoxResponse,
    GetFiscalYearMonthListBoxRequest
  >();

  useEffect(() => {
    step1SetValues('targetYearMonth', currentYearMonth());
    const { targetFiscalYear } = step1Watch();
    if (
      step1?.targetYearMonth &&
      step1?.targetFiscalYear === targetFiscalYear
    ) {
      step1SetValues(
        'targetYearMonth',
        step1?.targetYearMonth.split('/').join(''),
      );
    }

    if (
      targetFiscalYear !== step1?.targetFiscalYear &&
      currentYearMonth().indexOf(targetFiscalYear) < 0
    ) {
      step1SetValues('targetYearMonth', yearResult?.data?.[0]?.value ?? '');
    }
  }, [yearResult.data]);

  const targetYearMonthOptions = useMemo(() => {
    let years = yearResult.data;
    if (id !== 0) {
      if (
        !years?.find(
          (s) => s.value === step1?.targetYearMonth.split('/').join(''),
        )
      ) {
        years?.push({
          name: step1?.targetYearMonth.split('/').join('-') ?? '',
          value: step1?.targetYearMonth.split('/').join('') ?? '',
        });
      }
    }
    return years;
  }, [yearResult.data]);

  useEffect(() => {
    let targetFiscalYear = step1Watch('targetFiscalYear');
    if (targetFiscalYear) {
      getYearResult(API_URLS.FISCAL_YEAR_MONTH, {
        targetYear: targetFiscalYear,
        isLCIDBYear: false,
      });
    }
  }, [step1Watch('targetFiscalYear'), lastTenYearResult.data]);

  useEffect(() => {
    let scopeCode = step1Watch('scopeCode') ?? Emission.scopeCode;
    if (!isEmpty(step1?.scopeCode)) {
      scopeCode = step1?.scopeCode ?? '';
    }
    if (scopeCode) {
      getCategoryListBox(API_URLS.CATEGORY_LIST_BOX_API_URL, {
        scopeCode: scopeCode,
      });
    }
  }, [step1Watch('scopeCode')]);
  useEffect(() => {
    if (!isEmpty(scopeEdit)) {
      setSelectionInfos(scopeEdit?.nextStepSelectionInfos ?? []);
      step2SetValues('activityName', activityName ?? '');
      if (isEmpty(scopeEdit?.formula)) {
        step2SetValues(
          scopeEdit?.flowResultItem?.fieldTarget === 'emissionVolumeLocation'
            ? 'emissionVolumeLocation'
            : 'emissionVolume',
          scopeEdit.flowResultItem?.value,
        );
      }
    }
    if (!isEmpty(step1)) {
      step1Reset({
        ...step1,
        targetYearMonth: step1?.targetYearMonth.split('/').join(''),
      });
    }
    if (!isEmpty(offset)) {
      offsetReset({ ...offset });
    }
    if (!isEmpty(offset) || !isEmpty(scopeEdit)) {
      setIsEdit(false);
      setModalStatus({ isOpen: true, type: 'STEP_1' });
    }
  }, [step1, scopeEdit, activityName, offset]);

  const flowInputDetails: FlowInputDetail[] = useMemo(() => {
    let objectParam: string[] = [
      Emission.calculationTargetCode as string,
      Emission.calculationMethodCode as string,
      Emission.heatUsageCode as string,
      Emission.scope2MethodSelected as string,
    ];

    const flowInputs: FlowInputDetail[] =
      scope1Result.data?.flowInputDetails ?? [];
    const inputs: InputInfo[] = [];
    scopeEdit?.flowInputDetails
      ?.map((s) => s.inputGroup)
      .map((input) => {
        input?.forEach((k) => {
          inputs?.push(k);
        });
      });
    if (flowInputs) {
      if (!isEmpty(flowInputs[0]?.inputGroup)) {
        flowInputs
          ?.map((s) => s.inputGroup)
          .filter((s) => s)
          .forEach((item1) =>
            item1.forEach((item) => {
              step2SetValues(
                `${item.fieldTarget}Unit` as keyof Step2Fields,
                item.unit,
              );
              const valueflowInputs = inputs?.find(
                (l) => l.fieldTarget === item.fieldTarget,
              )?.value;
              const manualflowInputs = new BigNumber(valueflowInputs);
              step2SetValues(
                item.fieldTarget as keyof Step2Fields,
                !isEmpty(item.value?.toString())
                  ? Number(item.value)
                  : valueflowInputs
                  ? manualflowInputs?.toFixed() ?? ''
                  : valueflowInputs,
              );
              if (item.fieldTarget === 'transportMaximumCargoWeight') {
                setFormualCargoWeight(item?.formula);
              }
            }),
          );
      }
      flowInputs
        ?.map((s) => s.intensity)
        .filter((k) => k)
        .forEach((item) => {
          step2SetValues(
            `${item.fieldTarget}Unit` as keyof Step2Fields,
            item.unit,
          );
          const valueflowInputs = scopeEdit?.flowInputDetails
            ?.map((s) => s.intensity)
            .filter((k) => k)
            .find((item1) => item1.fieldTarget === item.fieldTarget)?.value;

          const manualIdeaInput = new BigNumber(
            scopeEdit?.flowInputDetails
              ?.map((s) => s.intensity)
              .filter((k) => k)
              .find((item1) => item1.fieldTarget === item.fieldTarget)?.value,
          );
          step2SetValues(
            item.fieldTarget as keyof Step2Fields,
            !isEmpty(item.value?.toString()) &&
              !(isEqual(scopeEditCloned, objectParam) && valueflowInputs)
              ? Number(item.value)
              : valueflowInputs
              ? manualIdeaInput?.toFixed() ?? ''
              : valueflowInputs,
          );
        });
      if (!isEmpty(scopeEdit) && id === 0) {
        if (!isEmpty(flowInputs[0]?.inputGroup)) {
          flowInputs
            ?.map((s) => s.inputGroup)
            .filter((s) => s)
            .forEach((item1) =>
              item1.forEach((item) => {
                if (!item.disabled)
                  step2SetValues(item.fieldTarget as keyof Step2Fields, '');
              }),
            );
        }
        flowInputs
          ?.map((s) => s.intensity)
          .filter((k) => k)
          .forEach((item) => {
            if (
              !item.disabled &&
              !(
                Emission.calculationMethodCode === 'M00006' &&
                Emission.calculationTargetCode === 'ST005' &&
                (Emission.scope2MethodSelected === 'ML0001' ||
                  Emission.scope2MethodSelected === 'ML0002')
              )
            ) {
              step2SetValues(item.fieldTarget as keyof Step2Fields, '');
            } else if (
              !item.disabled &&
              Emission.calculationMethodCode === 'M00006' &&
              Emission.calculationTargetCode === 'ST005' &&
              (Emission.scope2MethodSelected === 'ML0001' ||
                Emission.scope2MethodSelected === 'ML0002')
            ) {
              step2SetValues(item.fieldTarget as keyof Step2Fields, item.value);
            }
          });
      }
    }
    return flowInputs;
  }, [scope1Result.data?.flowInputDetails, selectionInfos, scopeEdit]);

  const flowResultItem: InputInfo | undefined = useMemo(() => {
    const flowItem: InputInfo | undefined =
      scope1Result.data?.flowResultItem ?? undefined;
    if (flowItem) {
      step2SetValues(
        `${flowItem.fieldTarget}Unit` as keyof Step2Fields,
        flowItem.unit,
      );
      step2SetValues(
        `${flowItem.fieldTarget}` as keyof Step2Fields,
        Number(flowItem.value) ?? null,
      );
    }
    return flowItem;
  }, [
    selectionInfos,
    flowInputDetails,
    scope1Result.data?.flowResultItem,
    scopeEdit,
  ]);
  const preValidateInputInfoCondition = (name: keyof Step2Fields) => {
    const checkers: InputInfo[] = [];
    flowInputDetails
      .map((flow) => flow.inputGroup)
      .map((input) => {
        input?.forEach((rs) => {
          if (rs.fieldTarget === name) {
            checkers.push(rs);
          }
        });
      });
    return checkers.length > 0;
  };

  const preValidateIntensityCondition = (name: keyof Step2Fields) => {
    const checkers: InputInfo[] = [];
    flowInputDetails
      .map((flow) => flow.intensity)
      .filter((s) => s)
      .forEach((intensity) => {
        if (intensity.disabled === false && intensity.fieldTarget === name) {
          checkers.push(intensity);
        }
      });
    return checkers.length > 0;
  };

  const validateByRange = (name: string) => {
    let validate: Partial<InputInfo> = {};
    flowInputDetails
      .map((flow) => flow.inputGroup)
      .filter((s) => s)
      .map((input) =>
        input?.forEach((rs) => {
          if (rs.fieldTarget === name) {
            validate = { ...rs };
          }
        }),
      );
    if (validate && validate.disabled === false) {
      if (validate.fieldTarget === 'greenEnergyCertificateVolume') {
        return yup
          .number()
          .required(E0000(C01.label.greenEnergyCertificateVolume))
          .min(0, E0000(C01.label.greenEnergyCertificateVolume))
          .typeError(E0000(C01.label.greenEnergyCertificateVolume))
          .lessThan(Number(Emission.activityVolume) + 0.000000000001, E0113)
          .test(
            'len',
            E0116,
            (val: any) => Number(val) <= (validate.constraints?.MAX_VALUE ?? 0),
          );
      }
      return yup
        .number()
        .min(
          validate.constraints?.MIN_VALUE ?? 0,
          validate.fieldTarget + common.message.isRequired,
        )
        .max(
          validate.constraints?.MAX_VALUE ?? 0,
          validate.fieldTarget + common.message.isRequired,
        )
        .required(E0000(C01.label.fuelUsagePerUse))
        .typeError(E0000(C01.label.fuelUsagePerUse));
    }
    return {};
  };

  useEffect(() => {
    selectionInfos.forEach((item) => {
      if (!isEmpty(item?.selectedValue)) {
        if (isEmpty(scopeEdit?.nextStepSelectionInfos)) {
          handleChangeScope1Params(
            item.fieldTarget ?? '',
            item?.selectedValue ?? '',
          );
        } else {
          step2SetValues(
            item.fieldTarget as keyof Step2Fields,
            item.selectedValue ?? '',
          );
          if (isEdit) {
            handleChangeScope1Params(
              item.fieldTarget ?? '',
              item?.selectedValue ?? '',
            );
          }
        }
      }
    });
  }, [selectionInfos]);

  useEffect(() => {
    selectionInfos.forEach((item) => {
      if (item.fieldTarget === 'useOfCertificate' && defaultActiveFirstOption) {
        handleChangeScope1Params(
          item.fieldTarget ?? '',
          step2Methods.getValues('useOfCertificate') ?? '',
        );
      }
    });
  }, [defaultActiveFirstOption]);

  const handleChangeScope1Params = async (name: string, value: string) => {
    const findedIndex = selectionInfos.findIndex(
      (item) => item.fieldTarget === name,
    );
    // setIsEdit(true);
    let newParams = Object.assign({});
    newParams = {
      ...newParams,
      targetLciDbYear: lciDBYear?.result,
      targetYearMonth: step1Methods.watch('targetYearMonth'),
    };
    // prepare new array for selects
    let tmpSelects: SelectionInfo[] = [];
    step2SetValues(name as keyof Step2Fields, value);

    // find index of current changed select
    // const indexOfChangeField = selectionInfos.find(
    //   (item: SelectionInfo) => item.fieldTarget === name,
    // )?.index;

    let resetObject: Step2Fields = { ...STEP2_DEFAULT_VALUE };
    tmpSelects = selectionInfos.map((item) => {
      if (!isEmpty(item?.selectedValue)) {
        step2SetValues(
          item.fieldTarget as keyof Step2Fields,
          item?.selectedValue ?? '',
        );
        // if (Number(item.index) <= Number(indexOfChangeField))
        //   resetObject = {
        //     ...resetObject,
        //     [item.fieldTarget]: item?.selectedValue,
        //   };
        item.selectedValue = null;
      }
      return item;
    });

    if (
      name === 'supplyCompanyNo' ||
      name === 'electricCompanyNo' ||
      name === 'companyGasSelected' ||
      name === 'loadPercentageCode' ||
      (name === 'radioButton2' &&
        Emission.calculationMethodCode === 'M00018') ||
      (name === 'calculationMethodCode' &&
        step2Methods.getValues('calculationTargetCode') === 'ST034') ||
      (name === 'calculationMethodCode' &&
        step2Methods.getValues('calculationTargetCode') === 'ST054') ||
      (name === 'calculationMethodCode' &&
        step2Methods.getValues('calculationTargetCode') === 'ST055') ||
      (name === 'calculationMethodCode' &&
        step2Methods.getValues('calculationTargetCode') === 'ST035')
    ) {
      tmpSelects = tmpSelects.slice(0, findedIndex + 2).filter((item) => {
        newParams = {
          ...newParams,
          [item.fieldTarget]:
            name !== item.fieldTarget
              ? Emission[item.fieldTarget as keyof Step2Fields]
              : value,
        };
        return item;
      });
    } else if (
      (name === 'consumptionTransportTypeCode' ||
        (name === 'radioButton1' && value === 'DK0001')) &&
      Emission.calculationMethodCode === 'M00019'
    ) {
      tmpSelects = tmpSelects.slice(0, findedIndex + 3).filter((item) => {
        if (
          item.fieldTarget === 'fuelCode' ||
          (item.fieldTarget === 'fuelEfficiencyCategory' &&
            name !== 'consumptionTransportTypeCode')
        ) {
          return;
        }
        newParams = {
          ...newParams,
          [item.fieldTarget]:
            name !== item.fieldTarget
              ? Emission[item.fieldTarget as keyof Step2Fields]
              : value,
        };
        return item;
      });
    } else if (
      name === 'radioButton1' &&
      Emission.calculationMethodCode === 'M00018'
    ) {
      tmpSelects = tmpSelects.slice(0, findedIndex + 3).filter((item) => {
        newParams = {
          ...newParams,
          [item.fieldTarget]:
            name !== item.fieldTarget
              ? Emission[item.fieldTarget as keyof Step2Fields]
              : value,
        };
        return item;
      });
    } else if (
      name === 'consumptionTransportTypeCode' &&
      Emission.calculationMethodCode === 'M00018'
    ) {
      tmpSelects = tmpSelects.slice(0, findedIndex + 4).filter((item) => {
        newParams = {
          ...newParams,
          [item.fieldTarget]:
            name !== item.fieldTarget
              ? Emission[item.fieldTarget as keyof Step2Fields]
              : value,
        };
        return item;
      });
    } else {
      tmpSelects = tmpSelects.slice(0, findedIndex + 1).filter((item) => {
        newParams = {
          ...newParams,
          [item.fieldTarget]:
            name !== item.fieldTarget
              ? Emission[item.fieldTarget as keyof Step2Fields]
              : value,
        };
        return item;
      });
    }

    // render new array select
    setSelectionInfos(tmpSelects);

    // because just cleared item in select array, need to reset index equal current maximum index

    resetObject = {
      ...resetObject,
      ...newParams,
      activityName: Emission.activityName,
    };

    step2Reset({ ...Emission, ...resetObject });

    requestScope1(
      `api/v1/emission-input/scope-${step1Watch('scopeCode')}/flow${
        step1Watch('categoryCode') !== '00'
          ? `/category-${Number(step1Watch('categoryCode'))}`
          : ''
      }`,
      newParams,
    );

    setParams(newParams);
  };

  useEffect(() => {
    if (
      scope1Result.data?.flowFinished &&
      (scope1Result.data.formula ?? '' !== '')
    ) {
      let emissionsCalculation = { ...Emission };
      const emissionKeys = Object.keys(Emission);
      const keysFromFormula = getFieldsFromFormula(
        scope1Result.data?.formula ?? '',
      );
      const keysFromFormulaMaximumCargoWeight = getFieldsFromFormula(
        formualCargoWeight ?? '',
      );
      emissionKeys.forEach((item) => {
        if (
          (keysFromFormula.includes(item) ||
            keysFromFormulaMaximumCargoWeight.includes(item)) &&
          !isEmpty(Emission[item as keyof Emission])
        ) {
          emissionsCalculation = {
            ...emissionsCalculation,
            [item]: Number(Emission[item as keyof Emission]),
          };
        }
      });
      try {
        if (!isEmpty(scope1Result.data?.formula)) {
          let value = getResultFromFormula(
            Emission,
            scope1Result.data?.formula,
          );
          step2Methods.setValue(
            Emission.emissionIntensityLocation
              ? 'emissionVolumeLocation'
              : 'emissionVolume',
            isNaN(Number(value)) ? '' : toBigDecimal(value, 3),
          );
        }
        if (!isEmpty(formualCargoWeight)) {
          let value = getResultFromFormula(Emission, formualCargoWeight);
          step2Methods.setValue(
            'transportMaximumCargoWeight',
            isNaN(Number(value)) ? '' : formatFormula(value.toString(), 3),
          );
        }
      } catch (error) {
        debug.log(error);
      }
    }
  }, [
    scope1Result.data?.flowFinished,
    scope1Result.data?.formula,
    JSON.stringify(Emission),
    scopeEdit,
    formualCargoWeight,
    defaultActiveFirstOption,
  ]);

  useEffect(() => {
    if (
      (flowInputDetails?.[0]?.inputGroup || []).some(
        (item) => item.fieldTarget === 'greenEnergyCertificateVolume',
      ) &&
      [null, undefined, ''].includes(Emission.greenEnergyCertificateVolume)
    ) {
      step2Methods.setValue('emissionVolume', '');
    }
  }, [Emission.greenEnergyCertificateVolume, JSON.stringify(flowInputDetails)]);

  const step1SubmitHandler = () => {
    // khi validate thành công step 1 mới nhảy vô được hàm này, nó sẽ chuyển qua step 2
    checkLcidbYear();
  };

  const resetObjectBeforeSubmit = (formula?: string) => {
    const fields = getFieldsFromFormula(formula ?? '');
    const keys = Object.keys(Emission);
    let emissions = { ...Emission };
    keys.forEach((item) => {
      if (
        !fields.includes(item) &&
        item !==
          (emissions?.emissionIntensityLocation
            ? 'emissionVolumeLocation'
            : 'emissionVolume') &&
        typeof emissions[item as keyof Emission] === 'number'
      ) {
        emissions = { ...emissions, [item]: null };
        emissions = {
          ...emissions,
          [`${item}Unit` as keyof Emission]: null,
        };
      }
    });
    step2Reset({ ...emissions });
    return emissions;
  };

  const scopeSubmitAfterConfirm = () => {
    const emissions = {
      ...resetObjectBeforeSubmit(scope1Result.data?.formula ?? ''),
      baseId: step1Watch('baseId'),
    };

    if (id === 0) {
      submitScope1(
        `/api/v1/emission-input/scope-${step1Watch('scopeCode')}${
          step1Watch('categoryCode') !== '00'
            ? `/category-${Number(step1Watch('categoryCode'))}/save`
            : ''
        }`,
        {
          ...emissions,
          targetLciDbYear: lciDBYear?.result,
        },
      );
    } else {
      updateEmissionData(
        `/api/v1/emission-input/scope-${step1Watch('scopeCode')}${
          step1Watch('categoryCode') !== '00'
            ? `/category-${Number(step1Watch('categoryCode'))}`
            : ''
        }`,
        {
          ...emissions,
          id: Number(id ?? 0),
          targetLciDbYear: lciDBYear?.result,
        },
      );
    }
  };

  const unitByRange = (intensity: InputInfo) => {
    if (!isEmpty(intensity.constraints)) {
      return `${intensity.constraints.MIN_VALUE}-${intensity.constraints.MAX_VALUE}`;
    }
    return '';
  };

  const offsetSubmitAfterConfirm = () => {
    const offsetData = { ...step1Watch(), ...offsetWatch() };
    if (id === 0) {
      submitOffset(API_URLS.OFFSET_API_URL, offsetData);
    } else {
      updateOffset(API_URLS.OFFSET_API_URL, {
        ...offsetData,
        id: Number(id ?? 0),
      });
    }
  };

  useEffect(() => {
    if (isEmpty(flowInputDetails)) {
      step2SetValues(
        flowResultItem?.fieldTarget === 'emissionVolumeLocation'
          ? 'emissionVolumeLocation'
          : 'emissionVolume',
        isEmpty(scope1Result.data?.formula)
          ? id === 0
            ? undefined
            : isEdit === false
            ? scopeEdit?.flowResultItem?.value
            : undefined
          : undefined,
      );
    }
  }, [flowResultItem]);

  const disableOkButton = () => {
    let disabled = false;
    if (step1Watch('scopeCode') !== '4') {
      if (modalStatus.type === 'STEP_1') {
        const step1Tmp = !isEmpty(step1) ? step1 : step1Watch();
        const values = Object.values(step1Tmp);
        values.forEach((s1) => {
          if (isEmpty((s1 ?? '').toString())) {
            disabled = true;
          }
        });
      }

      if (modalStatus.type === 'STEP_2') {
        const keyFormula = getFieldsFromFormula(
          scope1Result.data?.formula ?? '',
        );
        const keyEmission = Object.keys(Emission);
        keyEmission.forEach((key) => {
          if (
            key === 'emissionVolume' &&
            isEmpty(Emission?.emissionIntensityLocation?.toString()) &&
            isEmpty(Emission[key]?.toString())
          ) {
            disabled = true;
          }
          if (
            key === 'emissionVolumeLocation' &&
            Emission.scope2MethodSelected === 'ML0002' &&
            step1Watch('scopeCode') === '2' &&
            isEmpty(Emission[key]?.toString())
          ) {
            disabled = true;
          }
          if (
            keyFormula.includes(key) &&
            isEmpty(Emission[key as keyof Emission]?.toString())
          ) {
            disabled = true;
          }
          if (
            key === 'activityName' &&
            step1Watch('scopeCode') === '3' &&
            isEmpty(Emission[key]?.toString())
          ) {
            disabled = true;
          }
        });
        if (!scope1Result.data?.flowFinished) {
          disabled = true;
        }
      }
    } else {
      if (modalStatus.type === 'STEP_1') {
        const step1Tmp = !isEmpty(step1) ? step1 : step1Watch();
        const { targetFiscalYear, targetYearMonth, scopeCode, baseId } =
          step1Tmp;
        if (
          isEmpty(targetFiscalYear) ||
          isEmpty(targetYearMonth) ||
          isEmpty(scopeCode.toString()) ||
          isEmpty(baseId.toString())
        ) {
          disabled = true;
        }
      }
      if (modalStatus.type === 'STEP_2') {
        const { offsetAmount, offsetCertificateDate } = offsetWatch();
        if (
          isEmpty(offsetAmount?.toString()) ||
          isEmpty(offsetCertificateDate)
        ) {
          disabled = true;
        }
      }
    }

    return disabled;
  };

  const typeOfInput = (unit: string) => {
    const listUnitInteger = ['人', '日', '泊'];
    return listUnitInteger.includes(unit)
      ? 'positiveInteger'
      : 'positiveDecimal';
  };

  const isSearchSelect = useCallback((fieldTarget: string) => {
    const { scopeCode, categoryCode } = step1Watch();
    const searchList = [
      'fuelCode',
      'electricCompanyNo',
      'officeCityClassificationCode',
      'greenHouseGasCode',
      'calculationMethodCode',
      'capitalGoodsTypeCode',
      'departmentCode',
      'heatUsageCode',
      'businessTravelTypeCode',
      'departmentCode',
      'heatUsageCode',
      'certificateSelected',
    ];
    if (scopeCode === '1' || scopeCode === '2') {
      return searchList.includes(fieldTarget);
    } else {
      if (fieldTarget === 'Type') return categoryCode === '01';
      return searchList.includes(fieldTarget);
    }
  }, []);

  const step2SubmitErrorHandler: SubmitErrorHandler<Step2Fields> = (error) => {
    debug.error('error', error);
  };

  const handelCheckDefaultValue = (value: boolean) => {
    setDefaultActiveFirstOption(value);
  };

  return (
    <>
      <HgbAntdButton
        type="primary"
        ghost
        className={'tw-hidden tw-min-w-180'}
        onClick={() => {
          setModalStatus((prev) => ({ ...prev, isOpen: true }));
        }}
        endIcon={
          <HgbInfoLink
            to={'/support/manual/' + MANUAL_NAMEDDESTS.about_input_direct}
            target="_blank"
          />
        }
      >
        <>{C01.modal.emissionCreateTitle}</>
      </HgbAntdButton>
      {modalStatus.isOpen && !confirmModalStatus && (
        <HgbAntdModal
          preventSubmitTime={500}
          maskClosable={false}
          enterForSubmit={false}
          title={
            (!isEmpty(scopeEdit) || !isEmpty(offset)) && id === 0
              ? C01.modal.emissionDuplicateTitle
              : C01.modal.emissionUpdateTitle
          }
          onBack={
            modalStatus.type === 'STEP_1'
              ? undefined
              : () => {
                  setModalStatus((prev) => ({ ...prev, type: 'STEP_1' }));
                }
          }
          cancelText={button.cancel}
          okText={okText[modalStatus.type]}
          open
          disableOk={disableOkButton()}
          onCancel={resetModalCancel}
          formProps={{
            onSubmit:
              modalStatus.type === 'STEP_1'
                ? step1HandleSubmit(step1SubmitHandler)
                : Emission.scopeCode === '4'
                ? offsetSubmitHandler(() => {
                    setConfirmModalStatus(true);
                  }, step2SubmitErrorHandler)
                : step2HandleSubmit(() => {
                    setConfirmModalStatus(true);
                  }, step2SubmitErrorHandler),
          }}
        >
          <div className="tw-grid tw-grid-cols-2 tw-gap-24">
            {/* step 1 */}
            <FormProvider {...step1Methods}>
              {modalStatus.type === 'STEP_1' && (
                <>
                  <HgbSelect
                    {...step1Register('targetFiscalYear')}
                    options={(lastTenYearResult.data ?? [])
                      .map(({ name: label, value }) => ({ label, value }))
                      .filter((word) => Number(word.value) > 2018)}
                    label={C01.label.fiscalYear}
                    placeholder={C01.placeholder.fiscalYear}
                    required
                    disabled={id !== 0}
                    defaultActiveFirstOption
                  />
                  <HgbSelect
                    {...step1Register('targetYearMonth')}
                    options={(targetYearMonthOptions ?? [])
                      ?.filter((item) => item.value !== '')
                      .map(({ name: label, value }) => ({
                        label,
                        value,
                      }))}
                    label={C01.label.targetYearMonth}
                    placeholder={C01.placeholder.targetYearMonth}
                    required
                    disabled={id !== 0}
                  />
                  <HgbTreeSelect
                    {...step1Register('baseId')}
                    placeholder={C01.placeholder.baseId}
                    label={C01.label.baseId}
                    className="tw-col-span-2"
                    required
                    treeData={convertObjectToTree(
                      (baseQuery?.result ?? []).filter(
                        (item) => !getParentKey(item.hierarchicalPath ?? ''),
                      ) as any,
                      baseQuery?.result as any,
                    )}
                    disabled={id !== 0}
                    showSearch
                  />
                  <HgbSelect
                    {...step1Register('scopeCode')}
                    required
                    className="tw-col-span-2"
                    label={C01.label.scopeCode}
                    placeholder={C01.placeholder.scopeCode}
                    options={[
                      ...(scopeListBoxResult.data ?? []).map((item) => ({
                        label: joinSafe([item.name, item.note], ' : '),
                        value: item.value,
                      })),
                      { label: common.label.offset, value: '4' },
                    ]}
                    disabled
                  />

                  {step1Watch('scopeCode') === '3' && (
                    <HgbSelect
                      {...step1Register('categoryCode')}
                      className="tw-col-span-2"
                      options={categoryListBox}
                      label={common.label.category}
                      placeholder={C01.placeholder.categoryCode}
                      required
                      disabled
                    />
                  )}
                </>
              )}
            </FormProvider>
            {/* step 2 */}
            {Emission.scopeCode !== '4' && modalStatus.type === 'STEP_2' && (
              <FormProvider {...step2Methods}>
                <>
                  <div className="tw-col-span-2">
                    <h4 className="tw-mb-8 tw-font-bold">
                      {C01.label.dataEntry}
                    </h4>
                    <small className="tw-text-error-7">
                      {parse(C01.message.dataEntryScopeNotice)}
                    </small>
                    {getFiscalYearFromMonthDirectInput(
                      step1?.targetYearMonth ?? '',
                    ).toString() !== LCIDBYear && (
                      <div>
                        <small className="tw-text-error-7">
                          {parse(C01.message.dataEntryScopeNoticeReset)}
                        </small>
                      </div>
                    )}
                  </div>
                  {(selectionInfos ?? [])
                    .filter((item) => item.data)
                    .map((selectionInfo, index) =>
                      selectionInfo.type === 'LIST_BOX' ? (
                        <HgbSelect
                          key={
                            JSON_TO_KEY(selectionInfo) +
                            index +
                            'selectionInfo_LIST_BOX'
                          }
                          className="tw-col-span-2"
                          options={(selectionInfo.fieldTarget ===
                          'supplyMenuCode'
                            ? selectionInfo.data?.sort((p1, p2) =>
                                p1.value > p2.value
                                  ? 1
                                  : p1.value < p2.value
                                  ? -1
                                  : 0,
                              )
                            : selectionInfo.data
                          ).map(({ value, name: label }) => ({
                            value,
                            label,
                          }))}
                          required
                          label={selectionInfo.name}
                          defaultValue={
                            Emission[
                              selectionInfo.fieldTarget as keyof Emission
                            ]
                          }
                          name={selectionInfo.fieldTarget as keyof Step2Fields}
                          onChangeValue={handleChangeScope1Params}
                          showSearch={isSearchSelect(selectionInfo?.fieldTarget)}
                          defaultActiveFirstOption={
                            selectionInfo.fieldTarget === 'useOfCertificate'
                          }
                          handelCheckDefaultValue={handelCheckDefaultValue}
                        />
                      ) : (
                        <HgbAntdRadioGroup
                          key={
                            JSON_TO_KEY(selectionInfo) +
                            index +
                            'selectionInfo_LIST_BOX'
                          }
                          className="tw-col-span-2"
                          options={selectionInfo.data.map(
                            ({ value, name: label }) => ({ value, label }),
                          )}
                          label={selectionInfo.name}
                          name={selectionInfo.fieldTarget}
                          onChangeValue={(e) =>
                            handleChangeScope1Params(
                              e.target.name ?? '',
                              e.target.value,
                            )
                          }
                          info={
                            selectionInfo.fieldTarget === 'scope2MethodSelected'
                              ? '/support/manual/' +
                                MANUAL_NAMEDDESTS.about_market_location_base
                              : ''
                          }
                          direction={
                            selectionInfo.fieldTarget ===
                              'cityGasOptionSelected' ||
                            selectionInfo.fieldTarget === 'heatSelected'
                              ? 'vertical'
                              : 'horizontal'
                          }
                        />
                      ),
                    )}
                  {(flowInputDetails ?? []).map(({ inputGroup, intensity }) => {
                    return (
                      <React.Fragment
                        key={JSON_TO_KEY(inputGroup) + 'flowInputDetails'}
                      >
                        {(inputGroup ?? []).map((input) => {
                          return (
                            <HgbAntdInput
                              key={
                                JSON_TO_KEY(input) +
                                'inputGroup-flowInputDetails'
                              }
                              required
                              className="tw-col-span-2"
                              label={input.name}
                              suffix={(input.unit ?? '').replaceAll('null', '')}
                              subLabel={unitByRange(input).replaceAll(
                                'null',
                                '',
                              )}
                              disabled={input.disabled}
                              type={typeOfInput(input.unit)}
                              minValue={
                                input.constraints?.MIN_VALUE ?? undefined
                              }
                              maxValue={
                                input.fieldTarget !==
                                'greenEnergyCertificateVolume'
                                  ? input.constraints?.MAX_VALUE
                                  : undefined
                              }
                              maxLength={input.maxLength}
                              {...step2Register(
                                input.fieldTarget as keyof Step2Fields,
                              )}
                              decimalLength={
                                input.constraints?.DECIMAL_LENGTH ?? undefined
                              }
                            />
                          );
                        })}
                        {intensity && (
                          <HgbAntdInput
                            required
                            className="tw-col-span-2"
                            label={intensity.name}
                            suffix={(intensity.unit ?? '').replaceAll(
                              'null',
                              '',
                            )}
                            subLabel={unitByRange(intensity).replaceAll(
                              'null',
                              '',
                            )}
                            disabled={intensity.disabled}
                            maxLength={intensity.maxLength}
                            name={intensity.fieldTarget}
                            type={typeOfInput(intensity.unit)}
                            minValue={
                              intensity.constraints?.MIN_VALUE ?? undefined
                            }
                            maxValue={
                              intensity.constraints?.MAX_VALUE ?? undefined
                            }
                            value={step2Watch(
                              intensity.fieldTarget as keyof Step2Fields,
                            )}
                            decimalLength={
                              intensity.constraints?.DECIMAL_LENGTH ?? undefined
                            }
                          />
                        )}
                      </React.Fragment>
                    );
                  })}
                  {flowResultItem && (
                    <HgbAntdInput
                      required
                      className="tw-col-span-2"
                      name={flowResultItem.fieldTarget}
                      label={flowResultItem.name}
                      suffix={(flowResultItem.unit ?? '').replaceAll(
                        'null',
                        '',
                      )}
                      subLabel={unitByRange(flowResultItem).replaceAll(
                        'null',
                        '',
                      )}
                      disabled={flowResultItem.disabled}
                      maxLength={flowResultItem.maxLength}
                      type={'positiveDecimal'}
                      minValue={
                        flowResultItem.constraints?.MIN_VALUE ?? undefined
                      }
                      maxValue={
                        flowResultItem.constraints?.MAX_VALUE ?? undefined
                      }
                      decimalLength={
                        flowResultItem.constraints?.DECIMAL_LENGTH ?? undefined
                      }
                    />
                  )}
                  <HgbAntdInput
                    {...step2Register('activityName')}
                    label={
                      step1Watch('scopeCode') === '3'
                        ? C01.label.activityName
                        : C01.label.note
                    }
                    placeholder={C01.placeholder.activityName}
                    className="tw-col-span-2"
                    required={step1Watch('scopeCode') === '3'}
                    maxLength={200}
                  />
                </>
              </FormProvider>
            )}
            {Emission.scopeCode === '4' && modalStatus.type === 'STEP_2' && (
              <FormProvider {...offsetMethod}>
                <>
                  <div className="tw-col-span-2">
                    <h4 className="tw-mb-8 tw-font-bold">
                      {C01.label.dataEntry}
                    </h4>
                    <small className="tw-text-error-7">
                      {parse(C01.message.dataEntryOffsetNotice)}
                    </small>
                  </div>
                  <HgbAntdInput
                    {...offsetRegister('offsetAmount')}
                    label={C01.label.offsetAmount}
                    placeholder={C01.placeholder.offsetAmount}
                    className="tw-col-span-2"
                    type="positiveDecimal"
                    maxLength={14}
                    required
                    decimalLength={3}
                    maxValue={9999999999}
                  />
                  <HgbSelect
                    {...offsetRegister('offsetAmountUnit')}
                    className="tw-col-span-2"
                    required
                    defaultActiveFirstOption
                    options={[
                      { label: 'tCO2', value: 'tCO2' },
                      { label: 'kgCO2', value: 'kgCO2' },
                    ]}
                    label={C01.label.offsetAmountUnit}
                    placeholder={C01.placeholder.offsetAmountUnit}
                  />
                  <HgbDatePicker
                    {...offsetRegister('offsetCertificateDate')}
                    className="tw-col-span-2"
                    required
                    format="YYYY/MM/DD"
                    picker="date"
                    label={C01.label.offsetCertificateDate}
                    placeholder={C01.placeholder.offsetCertificateDate}
                  />
                  <HgbAntdInput
                    {...offsetRegister('offsetTarget')}
                    className="tw-col-span-2"
                    maxLength={255}
                    label={C01.label.offsetTarget + common.label.optional}
                    placeholder={C01.placeholder.offsetTarget}
                  />
                  <HgbAntdInput
                    {...offsetRegister('projectName')}
                    className="tw-col-span-2"
                    maxLength={255}
                    label={C01.label.projectName + common.label.optional}
                    placeholder={C01.placeholder.projectName}
                  />
                  <HgbAntdInput
                    {...offsetRegister('creditType')}
                    className="tw-col-span-2"
                    maxLength={255}
                    label={C01.label.creditType + common.label.optional}
                    placeholder={C01.placeholder.creditType}
                  />
                </>
              </FormProvider>
            )}
          </div>
        </HgbAntdModal>
      )}
      <HgbAntdModal
        preventSubmitTime={500}
        open={confirmModalStatus}
        okText={confirmModalText.ok}
        cancelText={confirmModalText.cancel}
        title={confirmModalText.title}
        formProps={{
          onSubmit:
            Emission.scopeCode === '4'
              ? offsetSubmitAfterConfirm
              : scopeSubmitAfterConfirm,
        }}
        onCancel={() => {
          setConfirmModalStatus(false);
        }}
      >
        <div>{confirmModalText.content}</div>
      </HgbAntdModal>
    </>
  );
};
