import { HgbBulletItem } from '@common/contents';
import { createMessage, createNode } from '@utils/text';
import { ReactNode } from 'react';
import { en } from './en';

export const jp: typeof en = {
  common: {
    button: {
      login: 'ログイン',
      forgotPassword: 'パスワードを忘れた場合',
      reissue: '再発行',
      backToLogin: 'ログイン画面へ戻る',
      gotoDashboard: 'トップページへ',
      changePassword: 'パスワード変更',
      cancel: 'キャンセル',
      save: '保存',
      register: '新規登録',
      delete: '削除',
      update: '編集',
      add: '登録',
      participate: '参加',
      remand: '差戻',
      change: '変更',
      changeAndResend: '変更＆再送信',
      next: '次へ',
      return: '戻る',
      duplicate: '複製',
      now: '現在',
      preview: 'プレビュー',
      draft: '下書き保存',
      active: '配信',
      delivery: '配信',
      close: '閉じる',
      ok: '確定',
      confirm: 'OK',
      newMessage: '新規登録',
      enterPayment: '決済情報入力へ',
      passedPayment: '本申込を行う',
      backToA06: '仮申込へ',
      enterpriseOutput: '企業情報一覧表出力',
      approve: '承認',
      denied: '否認',
      export: '出力',
      enterpriseAdminCard: '＋クレジットカード情報の新規登録・変更',
      respond: '回答',
      suspend: '停止',
    },
    message: {
      emailFormat: 'メール形式が無効です',
      partnerBanksEmpty: 'パートナー銀行は存在しません。',
      choosePartnerBankAndCompany: 'パートナー銀行と企業を選択してください。',
      choosePartnerBank: 'パートナー銀行を選択してください。',
      companyEmpty: 'このパートナー銀行は企業を持っていません。',
      chooseCompany: '企業を選択してください。',
      noResult: '結果がありません。',
      noData: 'データがありません。',
      startDateEmpty: '開始日を選択してください。',
      endDateEmpty: '終了日を選択してください。',
      startMonthEmpty: '開始年月を選択してください。',
      endMonthEmpty: '終了年月を選択してください。',
      mes_access_deny: (
        <>
          現在、このページへのアクセスは禁止されています。
          <br />
          サイト管理者の方はパージの権限設定等が適切かご確認ください。
        </>
      ),
      mess_not_found: (
        <>
          一時的にアクセスができない状態です
          <br />
          時間を置いて再度お試しください。
        </>
      ),
      tit_access_deny: 'アクセス禁止',
      tit_not_found: '問題が発生しました',
      isRequired: 'は必須項目です。',
      fileExceedMb: 'ファイル容量が$MBを超えるファイルが存在します。',
      fileFormat: 'ファイルの形式が適切ではありません。',
      fileExceedLimit: '許可されるファイル数を超えています。',
      fileExisted: 'そのファイルが存在しているのでご再確認ください。',
      device_not_match:
        'この機能はモバイルにサポートしないのでパソコンで実施をお願いします。',
    },
    label: {
      emissionVolume: '排出量',
      action: '操作',
      scope: 'スコープ',
      scope1: 'スコープ1',
      scope2: 'スコープ2',
      scope3: 'スコープ3',
      scopeNote1: 'スコープ1：自社による燃料の使用（ガソリン、ガス等）',
      scopeNote2: 'スコープ2：他社から供給された電気、熱等の使用',
      scopeNote3: 'スコープ3：商品・サービスの購入やサプライチェーン全体の排出',
      offset: 'オフセット',
      category: 'カテゴリー',
      emissions: '排出量',
      optional: '（任意）',
      all: '全部',
      fileUpload: {
        text1: 'ファイルをドラッグ＆ドロップするか、',
        text2: 'ファイル選択',
        text3: 'を押下してください。',
      },
      fileName: 'ファイル名',
      notification: '通知',
      pagingDetail: (total: number, current: number, pageSize: number) => (
        <>
          {total}件中に{(current - 1) * pageSize + 1}－
          {current * pageSize > total ? total : current * pageSize}件目を表示
        </>
      ),
      bankCode: '銀行コード',
      loginID: 'ログインID',
      enterpriseCode: '企業番号',
      enterpriseName: '企業名称',
      enterpriseNameKana: '企業名称カナ',
      tradeName: '商号',
      representative: '代表者',
      representativeKana: '代表者カナ',
      postalCode: '郵便番号',
      address2: 'ビル名等',
      phoneNumber: '電話番号 (ハイフンなし）',
      numberOfEmployees: '従業員数',
      specifiedEmitterCode: '特定排出者コード',
      specifiedBusinessNumber: '特定事業者番号',
      corporateNumber: '法人番号',
      controlNumber: '管理番号',
      startMonthOfYear: '事業年度開始月',
      business: '事業',
      businessCode: '事業コード',
      businessMinister: '事業所管大臣',
      billingDate: '請求開始年月(YYYY/MM)',
      maximumNumberOfUsers: '最大ユーザー数',
      industryMajorCodeClassification: '業種コード大分類',
      industryMediumCodeClassification: '業種コード中分類',
      paymentMethod: '決済方法',
      isCorporateAccount: '法人口座有無',
      branchNumber: '法人口座店番',
      corporateAccountNumber: '法人口座番号',
      picName: '担当者名（主に入力を担当される方）',
      picNameKana: '担当者名カナ',
      picEmail: '担当者メールアドレス',
      fullAddress: '住所（都道府県市区町村町域）',
      productName: '製品名',
      organizationBaseName: '拠点・組織',
      groupBuyer: 'グループ主催企業',
      emissionIntensity: '排出原単位',
    },
    placeholder: {
      enterpriseName: 'XXX株式会社',
      enterpriseNameKana: 'XXXカブシキガイシャ',
      postalCode: '8120011',
      phoneNumber: 'ハイフンなしで入力',
      bankId: 'パートナー銀行',
      enterpriseNameSearch: '企業名称',
      filter: 'フィルタ',
    },
    columns: { supplyChainGroupName: 'サプライチェーングループ名称' },
    month: {
      Jan: '1月',
      Feb: '2月',
      Mar: '3月',
      Apr: '4月',
      May: '5月',
      Jun: '6月',
      Jul: '7月',
      Aug: '8月',
      Sep: '9月',
      Oct: '10月',
      Nov: '11月',
      Dec: '12月',
      '01': '1月',
      '02': '2月',
      '03': '3月',
      '04': '4月',
      '05': '5月',
      '06': '6月',
      '07': '7月',
      '08': '8月',
      '09': '9月',
      '10': '10月',
      '11': '11月',
      '12': '12月',
    },
  },
  A01: {
    label: {
      password: 'パスワード',
    },
    placeholder: {
      bankCode: '銀行コードを入力してください',
      username: 'ログインIDを入力してください',
      password: 'パスワードを入力してください',
    },
  },
  A03: {
    pageTitle: 'パスワード再発行',
    pageTitleSuccess: 'パスワード再発行完了',
    label: {
      passwordRule:
        '登録済みの銀行コード（4桁）、ログインID（8桁）、メールアドレスを入力してください。',
      thePasswordWillBeSent:
        '入力したメールアドレスにIDと仮パスワードを記載したメールを送信します。',
      contactCompany:
        'ログインIDを忘れた場合は企業管理者にお問い合わせください。',
      theNewPasswordIsSent:
        '指定のメールアドレスに仮パスワードを送付しました。',
      recommendSplit1: '送付したパスワードは',
      recommendSplit2: '「仮パスワード」',
      recommendSplit3: 'ですので、パスワード変更画面で',
      recommendSplit4: 'パスワードの変更を早めに行ってください。',
      email: 'メールアドレス',
    },
    placeholder: {
      bankCode: ' 銀行コードを入力してください ',
      username: 'ログインIDを入力してください',
      email: 'メールアドレスを入力してください',
    },
  },
  A04: {
    pageTitleSuccess: 'パスワード変更完了',
    label: {
      oldPassword: '現在のパスワード',
      newPassword: '新しいパスワード',
      confirmPassword: '新しいパスワード（確認用）',
    },
    placeholder: {
      oldPassword: '現在のパスワードを入力してください',
      newPassword: '新しいパスワードを入力してください',
      confirmPassword: '新しいパスワードを入力してください',
    },
    message: {
      passwordExpired:
        'パスワードの有効期限が切れています。パスワードを変更してください。',
      enterCurrentPasswordAndNewPassword:
        '現在のパスワードと新しいパスワードをそれぞれ入力してください。',
      changePasswordSuccess: 'パスワードの変更が完了しました。',
      newPasswordLength:
        'パスワードは英大文字・英小文字・数字・記号を組み合わせた 12～16 文字でご設定ください。',
      newPasswordFormat:
        'パスワードは英大文字・英小文字・数字・記号を組み合わせた 12～16 文字でご設定ください。',
    },
  },
  A05: {
    pageTitleLogout: 'ログアウト',
    pageTitleSessionTimeOut: 'セッションの有効期限切れ',
    message: {
      logoutSuccessfully: '正常にログアウトしました。',
      sessionTimeout:
        'セッションの有効期限が切れたため自動的にログアウトしました。',
      loginAgain: '恐れ入りますが再度ログインしてください。',
    },
  },
  A06: {
    pageTitle: '仮申込',
    pageTitle2: '仮申込入力内容確認',
    label: {
      bankArea: '地域',
      bankName: '金融機関名',
      introducingCompanyName: '炭削くんの紹介会社',
      email: 'メールアドレス',
      emailConfirm: 'メールアドレス確認',
      L1NoticeTitle: 'ご用意頂くもの',
      L1NoticeContent1: (
        <>
          ・クレジットカード
          <br />
          ※法人名義のクレジットカード、もしくは法人代表者さまのカードをご用意ください
          <br />
          ※経理処理については顧問税理士にご確認ください。
        </>
      ),
      L1NoticeContent2: (
        <>
          ・メールアドレス
          <br />
          ※本サービスをメインで利用されるご担当者様のメールアドレスをご登録ください。
          <br />
          ※登録したメールアドレスは各種通知の送信先となります。必ず、受信可能なメールアドレスをご登録ください。
        </>
      ),
      L2Notice: (
        <>
          ご入力頂いたメールアドレスに本申込用のURLをお送りいたしますので、必要事項の記入をお願いします。
          <br />
          会社情報、クレジットカード情報を事前にご準備ください。
        </>
      ),
      L3Notice: (
        <>
          ご入力内容をご確認下さい。
          <br />
          変更する場合は「戻る」ボタン押下して下さい。
        </>
      ),
      success: '仮申込完了',
      L4Notice: (
        <>
          ご入力頂いたメールアドレスへ本申込用のURLを送信しました。
          <br /> ブラウザ（またはタブ）の閉じるボタンで閉じてください。
        </>
      ),
      step3Notice1:
        '迷惑メール対策としてご自身が指定したドメインやアドレスのみの受信などの設定をされていると、本申込用のメールを受信できない場合があります。',
      step3Notice2:
        '事前に「 system@zero-carbon-system.jp 」からのメールを受信できるように設定をお願い致します。',
      step3Notice3:
        'また、お客様がご使用になっているメールの種類によっては、 自動送信メールが迷惑メールフォルダに入る場合がございます。',
      ourBank: '当行行員からの紹介',
      staffName: `紹介店名`,
      otherCompany: '他社からのご紹介',
      introCompany: '紹介会社からのご紹介',
      homepage: 'ホームページ',
      cm: 'CM・チラシ',
      seminars: 'セミナー・講演会',
      newspaper: '新聞・雑誌記事',
      sns: 'SNS',
      mail: 'メール',
      others: 'その他',
      reference: '炭削くんをどこでお知りになりましたか',
      fromBankDescription: '紹介店名を入力してください',
      fromOthersDescription: '詳細内容を入力してください',
      details: '詳細',
      select1Option: 'オプションを 1 つ選択してください',
      titleResult: '問題が発生しました',
      contentResult: (
        <>
          入力したURLが間違っているようです。
          <br /> URLをご確認いただき、 再度お試しください。
        </>
      ),
    },
    placeholder: {
      bankArea: '地域を選択して下さい',
      bankName: 'お取引金融機関を選択して下さい',
      email: 'メールアドレスを入力して下さい',
      emailConfirm: 'メールアドレスを入力して下さい',
    },
    message: {
      emailFormat: '入力されたメールアドレスは使用できません。',
      emailConfirmFormat:
        'メールアドレスとメールアドレス確認が異なります。正しいメールアドレスが入力されているか確認して下さい。',
    },
  },
  A07: {
    L1Title: '企業利用申込情報入力（本申込）',
    L2Title: '同意事項',
    L3Title: '企業利用申込情報入力内容確認',
    label: {
      bankName: 'お申込み先金融機関名',
      specifiedBusinessNumber: '特定事業者番号',
      corporateNumber: '法人番号',
      businessMinister: '事業所管大臣',
      L2Agreement: 'サービス利用規約および個人情報の取り扱い規定に同意する',
      policy: '個人情報の取り扱いに関する規程',
      term1: 'ご契約内容',
      term2: (
        <>
          <h2 className="tw-mb-20 tw-font-bold">ご契約内容</h2>
          <div className="tw-border-[1px] tw-border-solid tw-border-neutral-8">
            <div className="tw-grid tw-grid-cols-5 tw-border-b-[1px] tw-border-l-0 tw-border-r-0 tw-border-t-0 tw-border-solid tw-border-neutral-8">
              <div className="tw-border-b-0 tw-border-l-0 tw-border-r-[1px] tw-border-t-0 tw-border-solid tw-border-neutral-8 tw-bg-[#B9EBFE]">
                <p className="tw-ml-6 tw-mt-6 tw-text-left">サービス内容</p>
              </div>
              <div className="tw-col-span-4">
                <p className="tw-ml-6 tw-mt-6 tw-text-left">
                  ＣＯ2排出量算定システム <br />
                  「Zero-Carbon-System（炭削くん）」
                </p>
              </div>
            </div>
            <div className="tw-grid tw-grid-cols-5 tw-border-b-[1px] tw-border-l-0 tw-border-r-0 tw-border-t-0 tw-border-solid tw-border-neutral-8">
              <div className="tw-border-b-0 tw-border-l-0 tw-border-r-[1px] tw-border-t-0 tw-border-solid tw-border-neutral-8 tw-bg-[#B9EBFE]">
                <p className="tw-ml-6 tw-mt-6 tw-text-left">基本利用料金 </p>
              </div>
              <div className="tw-col-span-4">
                <p className="tw-ml-6 tw-mt-6 tw-text-left">
                  月額2,200円（税込）
                </p>
              </div>
            </div>
            <div className="tw-grid tw-grid-cols-5 tw-border-b-[1px] tw-border-l-0 tw-border-r-0 tw-border-t-0 tw-border-solid tw-border-neutral-8">
              <div className="tw-border-b-0 tw-border-l-0 tw-border-r-[1px] tw-border-t-0 tw-border-solid tw-border-neutral-8 tw-bg-[#B9EBFE]">
                <p className="tw-ml-6 tw-mt-6 tw-text-left">お支払方法 </p>
              </div>
              <div className="tw-col-span-4">
                <p className="tw-ml-6 tw-mt-6 tw-text-left">
                  クレジットカード払い
                </p>
              </div>
            </div>
            <div className="tw-grid tw-grid-cols-5 tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-t-0 tw-border-solid tw-border-neutral-8">
              <div className="tw-border-b-0 tw-border-l-0 tw-border-r-[1px] tw-border-t-0 tw-border-solid tw-border-neutral-8 tw-bg-[#B9EBFE]">
                <p className="tw-ml-6 tw-mt-6 tw-text-left">利用期間</p>
              </div>
              <div className="tw-col-span-4">
                <p className="tw-ml-6 tw-mt-6 tw-text-left">
                  利用期間は、登録完了日から登録完了日の属する月の末日までとし、毎月１日に1カ月間自動的に延長されます。
                </p>
              </div>
            </div>
          </div>
          <div>
            <p>利用料金詳細</p>
            <div className="tw-border-[1px] tw-border-solid tw-border-neutral-8">
              <div className="tw-grid tw-grid-cols-2 tw-border-b-[1px] tw-border-l-0 tw-border-r-0 tw-border-t-0 tw-border-solid tw-border-neutral-8">
                <div className="tw-border-b-0 tw-border-l-0 tw-border-r-[1px] tw-border-t-0 tw-border-solid tw-border-neutral-8">
                  <p className="tw-ml-6 tw-mt-6 tw-text-left">アカウント </p>
                </div>
                <div className="">
                  <p className="tw-ml-6 tw-mt-6 tw-text-left">料金</p>
                </div>
              </div>
              <div className="tw-grid tw-grid-cols-2 tw-border-b-[1px] tw-border-l-0 tw-border-r-0 tw-border-t-0 tw-border-solid tw-border-neutral-8">
                <div className="tw-border-b-0 tw-border-l-0 tw-border-r-[1px] tw-border-t-0 tw-border-solid tw-border-neutral-8">
                  <p className="tw-ml-6 tw-mt-6 tw-text-left">1~5ユーザー </p>
                </div>
                <div className="">
                  <p className="tw-ml-6 tw-mt-6 tw-text-left">
                    月額2,200円（税込）
                  </p>
                </div>
              </div>
              <div className="tw-grid tw-grid-cols-2 tw-border-b-[1px] tw-border-l-0 tw-border-r-0 tw-border-t-0 tw-border-solid tw-border-neutral-8">
                <div className="tw-border-b-0 tw-border-l-0 tw-border-r-[1px] tw-border-t-0 tw-border-solid tw-border-neutral-8">
                  <p className="tw-ml-6 tw-mt-6 tw-text-left">6~10ユーザー </p>
                </div>
                <div className="">
                  <p className="tw-ml-6 tw-mt-6 tw-text-left">
                    月額3,300円（税込）
                  </p>
                </div>
              </div>
              <div className="tw-grid tw-grid-cols-2 tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-t-0 tw-border-solid tw-border-neutral-8">
                <div className="tw-border-b-0 tw-border-l-0 tw-border-r-[1px] tw-border-t-0 tw-border-solid tw-border-neutral-8">
                  <p className="tw-ml-6 tw-mt-6 tw-text-left">11~15ユーザー </p>
                </div>
                <div className="">
                  <p className="tw-ml-6 tw-mt-6 tw-text-left">
                    月額4,400円（税込
                  </p>
                </div>
              </div>
            </div>
            <p>※以後、5ユーザー毎に加算</p>
          </div>
          <h2 className="tw-my-20 tw-font-bold">炭削くん 利用規約</h2>
          <div>
            <p>
              本利用規約（以下「本規約」といいます。）は、株式会社肥後銀行（以下「当行」といいます。）が「Zero-Carbon-System（炭削くん）」の名称で提供するサービス（以下「本サービス」といいます。）の提供条件及びお客様と当行との間の権利義務関係を定めるものです。本サービスのご利用に際しては、本規約の全文をお読みください。
            </p>
            <br></br>
            <p>第1条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（適用）</p>
            <div className="tw-flex">
              <p className="tw-mr-20">1.</p>
              <p>
                本規約は、本サービスの提供条件及び本サービスの利用に関するお客様と当行との権利義務関係を定めることを目的とし、お客様と当行との間の本サービスに関する一切の関係に適用されます。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">2.</p>
              <p>
                本規約と、本利用契約（第2条第1項で定義します。）その他の本サービスに関して本規約とは別にお客様と当行との間で締結される契約又は規約等（以下これらを総称して「個別契約等」といいます。）が存在する場合、個別契約等も本規約の一部を構成するものとし、個別契約等の定めと本規約の定めが抵触するときは個別契約等の定めが優先するものとします。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">3.</p>
              <p>
                本規約の内容と本規約以外における本サービスの説明等とが異なる場合は、本規約の規定が優先して適用されるものとします。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">4.</p>
              <p>
                本規約のうち当行が本サービスの利用を許諾した時点ではお客様に適用されない規定がある場合、当該規定は、事情変更によって将来適用可能となった時点から適用されるものとします。
              </p>
            </div>
            <br></br>
            <p>第2条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（定義）</p>
            <p>本規約において使用する用語の定義は以下の通りとします。</p>
            <div className="tw-flex">
              <p className="tw-mr-[18px]">1.</p>
              <p>
                「本利用契約」とは、お客様と当行との間で締結される本サービスの提供に関する契約をいいます。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-[18px]">2.</p>
              <p>
                「お客様」とは、本規約の内容に承諾の上、当行所定の手続に従って本サービスの利用を申し込み、当行がその申込を承諾した法人及び団体をいいます。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-[18px]">3.</p>
              <p>
                「当行ウェブサイト」とは、本サービスの専用ページをいいます。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-[18px]">4.</p>
              <p>
                「登録希望者」とは、本サービスの利用を希望し、登録の申込みを行い又は登録の申込みを行おうとする法人、団体又はそれらを代表する個人を意味します。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-[18px]">5.</p>
              <p>
                「登録データ」とは、お客様又はユーザーが本サービスを利用してアップロード等する情報（氏名、メールアドレス、会社情報、電力・ガス等の請求書のデータを含みますが、これらに限りません。）をいいます。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-[18px]">6.</p>
              <p>
                「アカウント」とは、本サービスを利用するための権限をいいます。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-[18px]">7.</p>
              <p>
                「ユーザー」とは、お客様の役員又は従業員のうち、アカウントを有する者をいいます。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-[18px]">8.</p>
              <p>
                「ユーザー責任者」とは、ユーザーのうち、お客様における本サービスを利用する上での責任者をいいます。初期のユーザー責任者は、お申込時に担当者としてご登録いただいたユーザーとし、企業情報ページにて確認・変更できます。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-[18px]">9.</p>
              <p>
                「アカウント情報」とは、ユーザーがアカウントに基づいて本サービスを利用するために必要なID、パスワード等の情報をいいます。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-[10px]">10.</p>
              <p>
                「ID」とは、パスワードと組み合わせて、ユーザーとその他の者を識別するために用いられる符号をいいます。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-[10px]">11.</p>
              <p>
                「パスワード」とは、IDと組み合わせて、ユーザーとその他の者を識別するために用いられる符号をいいます。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-[10px]">12.</p>
              <p>
                「グループ会社」とは、親会社、子会社及び親会社の子会社を指します。
              </p>
            </div>
            <br></br>
            <p>
              第3条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（サービス内容）
            </p>
            <div className="tw-flex">
              <p className="tw-mr-20">1.</p>
              <p>
                本サービスは、電力・ガス等のエネルギーのコストや使用実績、及び電力・ガス等のエネルギー使用量から換算されるCO₂排出量のデータ管理・可視化等を提供するサービスです。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">2.</p>
              <p>
                当行は本サービスの内容又は機能等を、随時追加、変更又は削除等ができるものとし、お客様は異議・申し立てを行わないものとします。また、当行はお客様に対し、本サービスの内容又は機能等の追加、変更、削除による責任を負わないものとします。
              </p>
            </div>
            <br></br>
            <p>第4条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（登録）</p>
            <div className="tw-flex">
              <p className="tw-mr-20">1.</p>
              <p>
                登録希望者は、当行が定める一定の情報（以下「登録事項」といいます。）を当行に提供することにより、本サービスの利用を申請することができます。なお、登録希望者は、本サービスの利用を申請した際に、本規約に同意したものとみなされます。また、当該申請を行った方は、当該申請により、登録希望者を代表して本規約を締結する権限を有することを表明したものとします。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">2.</p>
              <p>
                当行は、前項の申請を受理した後、登録の可否を当行の基準に従って判断し、当行が登録を認める場合にはその旨を登録申請者に通知します。本サービスに関するお客様としての登録は、当行が当該通知を行ったことをもって完了したものとします。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">3.</p>
              <p>
                前項の登録の完了をもって、本規約がお客様と当行との間に成立し、お客様は本サービスを本規約に従い利用できるようになります。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">4.</p>
              <p>
                当行は、以下の各号のいずれかの事由に該当する場合は、登録及び再登録を拒否することがあります。また、その理由について当行は一切開示義務を負わないものとし、審査の異議をお客様は述べられないものとします。
              </p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-20">(1)</p>
              <p>
                当行に提供した登録事項の全部又は一部につき虚偽、誤記又は記載漏れがあった場合
              </p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-20">(2)</p>
              <p>
                本サービスと類似・競合するサービス若しくはウェブサイトを運営している又はその運営に協力していると当行が判断した場合
              </p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-20">(3)</p>
              <p>
                申請を行った方が、登録申請者を代表して、本サービスの利用を申請する正当な権限を有していなかった場合、又は、未成年者、成年被後見人、被保佐人又は被補助人のいずれかであり、法定代理人、後見人、保佐人又は補助人の同意等を得ていなかった場合
              </p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-20">(4)</p>
              <p>
                第22条（反社会的勢力の排除）第1項に定める暴力団員等又は同項第1号ないし第4号に該当する者である又はそのおそれがあると当行が判断した場合
              </p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-20">(5)</p>
              <p>
                過去に当行との契約に違反した者又はその関係者であると当行が判断した場合
              </p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-20">(6)</p>
              <p>
                第13条（禁止事項）各号に掲げる行為を行ったことがある又は行うおそれがあると当行が判断した場合
              </p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-20">(7)</p>
              <p>
                第16条（当行による利用停止又は解除等）に定める措置を受けたことがある場合
              </p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-20">(8)</p>
              <p>その他、当行が登録を適当でないと判断した場合</p>
            </div>
            <br></br>
            <p>
              第5条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（登録事項の変更）
            </p>
            <div className="tw-flex">
              <p className="tw-mr-20">1.</p>
              <p>
                お客様は、登録事項に変更があった場合、本サービス内にて速やかに変更するものとします。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">2.</p>
              <p>
                当行は、お客様が前項の変更を怠ったことにより、お客様又は第三者が損害を被った場合であっても、一切責任を負わないものとします。
              </p>
            </div>
            <br></br>
            <p>
              第6条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（アカウント）
            </p>
            <div className="tw-flex">
              <p className="tw-mr-20">1.</p>
              <p>
                本サービスに関するお客様と当行との間の連絡、確認等は、原則として、ユーザー責任者を通じて行うものとします。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">2.</p>
              <p>
                お客様は、ユーザー責任者を変更する場合には、本サービス内にて速やかに変更するものとします。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">3.</p>
              <p>
                お客様は、自己の責任において、本サービスに関するアカウント情報を適切に管理及び保管するものとし、自ら又はユーザーをして、これを第三者に利用させ、又は貸与、譲渡、名義変更、売買等をしてはならないものとします。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">4.</p>
              <p>
                アカウント情報の管理不十分、使用上の過誤、第三者の使用等によって生じた損害に関する責任はお客様が負うものとし、当行は一切の責任を負いません。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">5.</p>
              <p>
                当行は、アカウント情報の提供後に行われた当該アカウント情報による本サービスの利用行為については、すべてお客様ご自身が実施したものとしてお客様にその効果が帰属するものとみなします。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">6.</p>
              <p>
                当行は、当行の基準により、お客様のアカウントが不正使用されている可能性があると当行が判断した場合は、当該アカウントを停止することができます。その場合、お客様は当行が定める手続きに伴い、利用停止措置の解除手続きを行うこととします。なお、当行がこのような措置をとったことにより、お客様がアカウントを使用できないことを理由として損害が生じても、当行は一切の責任を負いません。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">7.</p>
              <p>
                お客様は、第三者にアカウント情報を知られた場合は、直ちに当行に連絡するものとします。その際に、当行からお客様にアカウント停止等の要請があった場合は従うものとします。
              </p>
            </div>
            <br></br>
            <p>
              第7条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（利用期間）
            </p>
            <p>
              利用期間は、自動的に延長されます。解約の手続きは第17条に基づくものとします。
            </p>
            <br></br>
            <p>
              第8条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（利用料金）
            </p>
            <div className="tw-flex">
              <p className="tw-mr-20">1.</p>
              <p>お客様は、当行が別途定める利用料金を支払うものとします。</p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">2.</p>
              <p>
                当行は、お客様の月末時点のユーザー数に応じて料金を確定し、お客様に請求するものとします。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">3.</p>
              <p>
                当行は、お客様の同意なく、当行の裁量により本サービスの利用料金を変更することがあります。この場合、当行は、利用料金の変更の3か月前までに、当行の定める方法によりお客様へ当該変更を通知します。お客様は、当該利用料金の変更に不服がある場合、第17条第1項に基づき、本規約及び個別契約等を解約し、自己のお客様としての登録を抹消できます。お客様への利用料金の変更の通知の後、当該変更が適用される日までの間に、お客様が本サービスを利用した場合又は解約の手続をとらなかった場合には、お客様は、当該利用料金の変更に同意したものとみなします。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">4.</p>
              <p>
                お客様は、当行が別途定める方法でのみ、本サービスに係る利用料金の決済を行うことができます。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">5.</p>
              <p>
                本サービスの利用期間が、暦月において１ヶ月に満たぬ利用期間となった場合にも1ヶ月分の利用料金をお客様に請求するものとします。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">6.</p>
              <p>
                第16条（当行による利用停止又は解除等）及び第17条（解約）に基づく解除又は解約があった場合であっても、当行は、利用料金の日割り計算及び払い戻しは行わないものとし、お客様は、解除又は解約日に関わらず利用期間分の利用料金を当行に支払うものとします。
              </p>
            </div>
            <br></br>
            <p>
              第9条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（利用状況データ及びフィードバック）
            </p>
            <div className="tw-flex">
              <p className="tw-mr-20">1.</p>
              <p>
                お客様は、当行が本サービスの利用状況を把握するための仕組みであるクッキー（Cookie）により利用状況を記録することに同意するものとします。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">2.</p>
              <p>
                当行は前項に基づき記録されたデータ、登録データその他のお客様及びユーザーに関するデータ（以下あわせて「利用状況データ」といいます。）を、本サービスを含む当行又は当行グループが提供し又は提供しようとしているサービスの提供、開発、改善、品質向上、お客様をはじめとする顧客の皆様へのご提案、ご案内の目的で利用し、かつ、当行のグループ会社に開示して共同利用することができるものとします。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">3.</p>
              <p>
                当行は、統計的目的で本サービスの利用状況又はお客様及びユーザーの状況を分析し、統計結果を一般に公表することがあります。但し、個々のお客様及び特定の個人が識別されることのないようにいたします。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">4.</p>
              <p>
                当行は、お客様から本サービスの利用情報を取得し、又はお客様に対して本サービスに関するフィードバックの提供を求める場合があります。お客様は、これらの利用情報又はフィードバックの内容につき、個別契約等期間中及び個別契約等終了後において当行が利用することに同意するものとします。ただし、これらの利用情報又はフィードバックの内容の利用の目的は、本サービスの運営、改善、プロモーション並びに当行及び当行のグループ会社による新サービスの開発に限定されます。
              </p>
            </div>
            <br></br>
            <p>
              第10条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（電力ガス等関連データ）
            </p>
            <div className="tw-flex">
              <p className="tw-mr-20">1.</p>
              <p>
                お客様は、本サービスにおいて、当行がお客様から受領するお客様の電力・ガス等の使用に関する使用量、料金、CO₂排出量その他の情報及びお客様の属性に関する情報(業種、拠点数、その他の企業の属性に関する情報を含みます。）（以下「電力ガス等関連データ」といいます。）について、当行が本条の定めに従い使用することに同意するものとします。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">2.</p>
              <p>
                当行は、電力ガス等関連データ（お客様を特定できないデータに限る。）を、本サービスを含む当行又は当行グループが提供し又は提供しようとしているサービスの提供、開発、改善、品質向上、お客様をはじめとする顧客の皆様へのご提案、ご案内の目的で利用し、かつ、当行のグループ会社に開示して共同利用することができるものとします。また、当行及び当行グループ会社は、その委託先に対し、電力ガス等関連データを開示できるものとします。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">3.</p>
              <p>
                当行は、統計的目的で電力ガス等関連データを分析し、統計結果を一般に公表することがあります。但し、個々のお客様及び特定の個人が識別されることのないようにいたします。
              </p>
            </div>
            <br></br>
            <p>
              第11条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（委託）
            </p>
            <p>
              当行は、本サービスの提供に関して、業務の全部又は一部を、当行の責任において第三者に委託することがあります。ただし、その場合、当行は責任をもって委託先を管理するものとします。
            </p>
            <br></br>
            <p>
              第12条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（権利帰属）
            </p>
            <div className="tw-flex">
              <p className="tw-mr-20">1.</p>
              <p>
                本サービス及び当行ウェブサイトに関する知的財産権等その他一切の有体・無体の財産権は全て当行又は当行にライセンスを許諾している者に帰属しており、お客様に対して譲渡又は本規約に定める以上の使用許諾を行うものではなく、かつその他の権利を付与するものではありません。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">2.</p>
              <p>
                当行はお客様の名称を本サービス導入企業として公開することができるものとします。この場合、当行は事例を公開するために必要な範囲で、お客様のロゴ及び商標等を無償で使用できるものとします。お客様は、当該公開又はロゴ及び商標等の使用の停止や削除、その他必要な措置を当行に請求することができるものとします。
              </p>
            </div>
            <br></br>
            <p>
              第13条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（禁止事項）
            </p>
            <div className="tw-flex">
              <p className="tw-mr-20">1.</p>
              <p>
                お客様は、本サービスの利用にあたり、自ら又はユーザーをして、以下の各号のいずれかに該当する行為又は該当すると当行が判断する行為をしてはなりません。また、お客様の以下の行為によって当行に損害が生じた場合、お客様は責任を負うものとします。
              </p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-20">(1)</p>
              <p>法令に違反する行為又は犯罪行為に関連する行為</p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-20">(2)</p>
              <p>
                当行、他のお客様若しくはユーザー又はその他の第三者に対する詐欺又は脅迫行為
              </p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-20">(3)</p>
              <p>公序良俗に反する行為</p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-20">(4)</p>
              <p>
                当行、他のお客様若しくはユーザー又はその他の第三者の著作権、特許権、実用新案権、意匠権、商標権その他の知的財産権（それらの権利を取得し又はそれらの権利につき登録等を出願する権利を含み、以下「知的財産権等」と総称します。）、肖像権、プライバシーの権利、名誉、その他の権利又は利益を侵害する行為
              </p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-20">(5)</p>
              <p>
                本サービスのネットワーク又はシステム等に過度な負担をかける行為
              </p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-20">(6)</p>
              <p>
                本サービスの運営を妨害するおそれのある行為、当行のネットワーク若しくはシステム等に不正にアクセスする行為、又は不正なアクセスを試みる行為
              </p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-20">(7)</p>
              <p>
                逆コンパイル又は逆アセンブル等、当行のソフトウェア等を解析するための一切のリバースエンジニアリング行為
              </p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-20">(8)</p>
              <p>第三者に成りすます行為</p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-20">(9)</p>
              <p>
                同一の法人、団体又は人物が、その必要がないにも関わらず複数のお客様登録を行なう行為
              </p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-12">(10)</p>
              <p>他のお客様又はユーザーのアカウントを利用する行為</p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-12">(11)</p>
              <p>
                当行が事前に承諾しない本サービス上での宣伝、広告、勧誘又は営業行為
              </p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-12">(12)</p>
              <p>本サービスの他のお客様の情報の収集行為</p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-12">(13)</p>
              <p>
                当行、他のお客様若しくはユーザー又はその他の第三者に不利益、損害、不快感を与える行為
              </p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-12">(14)</p>
              <p>反社会的勢力等への利益供与行為</p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-12">(15)</p>
              <p>前各号の行為を直接又は間接に惹起し、又は容易にする行為</p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-12">(16)</p>
              <p>その他、当行が不適切と判断する行為</p>
            </div>
            <br></br>
            <p>
              第14条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（本サービスの停止等）
            </p>
            <div className="tw-flex">
              <p className="tw-mr-20">1.</p>
              <p>
                当行は以下のいずれかに該当する場合には、お客様に事前に通知することなく、本サービスの全部又は一部の提供を停止又は中断できるものとします。
              </p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-20">(1)</p>
              <p>
                本サービスに係るコンピューター・システムの点検又は保守作業を緊急に行う場合
              </p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-20">(2)</p>
              <p>
                コンピューター又は通信回線等が事故により停止した場合、予期しない不正アクセス等の行為が判明した場合
              </p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-20">(3)</p>
              <p>
                本サービスの提供に必要な外部システム（Microsoft
                Azure等）の提供又は利用が遮断された場合
              </p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-20">(4)</p>
              <p>
                地震、落雷、火災、風水害、停電、疾病の蔓延、天災地変等の不可抗力により本サービスの運営ができなくなった場合
              </p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-20">(5)</p>
              <p>その他、当行が停止又は中断を必要と判断した場合</p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">2.</p>
              <p>
                当行は、本条に基づき当行が行った措置によって生じた損害について一切の責任を負いません。
              </p>
            </div>
            <br></br>
            <p>
              第15条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（本サービスの変更又は終了）
            </p>
            <div className="tw-flex">
              <p className="tw-mr-20">1.</p>
              <p>
                当行は、当行の都合により、本サービスの内容の変更又は提供の終了ができるものとします。本サービスの提供を終了する場合、当行はお客様に対して終了予定日の3ヶ月前までに通知を行うものとします。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">2.</p>
              <p>
                当行は、本条に基づき当行が行った措置によって生じた損害について一切の責任を負いません。
              </p>
            </div>
            <br></br>
            <p>
              第16条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（当行による利用停止又は解除等）
            </p>
            <div className="tw-flex">
              <p className="tw-mr-20">1.</p>
              <p>
                当行は、お客様が以下の各号のいずれかの事由に該当する場合、事前の通知又は催告をすることなく、保存されているデータ（登録事項及び登録データを含みますが、これに限られません。）の削除、本サービス利用の一時的な停止、登録の抹消又は個別契約等の解除を行うことができるものとします。
              </p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-20">(1)</p>
              <p>本規約及び個別契約等のいずれかの条項に違反した場合</p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-20">(2)</p>
              <p>登録事項に虚偽の事実があることが判明した場合</p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-20">(3)</p>
              <p>
                当行からの問い合わせ、その他の回答を求める連絡に対して14日間以上応答がない場合
              </p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-20">(4)</p>
              <p>第4条（登録）第4項各号に該当する場合</p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-20">(5)</p>
              <p>3ヶ月連続で利用料金のお支払が確認できなかった場合</p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-20">(6)</p>
              <p>
                支払停止若しくは支払不能となり、又は破産手続開始、民事再生手続開始、会社更生手続、特別清算開始若しくはこれらに類する手続の開始の申立てがあった場合
              </p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-20">(7)</p>
              <p>
                差押え、仮差押え、仮処分、競売の申立て、租税滞納処分、その他公権力の処分を受けたとき、又はそれらのおそれがある場合（ただし、本規約の履行に重大な影響を与えない軽微なものは除く
              </p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-20">(8)</p>
              <p>
                監督官庁により事業停止処分、又は事業免許若しくは事業登録の取消処分を受けた場合
              </p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-20">(9)</p>
              <p>
                その他、当行が、お客様の本サービスの利用、登録又は個別契約等の継続を適当でない、又は、当行が定める品質で本サービスを提供することが困難であると判断した場合
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">2.</p>
              <p>
                前項各号のいずれかの事由に該当した場合、お客様は、当行に対して負っている債務の一切について当然に期限の利益を失い、直ちに当行に対して全ての債務の支払いを行なわなければなりません。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">3.</p>
              <p>
                当行は、本条に基づき当行が行った行為によりお客様及びユーザーに生じた損害について一切の責任を負いません。
              </p>
            </div>
            <br></br>
            <p>
              第17条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（解約）
            </p>
            <div className="tw-flex">
              <p className="tw-mr-20">1.</p>
              <p>
                お客様は、別途定める当行所定の方法で当行に通知することにより、本規約及び個別契約等を解約し、自己のお客様としての登録を抹消できます。退会月の末日まで本サービスは利用可能です。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">2.</p>
              <p>
                解約にあたりお客様が当行に対して債務を負っている場合、お客様は、当行に対する債務の一切について当然に期限の利益を失い、直ちに当行に対する債務の全てについて履行しなければなりません。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">3.</p>
              <p>
                お客様の解約により、お客様・第三者に対して損害が発生した場合は、お客様が責任を負うものとします。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">4.</p>
              <p>
                お客様は、解約後に再登録手続きをした場合、解約前のデータが引き継がれない可能性があることについて、承諾します
              </p>
            </div>
            <br></br>
            <p>
              第18条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（契約終了後の効力）
            </p>
            <p>
              本規約及び個別契約等が、期間満了若しくは解除等により終了した後においても、第12条（権利帰属）、第20条（損害賠償）、第21条（秘密保持）、第22条（反社会的勢力の排除）、第30条（準拠法及び管轄裁判所）及び本条の規定はなお有効とし、お客様及び当行は当該条項に基づく債務等を履行又は遵守します。
            </p>
            <br></br>
            <p>
              第19条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（お客様の責任及び保証の制限）
            </p>
            <div className="tw-flex">
              <p className="tw-mr-20">1.</p>
              <p>
                お客様は、本サービスをお客様が利用するために必要な環境や設備（インターネット回線、パソコン等のハードウェア、Webブラウザ等のソフトウェア等を含みますがこれらに限りません。）を自己の責任と費用において適切に用意する必要があり、当行は、これらの用意に関する責任を負いません。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">2.</p>
              <p>
                お客様が本サービスの利用して行った行為は、全てお客様が責任を負うものとします。本サービスの不具合によって、お客様・第三者に生じた不利益・損害は当行に故意・重過失が無い場合は一切責任を負わないものとし、本サービスの利用により、第三者からクレームを受けた場合もお客様の責任・費用を持って処理及び解決するものとします。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">3.</p>
              <p>
                当行は本サービスの提供について、正確性・完全性・合目的性・確実性・安全性・適法性・可用性を実現できるようには努めますが、これについて如何なる保証も行わず、一切責任を負わないものとします。尚、本サービスにおける掲載情報は掲載した時点の情報であり、お客様の閲覧時においては古い情報が掲載されている場合があることについて、お客様は了承するものとします。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">4.</p>
              <p>
                当行は、登録データの消去、喪失等に関連してお客様が被った損害について、一切の責任を負いません。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">5.</p>
              <p>
                当行は、本サービスの利用不能若しくは本サービスの利用による機器の故障若しくは損傷によって生じた損害、又は第三者が当行ウェブサイト上で提供される情報を当行ウェブサイト以外のウェブサイト、文書等に転載したことにより発生した損害、その他の第三者の行為に起因して生じた損害について、一切の責任を負いません。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">6.</p>
              <p>
                本サービス又は当行ウェブサイトに関連してお客様と提携企業又は第三者との間において生じた取引、連絡若しくは紛争等について、当行は一切の責任を負いません。もしそれらに関連して当行に費用が発生した場合又は当行が賠償金の支払いを行なった場合には、お客様は当行に対して、当該費用（弁護士等の専門家の費用を含む）及び賠償金を補償するものとし、当行はお客様にこれらの合計額の支払いを請求できるものとします。
              </p>
            </div>
            <br></br>
            <p>
              第20条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（損害賠償）
            </p>
            <div className="tw-flex">
              <p className="tw-mr-20">1.</p>
              <p>
                当行は、本規約の違反に起因して、お客様が損害を被った時は、理由を問わず、当行は一切の責任を負いません。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">2.</p>
              <p>
                本サービスに掲載している情報を、不正アクセス等で第三者に取得され、お客様又は第三者が被った被害について、理由や情報取得の経路、経緯を問わず、当行は一切の責任を負いません。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">3.</p>
              <p>
                本規約の違反等、お客様の責で当行及び第三者に損害を与えた場合は、お客様が自己の費用と責任を持って解決するものとします。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">4.</p>
              <p>
                お客様が本規約に違反する行為又は不正若しくは違法な行為によって当行に損害を与えた場合、当行は当該お客様などに対してその損害の賠償を請求できるものとします。
              </p>
            </div>
            <br></br>
            <p>
              第21条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（秘密保持）
            </p>
            <div className="tw-flex">
              <p className="tw-mr-20">1.</p>
              <p>
                お客様は、当行に情報を開示する場合、自らの意思で開示したものとし、本サービスの利用により生ずるすべての結果について自ら責任を負うものとします。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">2.</p>
              <p>
                お客様及び当行は、本規約又は本サービスの利用に際して当行ウェブサイトを介して相手方から開示された情報（当行が本サービスにおいてお客様に提供する一切の情報、画像、データとこれらのデザイン、機能に関する情報を含みます。）、及び、開示に際して開示当事者が秘密であることを示して開示した情報について、相手方の事前の書面による承諾がある場合を除き、秘密情報として取り扱うものとし、そのいかなる部分も、開示当事者の事前の書面による承諾なく第三者に対して開示せず、かつ、本サービスの提供又は利用の目的以外で使用しないものとします。但し、第9条に基づく利用状況データ及びフィードバックに関する情報、第10条に基づく電力ガス等関連データ、並びに次の各号のいずれかに該当するものについては秘密情報には該当しないものとします。
              </p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-20">(1)</p>
              <p>開示を受けたときに既に保有していた情報</p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-20">(2)</p>
              <p>
                開示を受けた後、秘密保持義務を負うことなく第三者から正当に入手した情報
              </p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-20">(3)</p>
              <p>
                開示を受けた後、相手方から開示を受けた情報に関係なく独自に取得し、又は創出した情報
              </p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-20">(4)</p>
              <p>開示を受けたときに既に公知であった情報</p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-20">(5)</p>
              <p>
                開示を受けた後、自己の責めに帰し得ない事由により公知となった情報
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">3.</p>
              <p>
                前項に拘らず、秘密情報の受領当事者は、本サービスの提供又は利用に際して秘密情報を知る必要のある自己の役職員、弁護士及びコンサルタント等の専門家、グループ会社、提携企業、並びに、当行については、第26条第1項に定める規程等に定める共同利用の相手方、第27条第2項に定める譲渡の譲受人、業務委託先、及び本ソフトウェア開発の委託先に対して秘密情報を開示することができるものとします。但し、秘密情報の受領当事者は、秘密情報の開示に先立ち、これらの開示を受ける者に対して、法令上の守秘義務を負う場合を除き、本規約に定めるのと同等の秘密保持義務を課すものとします。受領当事者は、これらの開示を受ける者の秘密保持義務違反及び守秘義務違反について一切の責任を負うものとします。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">4.</p>
              <p>
                本条第2項に拘らず、受領当事者が、法令若しくは金融商品取引所規則に基づいて秘密情報の開示が必要となる場合、又は裁判所、監督官庁等の行政機関等より秘密情報の開示を命じられた場合には、必要な限度で開示を行うことができるものとします。この場合、受領当事者は速やかに、法令上許容される限度で、開示が必要となり、又は開示命令を受けた旨を開示当事者に通知するものとします。
              </p>
            </div>
            <br></br>
            <p>
              第22条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（反社会的勢力の排除）
            </p>
            <div className="tw-flex">
              <p className="tw-mr-20">1.</p>
              <p>
                お客様及び当行は、相手方に対し、自己又は自己の役員若しくは自己の従業員が、現時点において、暴力団、暴力団員、暴力団員でなくなったときから5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動標榜ゴロ又は特殊知能暴力集団等、その他これらに準ずる者（以下これらを「暴力団員等」といいます。）に該当しないこと及び各号のいずれにも該当しないことを表明し、かつ将来にわたっても該当しないことを確約するものとします。
              </p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-20">(1)</p>
              <p>
                暴力団員等が経営を支配している又は経営に実質的に関与していると認められる関係を有すること
              </p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-20">(2)</p>
              <p>
                自己又は第三者の不正の利益を図る目的又は第三者に損害を加える目的をもってする等、不当に暴力団員等を利用していると認められる関係を有すること
              </p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-20">(3)</p>
              <p>
                暴力団員等に対して資金等を提供し、又は便宜を供与する等の関与をしていると認められる関係を有すること
              </p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-20">(4)</p>
              <p>
                役員又は経営に実質的に関与している者が暴力団員等と社会的に非難されるべき関係を有すること
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">2.</p>
              <p>
                お客様及び当行は、自ら又は第三者を利用して各号のいずれかに該当する行為を行わないことを確約します。
              </p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-20">(1)</p>
              <p>暴力的な要求行為</p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-20">(2)</p>
              <p>法的な責任を超えた不当な要求行為</p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-20">(3)</p>
              <p>取引に関して、脅迫的な言動をし、又は暴力を用いる行為</p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-20">(4)</p>
              <p>
                風説を流布し、偽計を用い又は威力を用いて相手方の信用を毀損し、又は相手方の業務を妨害する行為
              </p>
            </div>
            <div className="tw-ml-[10px] tw-flex">
              <p className="tw-mr-20">(5)</p>
              <p>その他前各号に準ずる行為</p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">3.</p>
              <p>
                お客様及び当行は、相手方が前２項のいずれかに違反した場合は、本規約、個別契約等の全部又は一部につき相手方の有する期限の利益を損失させ、また、何らの催告をせず、本規約、個別契約等を解除することができるものとします。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">4.</p>
              <p>
                お客様及び当行は、前項に基づく解除により解除された当事者が被った損害につき、一切の義務及び責任を負わないものとします。
              </p>
            </div>
            <br></br>
            <p>
              第23条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（本規約等の変更）
            </p>
            <div className="tw-flex">
              <p className="tw-mr-20">1.</p>
              <p>
                当行は、お客様の一般の利益に適合する場合、又は社会情勢、経済事情若しくは本サービスに関する実情の変化若しくは法令の変更その他合理的な事由があると認められる場合には、民法の定型約款の変更に係る規定に基づき、本サービスの目的に反しない範囲で本規約の内容を変更できるものとします。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">2.</p>
              <p>
                当行は、前項の定めに基づいて本規約の変更を行う場合は、変更後の利用規約の内容を、当行ウェブサイト（お客様がID及びパスワードを入力した上で閲覧可能な本サービス用ウェブサイトを指します。リンク先及び閲覧方法については、別途当行より通知します。）上に表示し、又は当行の定める方法によりお客様に通知することでお客様に周知するものとし、その周知の際に定める相当な期間を経過した日から、変更後の利用規約が適用されるものとします。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">3.</p>
              <p>
                当行は、本条第1項の定めに基づかずに本規約の変更を行う場合、変更後の利用規約の内容についてお客様の同意を得るものとします。この場合も、当行は、変更後の利用規約の内容を前項の定めに従って周知するものとします。お客様は、かかる変更に不服がある場合、第17条第1項の定めに従い、本規約及び個別契約等を解約し、自己のお客様としての登録を抹消できます。かかる周知の後、当該変更後の利用規約が適用される日までの間に、お客様が本サービスを利用した場合又は解約の手続をとらなかった場合には、お客様は当該変更後の利用規約の内容に同意したものとみなします。
              </p>
            </div>
            <br></br>
            <p>
              第24条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（連絡又は通知）
            </p>
            <div className="tw-flex">
              <p className="tw-mr-20">1.</p>
              <p>
                お客様から当行に対する本サービスに関する問い合わせその他の連絡又は通知は、当行の定める方法で行うものとします。尚、当行はお客様からの問い合わせやその他連絡又は通知が、インターネット上の不具合等により、着信しなかった場合の責任は負わないものとします。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">2.</p>
              <p>
                当行からお客様に対する本サービスに関する連絡又は通知は、ユーザーに対して、登録事項に含まれるメールアドレスに電子メールを送る方法その他当行の定める方法（炭削くん内のお知らせ含む）によって行うものとします。当行がユーザーの登録事項に含まれるメールアドレスその他の連絡先に対して連絡又は通知を行った場合、お客様は当該連絡又は通知を受領し、理解したものとみなします。
              </p>
            </div>
            <br></br>
            <p>
              第25条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（お客様への告知・アンケート等）
            </p>
            <div className="tw-flex">
              <p className="tw-mr-20">1.</p>
              <p>
                当行は、お客様に対し、本サービスの利用条件等につき、必要に応じ随時当サイト上への掲示により告知を行うことができるものとします。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">2.</p>
              <p>
                当行は、お客様に対し、本サービスの運営にあたり、必要に応じ随時電子メール、ダイレクトメール・電話等により連絡および要請を行うことができるものとします。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">3.</p>
              <p>
                当行は、お客様に対し、今後のサービスの充実や検討にあたり、必要に応じ随時電子メール、ダイレクトメール・電話等によりアンケートのご協力要請を行うことができるものとします。
              </p>
            </div>
            <br></br>
            <p>
              第26条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（個人情報の取扱い等）
            </p>
            <div className="tw-flex">
              <p className="tw-mr-20">1.</p>
              <p>
                当行は、別途当行ウェブサイトに掲示する個人情報の取り扱いに関する規程（以下本条において「規程等」といいます。）に基づき、適切に取扱います。お客様は、当行が規程等に従って、個人情報の第三者への開示及び利用（共同利用を含みます。）を実施することに同意します。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">2.</p>
              <p>
                当行は、規程等に基づき、本サービス提供およびこれに付随する業務に必要は範囲で、個人情報の取り扱いを業務委託先に委託することができるものとします。この場合、委託先の選定及び監督に関して、当行は善管注意義務に従いこれをなすものとします。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">3.</p>
              <p>
                前項の規定に拘らず、当行は、お客様が当行に提供した情報、データ等を、個人を特定できない統計的な情報として、当行の裁量で、利用及び公開することができるものとし、お客様は当行の利用及び公開に対して異議を唱えません。
              </p>
            </div>
            <br></br>
            <p>
              第27条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（本契約上の地位の譲渡等）
            </p>
            <div className="tw-flex">
              <p className="tw-mr-20">1.</p>
              <p>
                お客様は、当行の書面による事前の承諾なく、本規約、本利用契約及び個別契約等上の地位又は本規約、本利用契約及び個別契約等に基づく権利若しくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分はできません。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">2.</p>
              <p>
                当行は、本サービスにかかる事業を他社に譲渡した場合には、当該譲渡に伴い本規約、本利用契約及び個別契約等上の地位、本規約、本利用契約及び個別契約等に基づく権利及び義務並びにお客様の登録事項その他の顧客情報を当該譲渡の譲受人に譲渡できるものとし、お客様は、かかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、事業会社設立、会社分割、合併、その他事業が移転するあらゆる場合を含むものとします。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">3.</p>
              <p>
                当行は、前項に基づき、本規約、本利用契約及び個別契約等上の地位、本規約、本利用契約及び個別契約等に基づく権利及び義務並びにお客様の登録事項その他の顧客情報を他社に譲渡する場合には、当行の定める方法により、当該他社の名称及び住所並びに当該譲渡を行う旨を当該譲渡前にお客様に通知します。
              </p>
            </div>
            <br></br>
            <p>
              第28条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（分離可能性）
            </p>
            <div className="tw-flex">
              <p className="tw-mr-20">1.</p>
              <p>
                本規約のいずれかの条項の全部又は一部が法令等により無効又は執行不能と判断された場合であっても、本規約における残りの条項、及び条項の一部が無効又は執行不能と判断された場合の当該条項の残りの部分は、継続して完全に効力を有するものとします。
              </p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">2.</p>
              <p>
                本規約のいずれかの条項又はその一部が、あるお客様との関係で無効又は執行不能と判断された場合であっても、他のお客様との関係における有効性等には影響を及ぼさないものとします。
              </p>
            </div>
            <br></br>
            <p>
              第29条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（完全合意条項）
            </p>
            <p>
              本規約は、本規約の対象事項に関する当事者間の完全な合意を示すものであり、本規約締結までにお客様・当行間でなされた書面、口頭又は黙示的になされたあらゆる合意はその効力を失うものとします。
            </p>
            <br></br>
            <p>
              第30条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（準拠法及び管轄裁判所）
            </p>
            <div className="tw-flex">
              <p className="tw-mr-20">1.</p>
              <p>本規約の準拠法は、日本法とします。</p>
            </div>
            <div className="tw-flex">
              <p className="tw-mr-20">2.</p>
              <p>
                本規約に起因又は関連する一切の紛争については、当行の本店所在地を管轄する地方裁判所を第一審の専属的合意管轄裁判所とします。
              </p>
            </div>
            <br></br>
            <br></br>
            <p>2024年1月29日制定</p>
          </div>
        </>
      ),
      L3Agreement:
        'サービス利用規約 および 個人情報の取り扱いに関する規程に同意する',
      success: '本申込完了',
      successMsg: (
        <>
          ・本申込を受け付けました。ご入力いただいたメールアドレス宛に自動返信メールを送信しております。ご確認ください。
          <br />
          ・ご入力いただきました内容を事務局にて確認後、確認結果をメールまたは電話にて連絡致します。
          <br />
          ・事務局による確認には1~3営業日を頂戴しております。予めご了承下さい。
        </>
      ),
      successMsg2: 'ブラウザ（またはタブ）の閉じるボタンで閉じてください。',
      fail: 'URL有効期限切れ',
      failMsg: (expirationPeriodHours: string) => (
        <>
          URL の有効期限が切れています。
          <br />
          仮申込から
          {expirationPeriodHours !== 'undefined' ? expirationPeriodHours : 72}
          時間以内に本申込を行っていただきますようお願い申し上げます。
          <br />
          お手数ですが下記リンクより再度仮申込のお手続きをお願い致します。
        </>
      ),
      A09_fail: '決済処理に問題が発生しました',
      A09_failMsg: (msg: string) => (
        <div className="tw-flex tw-flex-col tw-gap-24">
          <p>
            大変申し訳ございませんが、クレジットカード情報の登録中にエラーが発生しました。以下の点をご確認ください。
          </p>
          <HgbBulletItem>{msg}</HgbBulletItem>
          <p>これらを確認の上、数分後に再度お試し下さい。</p>
          <p>
            この問題が続く場合は、カード発行会社にお問い合わせいただくか、炭削くん事務局までご連絡をお願い致します。
          </p>
          <p>
            お客様にはご不便をおかけしておりますが、ご理解とご協力をお願い申し上げます。
          </p>
        </div>
      ),
    },
    placeholder: {
      enterpriseCode: '',
      tradeName: '',
      representative: '',
      representativeKana: '',
      address1: '',
      address2: '',
      numberOfEmployees: '',
      specifiedEmitterCode: '',
      specifiedBusinessNumber: '',
      corporateNumber: '',
      controlNumber: '',
      startMonthOfYear: '',
      business: '',
      businessCode: '',
      businessMinister: '',
      billingDate: '',
      maximumNumberOfUsers: '',
    },
    message: {
      postalCodeFormat: '郵便番号が正しくありません。',
      deleteEnterpriseAlertSplit1: '企業「',
      deleteEnterpriseAlertSplit2: '」を削除しますか？',
      branchNumberFormat: '3桁で入力して下さい',
      corporateAccountNumberFormat: '7桁で入力して下さい',
      phoneNumberFormat: '電話番号が正しくありません。',
    },
  },
  A08: {
    mainTitle: '企業利用申込（本申込）',
    title: '料金のご請求とお支払い',
    subTitle: (
      enterpriseMonthlyFree: string | 0,
      enterpriseTrialMonth: number | undefined,
      subscriptionType: string,
    ) => (
      <>
        基本月額
        <span className="tw-text-[24px] tw-font-bold tw-leading-[42px]">
          {subscriptionType !== 'COMPLIMENTARY' ? enterpriseMonthlyFree : 0} 円
        </span>
        {subscriptionType !== 'COMPLIMENTARY' && '(税込)'} <br />
        {subscriptionType !== 'COMPLIMENTARY'
          ? `※ご利用開始月を含む ${
              enterpriseTrialMonth ? enterpriseTrialMonth : 0
            } ヶ月間は無料`
          : `※無料でご利用いただけます`}
      </>
    ),
    content: (enterpriseMonthlyFree: string | 0) => (
      <>
        <p className="tw-text-h4">
          <p className="tw-text-h4 tw-font-bold">料金の支払い方法</p>
          <HgbBulletItem>
            クレジットカードによるお支払いがご利用いただけます。
          </HgbBulletItem>
          <HgbBulletItem>
            口座振替、請求書でのお支払いには対応しておりません。
          </HgbBulletItem>
        </p>
        <p className="tw-text-h4">
          <p className="tw-text-h4 tw-font-bold">請求スケジュール</p>
          <HgbBulletItem>
            毎月1日0:00時点で1ヶ月分の請求が確定いたします。
          </HgbBulletItem>
          <HgbBulletItem>料金はユーザー数によって変動します。</HgbBulletItem>
          <HgbBulletItem>
            基本料金は5ユーザーまで{enterpriseMonthlyFree}円です。
          </HgbBulletItem>
          <HgbBulletItem>詳細は規約をご参照ください。</HgbBulletItem>
        </p>
      </>
    ),
    notice:
      '※これより先は炭削くんと提携しているソニーペイメントサービス株式会社でのお手続きとなります。',
  },
  D01: {
    pageTitleEnterprise: '企業情報',
    pageTitleBank: '企業一覧',
    modal: {
      enterpriseAddTitle: '企業新規登録',
      enterpriseDeleteTitle: '企業削除のお知らせ',
      enterpriseUpdateTitle: '企業編集',
      enterpriseViewTitle: '企業情報',
      enterpriseApproveTitle: '承認確認',
      enterpriseDeniedTitle: '否認確認',
      enterpriseUpdateConfirmModalTitle: '企業情報変更確認',
      enterpriseAppropvalModalTitle: '企業利用申込承認',
      enterpriseUpdateCradModalTitle: (
        <p className="tw-text-[16px] tw-font-bold tw-leading-[24px]">
          クレジットカード変更完了
        </p>
      ),
    },
    table: {
      id: 'ID',
      representative: '代表者名',
      address1: '住所',
      phoneNumber: '電話番号',
      billingDate: '請求開始年月',
      number: '店番-口座番号',
      picName: '担当者名',
      status: 'ステータス',
      email: 'メールアドレス',
      url: 'URLの有効期限',
      lastActivityModifyDate: '最終更新日',
    },
    label: {
      scope3DirectInput: 'SCOPE3 直接入力使用カテゴリ',
      isInvested: '投融資',
      activeTab: '本申込み',
      pendingTab: ' 仮申込中',
      cif: '企業識別キー情報',
      approve: '企業利用ステータス',
      pending: '事務局承認待ち（一時保存）',
      active: '事務局承認',
      inactive: '事務局否認',
      approveContent: (compName: string) => {
        return (
          <div className="tw-flex tw-flex-col tw-gap-24">
            <p>下記企業の利用申込を承認します。</p>
            <HgbBulletItem>{compName}</HgbBulletItem>
            <p>
              承認すると企業担当者へアカウント情報がメールで送付されます。メール送信は取り消しできません。
            </p>
            <p>よろしいですか？</p>
          </div>
        );
      },
      deniedContent: (compName: string) => {
        return (
          <div className="tw-flex tw-flex-col tw-gap-24">
            <p>下記企業の利用申込を否認します。</p>
            <HgbBulletItem>{compName}</HgbBulletItem>
            <p>
              否認すると企業担当者へ否認の連絡がメールで送付されます。メール送信は取り消しできません。
            </p>
            <p>よろしいですか？</p>
          </div>
        );
      },

      updateConfirmContent: (
        <div className="tw-flex tw-flex-col tw-gap-24">
          <p>企業情報を変更します。よろしいですか？</p>
          <p>
            以下の情報を変更する場合は取引先金融機関への連絡をお願い致します。
          </p>
          <div>
            <HgbBulletItem>企業名称、企業名称カナ</HgbBulletItem>
            <HgbBulletItem>代表者、代表者カナ</HgbBulletItem>
            <HgbBulletItem>郵便番号、住所、ビル名等</HgbBulletItem>
            <HgbBulletItem>電話番号</HgbBulletItem>
          </div>
        </div>
      ),

      updateCardContent: (
        <p className="tw-text-center">
          クレジットカード情報が正常に変更されました。
        </p>
      ),

      leftGroupContent: (
        <div className="tw-flex tw-flex-col tw-gap-24 tw-p-24">
          <p>
            退会は以下のリンクから申請をお願い致します。
            事務局から連絡致します。
          </p>
          <p>
            <a
              className="!tw-text-primary-8 !tw-underline"
              href={
                'https://higobank.syd1.qualtrics.com/jfe/form/SV_2mDdjQ7G1QGyQWG'
              }
              target="_blank"
            >
              炭削くん退会
            </a>
            （別ウィンドウが開きます）
          </p>
        </div>
      ),
    },
    placeholder: {
      enterpriseCode: '',
      tradeName: '',
      representative: '',
      representativeKana: '',
      address1: '',
      address2: '',
      numberOfEmployees: '',
      specifiedEmitterCode: '',
      specifiedBusinessNumber: '',
      corporateNumber: '',
      controlNumber: '',
      startMonthOfYear: '',
      business: '',
      businessCode: '',
      businessMinister: '',
      billingDate: '',
      maximumNumberOfUsers: '',
    },
    message: {
      postalCodeFormat: '郵便番号が正しくありません。',
      deleteEnterpriseAlertSplit1: '企業「',
      deleteEnterpriseAlertSplit2: '」を削除しますか？',
      branchNumberFormat: '3桁で入力して下さい',
      corporateAccountNumberFormat: '7桁で入力して下さい',
      phoneNumberFormat: '電話番号が正しくありません。',
      billingStartDateFormat: '請求開始年月日が無料期間内です。',
    },
  },
  D02: {
    pageTitle: '拠点・組織一覧',
    modal: {
      organizationDeleteTitle: '拠点・組織情報削除のお知らせ',
      organizationUpdateTitle: '「拠点」・「組織」 編集',
      organizationAddTitle: '「拠点」・「組織」 新規登録',
      organizationViewTitle: '「拠点」・「組織」 情報',
    },
    label: {
      enterpriseId: '企業',
      classification: '区分',
      baseName: '名称',
      parentOrganizationId: '組織',
      parentBaseId: '上位拠点',
      displayOrder: '表示順',
      baseId: '拠点・組織ID',
    },
    placeholder: {
      bankId: '',
      enterpriseId: '',
      baseName: '名称',
      parentOrganizationId: '',
      parentBaseId: '',
      displayOrder: '',
    },
    message: {
      // baseName: '名称は必須項目です。',
      // classification: '区分は必須項目です。',
      // parentOrganizationId: '上位拠点は必須項目です。',
      deleteBaseAlertSplit1: '拠点・組織 : 「',
      deleteBaseAlertSplit2: '」を削除しますか？',
    },
  },
  D04: {
    pageTitle: 'ユーザー入力',
    modal: {
      userDeleteTitle: 'ユーザー削除のお知らせ',
      userUpdateTitle: 'ユーザー編集',
      userAddTitle: 'ユーザー新規登録',
      userViewTitle: 'ユーザー情報',
    },
    label: {
      bankName: '銀行名',
      companyName: '銀行名 / 企業名',
      name: '氏名',
      nameKana: '氏名カナ',
      roleName: '権限',
      email: 'メールアドレス',
      userId: 'ユーザーID',
      roleId: '権限',
      departmentId: '組織',
      partnerBank: 'パートナー銀行',
      enterprise: '企業',
      baseOrganization: '拠点・組織',
    },
    placeholder: {
      nameSearch: 'フィルタ',
      userId: '',
      name: '例：肥後',
      nameKana: '例：ヒゴ',
      email: '例：higo@higobank.co.jp',
      roleId: '',
      departmentId: '',
    },
    message: {
      email: 'メールアドレスは必須項目です。',
      name: '氏名は必須項目です。',
      nameKana: '氏名カナは必須項目です。',
      partnerBank: 'パートナー銀行は必須項目です。',
      enterprise: '企業は必須項目です。',
      baseOrganization: '拠点・組織は必須項目です。',
      deleteUserAlertSplit1: 'ユーザー情報「',
      deleteUserAlertSplit2: '」を削除しますか？',
    },
  },
  D06: {
    pageTitle: '排出量目標一覧',
    modal: {
      emissionDeleteTitle: '目標削除のお知らせ',
      emissionUpdateTitle: '排出量目標編集',
      emissionAddTitle: '排出量目標新規登録',
      emissionViewTitle: '排出量目標情報',
    },
    label: {
      enterpriseIdSearch: '企業',
      yearSearch: '事業年度',
      year: '年度',
      target: '排出量目標',
    },
    placeholder: {
      bankIdSearch: '',
      enterpriseIdSearch: '',
      yearSearch: '',
      year: '例：2022',
      target: '例：150 tCO2',
      scopeCode: '',
      categoryCode: '',
      baseId: '',
    },
    message: {
      year: '年度は必須項目です。',
      target: '排出量目標は必須項目です。',
      scopeCode: 'スコープは必須項目です。',
      categoryCode: 'カテゴリーは必須項目です。',
      baseId: '拠点は必須項目です。',
      deleteYearAlertSplit1: '目標：「',
      deleteYearAlertSplit2: '年度」を削除しますか？',
    },
  },
  G01: {
    pageTitleBankInfo: 'パートナー銀行情報',
    pageTitleBankList: 'パートナー銀行一覧',
    modal: {
      bankDeleteTitle: 'パートナー銀行情報削除のお知らせ',
      bankViewTitle: 'パートナー銀行情報',
      bankAddTitle: 'パートナー銀行新規登録',
      bankUpdateTitle: 'パートナー銀行編集',
    },
    label: {
      area: '地域区分',
      province: '都道府県',
      bankName: '銀行名称',
      loginUrl: 'ログインURL',
      personInCharge: '担当者',
      personInChargeEmail: '担当者メールアドレス',
      nameSearch: '名称',
      privacyPolicyUrl: '個人情報の取り扱いに関する規程 URL',
      signature: 'メール署名',
      numberCompany: '利用企業数',
      freePeriod: '無料期間',
      usageFee: '企業月額利用料',
      billStartMonth: '請求開始月',
      urlExpired: '本申込URLの有効期限',
      subscriptionType: '課金方式',
      complimentary: '課金なし（無償提供）',
      freeTrial: '無料期間後に課金する',
      numberOfActiveEnterprises: '利用企業数',
      enterpriseTrialStartMonths: '無料利用期間の開始月',
      enterpriseTrialMonths: '無料期間',
      enterpriseMonthlyFee: '企業月額利用料',
      enterpriseTrialMonthsG02: '無料利用期間（月指定）',
      enterpriseMonthlyFeeG02: '企業月額利用料(税込)',
      billingStartMonth: '請求開始年月',
      URLExpirationPeriodHours: '本申込URLの有効期限',
      months: 'ヶ月',
      'yen/month': '円 / 月',
      yen: '円',
      hours: '時間',
    },
    placeholder: {
      area: '九州',
      province: '熊本',
      bankCode: '例）0045',
      bankName: '例）肥後銀行',
      loginUrl: '',
      personInCharge: '例）鈴木　一郎',
      personInChargeEmail: 'info@higobank.co.jp',
      nameSearch: 'パートナー銀行名',
      privacyPolicyUrl:
        '例）https://www.higobank.co.jp/policy/privacy/declaration.html',
      signature: '',
    },
    message: {
      deleteBankAlertSplit1: 'パートナー銀行情報「',
      deleteBankAlertSplit2: '」を削除しますか？',
    },
  },
  CM01: {
    modal: {
      linkAddTitle: '外部リンク追加',
      linkUpdateTitle: '外部リンク編集',
      linkDeleteTitle: '外部リンク削除',
      linkDeleteSubTitle: '以下のリンクを削除します。よろしいですか？',
    },
    label: {
      externalLink: 'リンク',
      tutorialVideo: ' 操作動画',
      addExternalLink: '外部リンク追加',
      linkName: '外部リンク名',
      url: '外部リンク',
      userInfo: 'ユーザー情報',
      english: '英語',
      japanese: '日本語',
      higoManual: '統括銀行マニュアル',
      partnerManual: '管理者マニュアル',
      userManual: 'ユーザーマニュアル',
      supplyChainManual: 'サプライチェーン機能マニュアル',
      supplyChain: 'サプライチェーン',
      inputUpload: '入力・アップロード',
    },
    placeholder: { linkName: '外部リンク名', url: '外部リンク' },
    message: {
      externalLinksEmpty: '外部リンクがない',
    },
  },
  F01: {
    message: {
      fileNotFound: 'マニュアルが存在しません。',
    },
  },
  F03: {
    message: {
      fileNotFound: 'FAQファイルが存在しません。',
    },
  },
  C01: {
    pageTitle: 'データ一覧',
    modal: {
      supplierDeleteTitle: '一括データ削除のお知らせ',
      emissionDeleteTitle: 'データ削除のお知らせ',
      emissionDeleteAllTitle: '一括データ削除のお知らせ',
      emissionCreateConfirmTitle: '登録確認',
      emissionUpdateConfirmTitle: '更新確認',
      emissionCreateTitle: '直接入力',
      emissionUpdateTitle: '編集',
      emissionDuplicateTitle: '直接入力',
      uploadConfirmModalTitle: (
        <>
          ファイル
          <br />
          アップロード確認
        </>
      ),
      uploadConfirmModalContent:
        'ファイルのアップロードと読み込みを実行しますか？',
      uploadConfirmModalOkButton: '読込',
      csvConfirmModalTitle: '取込確認',
      csvConfirmModalContent: 'CSVファイルの取り込みを実行しますか？',
      csvConfirmModalOkButton: '取込',
      csvErrorModalTitle: '取込失敗',
      csvErrorModalContent1: 'CSVファイルの取り込みに失敗しました。',
      csvErrorModalContent2: 'エラーログを確認してください。',
      csvErrorModalCancelButton: 'エラーログダウンロード',
      numberImported: '取込対象件数：$件',
      numberFail: '取込失敗件数：$件',
      lcidbErrorModalContent: (
        <>
          ファイルの取り込みに失敗しました。
          <br />
          エラーを確認してください。
        </>
      ),
      recalculationConfirmModalTitle: '再計算確認',
      recalculationConfirmModalContent: '排出量の再計算を実行しますか？',
      recalculationConfirmModalOk: '再計算',
      supplierConfirmModalTitle: '回答削除のお知らせ',
      supplierConfirmModalSubTitle: (quantities: string) => (
        <>
          選択された{quantities}件のデータを一括削除します。
          <br />
          よろしいですか？
          <br />
          <br />
          サプライヤー回答受信から複数月に按分入力された排出量データは全て削除（入力キャンセル）されます。
          <br />
          また、サプライヤー回答受信から入力したデータは回答受信一覧画面より再入力可能です。
          <br />
        </>
      ),
    },
    label: {
      newRegistrationEmissionData: '排出量データ新規登録',
      targetYearMonth: '年月',
      enterpriseName: '企業',
      calculationTargetName: '計算対象',
      calculationMethodName: '計算方法',
      supplierCompanyName: 'サプライヤー企業名',
      emissionExplanation: '排出量説明',
      types: '種類',
      amount: '使用量',
      intensity: '排出原単位',
      createdBy: '登録者',
      createdDate: '登録日',
      createdMethod: '登録方法',
      ideaYear: '算定に使用した環境省DBの年度',
      offsetAmountWithUnit: 'オフセット量（単位付）',
      offsetAmountUnit: 'オフセット量単位',
      offsetAmount: 'オフセット量',
      offsetCertificateDate: 'オフセット証書発行日',
      offsetTarget: 'オフセット対象',
      projectName: 'プロジェクト名',
      creditType: 'クレジット種別',
      insertMethodName: '登録方法',
      fiscalYear: '事業年度',
      uploadFile: 'ファイルアップロード',
      uploadCSV: 'CSV取込',
      emissionDataSearch: '排出量データ検索',
      enterpriseId: '企業',
      baseId: '拠点・組織',
      displayRangeSpecification: '表示範囲指定',
      search: '検索',
      dataEntry: 'データ入力',
      scopeCode: 'スコープ・オフセット',
      activityName: '活動名',
      note: '備考',
      lcidb: '排出量更新',
      supplierEmissionsInput: 'サプライヤー排出量入力',
      returnToDataList: 'データ一覧画面へ戻る',
      upload: '読込',
      invoice: '請求書',
      certificate: 'カーボンクレジット',
      fileType: 'アップロード',
      csvUpload: 'CSVデータ取り込み',
      download: 'CSVフォーマットダウンロード',
      import: '取込',
      businessYear: '事業年度​',
      lcidbImport: 'LCI-DB取込',
      deleteAll: '一括削除',
      startMonth: '開始年月',
      endMonth: '終了年月',
      activityVolume: '使用量',
      greenEnergyCertificateVolume: '割り当てる使用量',
      fillingVolume: '充填量',
      emissionIntensity: '排出原単位',
      rawActivityVolume: '原材料の投入量または価格',
      rawEmissionIntensity: '排出原単位',
      rawTransportActivityVolume: '原材料の輸送量',
      rawTransportEmissionIntensity: '排出原単位',
      targetFiscalYear: '事業年度',
      capitalWasteEmissionIntensity: '排出原単位',
      transportCargoWeight: '貨物重量',
      transportMaximumCargoWeight: '最大貨物重量',
      transportDistance: '輸送距離',
      numberOfPeople: '従業員数',
      numberOfNight: '泊数',
      numberOfDay: '日数',
      expectedLifetimeUsagePer: '想定生涯使用',
      saleCount: '販売数',
      perLessonUsage: '使用1回あたりの燃料使用量',
      emissionLifetimeUse: '生涯使用の排出率（%）',
      distribution: '',
      fuelEconomy: '燃費',
      transportLoadRate: '積載率',
      businessDaysYear: '年間営業日数',
      expectedLifetimeUsage: '想定生涯使用',
      expectedLifetimeUsagePercentage: '想定生涯使用回数の割合',
      useEmissionCoefficient: '',
      unclearActivityVolume1: '各株式投資の排出量',
      unclearActivityVolume2: '株式保有割合(%)',
      unclearActivityVolume3: '各債券投資の排出量',
      unclearActivityVolume4: '投資先の総資本に対する割合(%)',
      unclearActivityVolume5: '各プロジェクトの排出量',
      unclearActivityVolume6: 'プロジェクト出資額の割合(%)',
      unclearActivityVolume8: '投資先の総資本に対する割合(%)',
      unclearActivityVolume10: '業務全体に対する顧客割合(%)',
      unclearActivityVolume11: '各その他投資分の排出量',
      unclearActivityVolume12: '投資全体に対する割合(%)',
      unclearEmissionIntensity1: '投資部門の排出原単位',
      unclearEmissionIntensity2: '投資部門の排出原単位',
      unclearEmissionIntensity3: '投資部門の排出原単位',
      unclearEmissionIntensity4: '投資部門の排出原単位',
      unclearEmissionIntensity5: '投資部門の排出原単位',
      unclearEmissionIntensity6: '投資部門の排出原単位',
      fuelUsagePerUse: '使用1回あたりの燃料使用量',
      unit: '使用量の単位量',
      capitalWasteActivityVolume: ' 資本財に関連した廃棄物の重量',
    },
    placeholder: {
      bankId: '',
      enterpriseId: '',
      baseId: '拠点・組織',
      targetYear: '',
      categoryCode: '',
      offsetAmountUnit: '',
      offsetAmount: '',
      offsetTarget: '',
      offsetCertificateDate: '',
      projectName: '',
      creditType: '',
      fiscalYear: '',
      targetYearMonth: '',
      scopeCode: '',
      activityName: '',
    },
    message: {
      startMonthendMonth: '表示開始年月と表示終了年月の値が正しくありません。',
      deleteEmissionConfirm: 'データを削除しますか？',
      deleteAllEmissionConfirm: (s: string) => (
        <>
          選択された{s}件のデータを一括削除します。
          <br />
          よろしいですか？
        </>
      ),
      emissionCreateConfirm: 'データ登録を実行しますか？',
      emissionUpdateConfirm: 'データ更新を実行しますか？',
      dataEntryScopeNotice:
        '※排出原単位、排出量は自動計算のため入力の必要はありません。（ただし、他の項目の入力内容により、ユーザで入力する必要があります）',
      dataEntryScopeNoticeReset:
        '※複製元と複製先の会計年度が異なり、排出量算出ロジックが異なるため複製はできません。最初から入力してください。',
      dataEntryOffsetNotice:
        '※オフセット対象・プロジェクト名・クレジット種別は任意入力です。',
      recoveryVolume: '回収・適正処理量は必須項目です。',
      recoveryVolumeFormat: '回収・適正処理量は必須項目です。',
      emissionVolumeNegativeCreate:
        '排出データにマイナスの値が存在するので、登録に失敗した。',
      emissionVolumeNegativeUpdate:
        '排出データにマイナスの値が存在するので、更新に失敗した。',
      capitalWasteActivityVolumeFormat:
        '資本財に関連した廃棄物の重量は必須項目です。',
      distributionFormat: '',
      useEmissionCoefficientFormat: '',
      unclearActivityVolume7:
        '各収益の使途が明らかでない債権投資の排出量は必須項目です。',
      unclearActivityVolume7Format:
        '各収益の使途が明らかでない債権投資の排出量は必須項目です。',
      unclearActivityVolume9:
        '各管理型投資及び顧客業務の排出量は必須項目です。',
      unclearActivityVolume9Format:
        '各管理型投資及び顧客業務の排出量は必須項目です。',
      unit: '選択済みの単位は適切ではありません。ご再選択ください。',
      manualIdeaInput:
        '選択済みのIDEA値は適切ではありません。ご再選択ください。',
      c02NoticeScope:
        '※排出原単位、排出量は自動計算のため入力の必要はありません。（ただし、他の項目の入力内容により、ユーザで入力する必要があります）',
      c02NoticeOffset:
        '※オフセット対象・プロジェクト名・クレジット種別は任意入力です。',
      categoryNotSupport:
        '選択されたカテゴリーは企業設定により使用不可に指定されています。',
      fileFormatCsv: 'CSVファイルフォーマット',
    },
  },
  C07: {
    title: 'グリーンエネルギー証書一覧',
    titleDetail: 'グリーンエネルギー証書利用詳細',
    titleConfirmRegister: '登録確認',
    detailConfirmRegister: 'データ登録を実行しますか？',
    titleConfirmEdit: '更新確認',
    detailConfirmEdit: 'データ更新を実行しますか？',
    certificateType: '証書の種類',
    certificateNumber: '証書番号',
    certificateNo: '証書番号',
    serialNo: 'シリアルNo',
    deductionTarget: '控除対象',
    generatedElectricityAmount: '発電電力量',
    generatedPowerAmount: '発電電力量',
    generatedHeatAmount: '生成熱量',
    issueDate: '証書発行日',
    startDate: '開始年月日',
    endDate: '終了年月日',
    certificateValidityPeriod: '証書の有効期限',
    certificateName: '証書名称',
    buttonBack: '証書一覧に戻る',

    column: {
      creditID: '証書ID',
      generatedPowerAmount: (
        <>
          発電電力量
          <br />
          生成熱量
        </>
      ),
      deductedAmount: '割当済み量',
      remainingAmount: '残量',
      certificateIssueDate: '証書発行日',
      action: '操作',
      operation: '操作',
      scope: 'スコープ種類',
      yearMonth: '年月',
      baseOrganization: '拠点・組織',
      calculationTarget: '計算対象',
      type: '種類',
      activityVolume: '使用量',
    },

    modal: {
      titleModalConfirm: (
        <>
          グリーンエネルギー証書
          <br />
          削除確認
        </>
      ),
      titleModalRegister: (
        <>
          グリーンエネルギー証書
          <br />
          新規登録
        </>
      ),
      titleModalUpdate: (
        <>
          グリーンエネルギー証書
          <br />
          編集
        </>
      ),
      attentionMessage: (
        <>
          以下の証書データを削除します。
          <br />
          よろしいですか？
        </>
      ),
      generatedPowerAmount: '発電電力量',
      certificateIssueDate: '証書発行日',
    },
  },

  B01: {
    pageTitle: '',
    label: {
      generalAnchor: '概要',
      monthlyEmissionAnchor: '月別排出量',
      breakdownEmissionAnchor: '排出量内訳',
      predictEmissionAnchor: '排出量予測',
      thisYearsSimulationAnchor: '今年度シミュレーション',
      scope1Last: 'スコープ1(前年)',
      scope2Last: 'スコープ2(前年)',
      scope3Last: 'スコープ3(前年)',
      scope1Current: 'スコープ1(当年)',
      scope2Current: 'スコープ2(当年)',
      scope3Current: 'スコープ3(当年)',
      general: '概要',
      cumulativeCurrentYear: '当年度累計値',
      targetCurrentYear: '当年度目標値',
      compareEmission: '前年度総排出量比',
      monthlyEmission: '月別排出量',
      breakdownEmission: '排出量内訳',
      thisYearsSimulation: 'シミュレーション',
      predictEmission: '予測シミュレーション',
      higoBank: '肥後銀行',
      predict: '予測',
      detail: '詳細',
      scope1Desc: '自社による燃料の使用（ガソリン、ガス等）',
      scope2Desc: '他社から供給された電気、熱等の使用',
      scope3Desc: '商品・サービスの購入やサプライチェーン全体の排出',
      other: 'その他',
      total3Scopes1: '当年度累計値',
      total3Scopes2: '(Scope 1, 2, 3)',
      total1: '当年度総排出量',
      total2: '(オフセット後)',
      compareToLastYear1: '現在の排出状況',
      compareToLastYear2: '（対目標値）',
      target1: '当年度の目標値',
      target2: '（企業全体）',
      scope2MethodSelected: 'Scope2算定基準',
    },
    scope3Descs: {
      '01': '購入した製品・サービス',
      '02': '資本財',
      '03': 'スコープ１、２に含まれない燃料及びエネルギー活動',
      '04': '輸送、配送(上流)',
      '05': '事業から出る廃棄物',
      '06': '出張',
      '07': '雇用者の通勤',
      '08': 'リース資産(上流)',
      '09': '輸送、配送(下流)',
      '10': '販売した製品の加工',
      '11': '販売した製品の使用',
      '12': '販売した製品の廃棄',
      '13': 'リース資産(下流)',
      '14': 'フランチャイズ',
      '15': '投資',
    },
    placeholder: {
      enterpriseId: '企業',
      targetYear: '事業年度',
      baseId: '拠点・組織',
      supplyChain: 'サプライチェーン企業',
    },
  },
  E01: {
    pageTitle: 'レポート出力',
    label: {
      fiscalYear: '事業年度',
      LCIDBYear: '会計年度',
      excel: 'EXCEL',
      csv: 'CSV',
      enterpriseId: '企業',
      baseId: '拠点・組織',
      outputType: '出力形式',
      outputDisplayRange: '出力表示範囲',
    },
    placeholder: {
      bankId: '',
      enterpriseId: '',
      fiscalYear: '',
      targetYearMonthStart: '',
      targetYearMonthEnd: '',
    },
    message: {
      startMonth: '開始年月は必須項目です。',
      endMonth: '終了年月は必須項目です。',
      startMonthendMonth: '出力開始年月と出力終了年月の値が正しくありません。',
      timeBetweenExceedOneYear:
        '出力開始年月と出力終了年月が1年を超えることはできません。',
    },
    modal: {
      exportConfirmation: '出力確認',
      exportConfirmationQuestion: 'レポート出力を実行しますか？',
      exportConfirmationWarning:
        '出力条件によっては時間がかかる場合があります。',
    },
  },
  E02: {
    pageTitle: '企業情報一覧表出力指示',
    label: {
      format: '出力形式',
      excel: 'EXCEL',
      csv: 'CSV',
      status: 'ステータス',
      selectAll: '全てオン',
      deselectAll: '全てオフ',
      confirmContent: (
        <>
          <p>企業情報一覧表出力を実行しますか？</p>
          <p>出力条件によっては時間がかかる場合があります。</p>
        </>
      ),
    },
    modal: {
      confirmTitle: '出力確認',
    },
  },
  D13: {
    pageTitle: 'メッセージ配信一覧',
    label: {
      id: 'ID',
      title: 'タイトル',
      type: 'メッセージタイプ',
      delivery: 'メッセージ配信範囲',
      status: 'ステータス',
      displayStartDate: '配信開始日時',
      displayEndDate: '配信終了日時',
      lastModified: '最終更新日時',
      description: 'メッセージ本文',
    },
    placeholder: {
      message: 'フィルタ',
    },
    message: {
      startDateTimeEarlier:
        '配信開始日時には配信終了日時より以前の値を設定してください。',
      endDateTimeEarlier: '配信終了日時が未来の日時であること',
      edit: `
          ユーザーへ配信済みのメッセージは編集できません。
          修正が必要な場合は訂正用のメッセージを新規作成して配信し、その後に誤ったメッセージを削除してください。
          （送信済みメールは削除できません）
      `,
      delete: (
        <>
          ユーザーへ配信済みのメッセージを削除しようとしています。 <br />
          削除してよろしいですか？
          <br />
          (送信済みメールは削除できません)
        </>
      ),
    },
    modal: {
      addMessage: 'メッセージ配信新規登録',
      displayStartDate: 'メッセージ配信開始日時',
      displayEndDate: 'メッセージ配信終了日時',
      messageType: 'メッセージタイプ',
      messageRange: 'メッセージ配信範囲',
      messageTitle: 'メッセージタイトル（50文字以内）',
      messageBody: 'メッセージ本文（2000文字以内）',

      updateMessage: 'メッセージ配信編集',

      confirmMessage: 'メッセージ配信確認',
      confirmTitle: 'メッセージタイトル',
      confirmType: 'メッセージタイプ',
      confirmRange: 'メッセージ範囲',
      confirmStartDate: '配信開始時間',
      confirmEndDate: '配信終了時間',
      confirmWarning: (
        <>
          以上の内容でメッセージを配信します。
          <br />
          処理時刻が配信開始時間を過ぎている場合は利用中のユーザーへ即時メール配信されます。
          <br />
          よろしいですか？
        </>
      ),

      discardMessage: 'キャンセル確認',
      discardContent: (
        <>
          データは変更されています。
          <br />
          保存せずに閉じてよろしいですか？ <br />
          閉じるボタンを押下すると、変更したデータを破棄してメッセージ編集画面を閉じます。
        </>
      ),

      deleteMessage: 'メッセージ配信削除のお知らせ',
      deleteContent: (s: string) => `メッセージ配信「${s}」を削除しますか？`,
    },
  },
  D22: {
    pageTitle: '紹介会社一覧',
    label: {
      supportCode: '紹介コード',
      supportCodeDescription: '登録後に発行されます',
      introducerName: '紹介会社名称',
      numberOfApplications: '申込承認数',
      lastApplicationDate: '最終申込承認日',
      introducerNameKana: '紹介会社名称カナ',
      buildingName: 'ビル名等',
      phoneNumber: '電話番号 (ハイフンなし）',
      PICEmail: '担当者メールアドレス',
      PICName: '担当者名',
      PICNameKana: '担当者名カナ',
      referralCode: '紹介コード',
      addIntroducer: '紹介会社新規登録',
      viewIntroducer: '紹介会社情報',
    },
    placeholder: {
      introducerName: '紹介会社名称',
    },
    message: {},
    modal: {},
  },
  D15: {
    title: 'サプライチェーングループ主催一覧​',
    columns: {
      inviteCode: '招待コード',
      numPracticipants: '参加企業数',
      createDate: '作成日',
      note: '備考',
    },
    label: {
      supplyChainGroupName: 'サプライチェーングループ名称',
      inviteCode: '招待コード',
    },
    titleAddModal: 'サプライチェーングループ主催新規作成',
    titleViewModal: 'サプライチェーングループ主催情報',
    noteInvite: '登録後に発行されます',
  },
  D17: {
    title: 'サプライチェーングループ参加一覧​',
    columns: {
      groupOwner: 'グループ主催',
      joinDate: '参加日',
      numberOfResSent: '回答送信数',
      resList: '回答一覧',
      createRes: '回答作成',
    },
    label: {
      supplyChainGroupBuyer: 'サプライチェーングループ主催',
    },
    titleAddModal: 'サプライチェーングループ新規参加',
    titleViewModal: 'サプライチェーングループ参加情報',
  },
  D19: {
    title: 'サプライヤー製品一覧​',
    titleModal: '製品停止確認',
    serialNumber: '製造コード',
    productName: '製品名',
    gtinCode: 'GTINコード',
    upcCode: 'UPCコード',
    emissionsPerUnit: '1単位あたり排出量',
    columns: {
      dateOfRegistration: '登録日',
      updatedOn: '更新日',
      numberOfUses: '利用数',
      status: 'ステータス',
      active: '利用中',
      inactive: '利用停止',
    },
    modal: {
      supplierProductRegistration: 'サプライヤー製品新規登録',
      supplierProductsEdit: 'サプライヤー製品編集',
      supplierProductsInformation: 'サプライヤー製品情報',
      unit: '単位',
      attentionMessage: '以下の製品を停止します。よろしいですか？',
      serialNumber: '製造コード',
      productName: '製品名',
    },
  },
  F04: {
    label: {
      higoBank: '肥後銀行',
    },
    title: 'お問い合わせ',
    description:
      '炭削くんに関するお問い合わせは炭削くんお問い合わせフォームよりお願い致します。',
    linkContent: '炭削くんお問合せフォーム',
    linkDescription: '（別ウィンドウが開きます）',
  },

  H01: {
    title: '回答受信一覧',
    columns: {
      supplierName: '企業名称',
      answerType: '回答種別',
      responsePeriod: '回答対象期間',
      responseReceivedDateAndTime: '回答受信日時',
      answerReceivedStatus: '回答受信ステータス',
      pending: '登録待ち',
      register: '登録済',
      return: '差戻',
    },
    modal: {
      remandConfirmTitle: '回答差戻確認',
      remainConfirmSubtitle: '以下の回答を差戻します。よろしいですか？',
      enterpriseName: '企業名称',
    },
  },

  H02: {
    title: '回答送信一覧​',
    responsePeriod: '回答対象期間',
    notReceived: '未受付',
    received: '受付済',
    returned: '差戻し',
    fiscalYear: '年度',
    productUnit: '製品単位',
    organizationalUnit: '組織単位',
    columns: {
      sentDateTime: '送信日時',
      status: '回答ステータス',
      responseType: '回答種別',
      detail: '回答詳細',
      emissionVolume: '排出量',
    },
    modal: {
      title: '回答削除確認',
      announcementMessage: '以下の排出量データを削除します。よろしいですか？',
      GroupBuyer: 'グループ主催',
      groupName: 'グループ名称',
    },
    modalAction: {
      content: (
        orgName: string,
        yearMonthStart: string,
        yearMonthEnd: string,
      ) => {
        return (
          <>
            <div className="tw-flex tw-items-start tw-justify-start">
              <p className="tw-text-h3">拠点 / 組織&nbsp;:</p>
              <p className="tw-break-all tw-text-h3">&nbsp;{orgName}</p>
            </div>
            <p className="tw-text-h3">
              {yearMonthStart} ~ {yearMonthEnd}
              の回答排出量が企業のScope1、2の排出量が変更されています。
            </p>
            <p className="tw-text-h3">続けて実行してもよろしいでしょうか。</p>
          </>
        );
      },
    },
  },

  H03: {
    titleAdd: 'サプライヤー回答新規入力',
    titleEdit: 'サプライヤー回答編集',
    groupBuyer: 'サプライチェーングループ主催',
    responseType: '回答種別',
    responseTooltip: (
      <>
        2種類があります <br />
        ・製品単位：納入した製品の、単位（1個）当たりの排出量
        <br />
        ・組織単位：1年間に回答受信側企業に納入した製品全てに関わる排出量
      </>
    ),
    startDate: '開始年月',
    startDateTooltip:
      '終了年月と同年度、かつ、開始年月が終了年月より前である月を選択して下さい。',
    endDate: '終了年月',
    endDateTooltip:
      '開始年月と同年度、かつ、開始年月が終了年月より前である月を選択して下さい。',
    manufacturingCodeProductName: '製造コード / 製品名',
    manufacturingCodeProductNameTootip: 'サプライヤー製品画面で定義すること',
    quantity: '数量',
    correspondingUnit: 'tCO2/該当な単位',
    quantityUnit: '単位',
    scopeHalfEmissions: 'Scope1・2の排出量',
    allocation: 'アロケーション',
    allocationTooltip:
      '指定した年月間と拠点・組織に対して入力した（サプライヤーの）Scope1, 2 排出量に対してアロケーション(%)を乗じた排出量を回答すること',
    responseSummary: '回答の概要',
    modalCreate: {
      title: '回答確認',
      subTitle: '回答排出量を送信しますか？',
    },
    modalEdit: {
      title: '回答更新確認',
      subTitle: '回答排出量を更新しますか？',
    },
    modalSubmit: {
      content: (
        orgName: string,
        emission: number,
        yearMonthStart: string,
        yearMonthEnd: string,
      ) => {
        return (
          <>
            <div className="tw-flex tw-items-start tw-justify-start">
              <p className="tw-text-h3">拠点 / 組織&nbsp;:</p>
              <p className="tw-break-all tw-text-h3">&nbsp;{orgName}</p>
            </div>
            <div className="tw-flex tw-items-start tw-justify-start">
              <p className="tw-text-h3">回答済み&nbsp;:</p>
              <p className="tw-break-all tw-text-h3">&nbsp;{emission} tC02</p>
            </div>
            <p className="tw-text-h3">
              {yearMonthStart} ~ {yearMonthEnd}
              の回答排出量が企業のScope1、2の合計を超えています。
            </p>
            <p className="tw-text-h3">続けて実行してもよろしいでしょうか。</p>
          </>
        );
      },
    },
  },

  H04: {
    title: 'サプライヤー排出量入力',
    baseOrganization: '拠点・組織',
    calculationTarget: '計算対象',
    total: '合計',
    startMonth: '開始年月',
    endMonth: '終了年月',
    month: '年月',
    supplierProduct: 'サプライヤー製品',
    serialNumber: '製造コード',
    supplierName: 'サプライヤー企業',
    unit: '単位',
    supplierProductBase: 'サプライヤー製品ベース',
    directManualInput: '直接手入力',
    supplierResponseBase: 'サプライヤー回答ベース',
    fiscalYear: '会計年度',
    startDateTooltip:
      '終了年月と同年度、かつ、開始年月が終了年月より前である月を選択して下さい。',
    endDateTooltip:
      '開始年月と同年度、かつ、開始年月が終了年月より前である月を選択して下さい。',
    placeholder: {
      category1: 'カテゴリー1：購入した製品・サービス',
    },
    modalRegister: {
      title: '排出量入力確認',
      subTitle: 'サプライヤー排出量を登録しますか？',
    },
    modalEdit: {
      title: '排出内容更新確認',
      subTitle: '排出内容を更新しますか？',
    },
  },

  E0000: (field: string) => createMessage('{0}は必須項目です。', field),
  E0001: '',
  E0002: (field: ReactNode) =>
    createNode('{0}が正常にコピーされました。', field),
  E0003: '銀行コード、ログインIDまたはパスワードが正しくありません。',
  E0004: `ユーザーが既に存在しました。`,
  E0005: `ユーザーIDが存在しています。他のユーザーIDを入力してください。`,
  E0006: `ユーザーが存在していません。`,
  E0007: `５回連続で認証に失敗したため一時的にアカウントロック中です。管理者に連絡してください。`,
  E0008: `メール形式が無効です`,
  E0009: `現在のパスワードが間違っています。もう一度お試しください。`,
  E0010: `入力された新パスワードと新パスワード(確認)が一致しません。もう一度お試しください。`,
  E0011: `このパスワードは最近使用されています。別のパスワードを指定してください。`,
  E0012: `現在のパスワードと新しいパスワードが同じです。 もう一度お試しください。`,
  E0013: (field: string) =>
    createMessage(`{0}は半角英数字と半角記号で入力してください。`, field),
  E0014: (field: string) => createMessage(`{0}が登録されていません。`, field),
  E0015: `アカウントがロックされています。管理者へ問い合わせてください。`,
  E0016: `アカウントが削除されています。`,
  E0017: (field: string) => createMessage(`{0}を入力してください。`, field),
  E0018: `結果がありません`,
  E0019: (field: string) => createMessage(`{0}に変換しました。`, field),
  E0020: (
    <>
      年度データが存在しました。
      <br />
      登録できません
    </>
  ),
  E0021: `レポートの容量が大きすぎて、エクスポートができません。エクスポート条件をもう一度選択してください。`,
  E0022: (
    <>
      [拠点​・組織]が存在しました。
      <br />
      登録できません。
    </>
  ),
  E0023: `ファイルの形式が適切ではありません。`,
  E0024: `ファイルが選択されていません。`,
  E0025: (field: string) =>
    createMessage(`ファイルのサイズが{0}MB以内をアップしてください。`, field),
  E0026: `入力ファイルが選択したOCRテンプレートと一致しません。`,
  E0027: `年月の形式が適切ではありません。'YYYY-MM'のように入力してください。`,
  E0028: `エラーが発生した。データが見つかりません。`,
  E0029: `入力が無効です。数字を入力してください。`,
  E0030: `すべての必須項目に入力してください。`,
  E0031: (
    <>
      データベースに接続できません。
      <br />
      登録できません。
    </>
  ),
  E0032: (
    <>
      データベースに接続できません。
      <br />
      登録できません。
    </>
  ),
  E0033: `メールアドレスが存在しています。他のメールアドレスを入力してください。`,
  E0034: `メールアドレスが間違っているか、登録されていません。`,
  E0035: (field: string) => createMessage(`有効期限切れまで残り{0}日`, field),
  E0036: `新しいパスワードは半角英数字・半角記号で入力してください。`,
  E0037: `新しいパスワードは１２桁以上１６桁未満で入力してください。`,
  E0038: `新しいパスワードと新しいパスワード（確認用）が同じでないため変更できません。`,
  E0039: `郵便番号が正しくありません。`,
  E0040: `出力開始年月と出力終了年月の値が正しくありません。`,
  E0041: `データが存在しません。`,
  E0042: `削除対象のデータが存在しません。`,
  E0043: `登録できるリンクの最大数を超えました。（リンクの最大数：１０）`,
  E0044: `パートナー銀行を選択してください。`,
  E0045: `企業を選択してください。`,
  E0046: `このパートナー銀行は企業を持っていません。`,
  E0047: `開始年月を選択してください。`,
  E0048: `終了年月を選択してください。`,
  E0049: `終了年月は開始年月より大きくなければなりません。`,
  E0050: (field1: string, field2: string) =>
    createMessage(`{0}行目:{1}は必須項目です。`, field1, field2),
  E0051: (field: string) =>
    createMessage(`{0}行目:使用量は0以上で入力してください。`, field),
  E0052: (field: string) =>
    createMessage(`{0}行目:使用量は小数点第3位まで入力可能です。`, field),
  E0053: `排出データにマイナスの値が存在するので、登録に失敗した。`,
  E0054: `マニュアルが存在しません。`,
  E0055: `FAQファイルが存在しません。`,
  E0056: `排出データにマイナスの値が存在するので、更新に失敗した。`,
  E0057: `パスワードは大文字、小文字、数字、記号を含んでご設定ください。`,
  E0058: (field: string) =>
    createMessage(`ファイルのサイズが{0}MBを超えています。`, field),
  E0059: `文字コードがUTF-8ではありません。`,
  E0060: (field1: string, field2: string) =>
    createMessage(
      `このレコードのデータは{0}と{1}の何にもパターンに一致 しない。`,
      field1,
      field2,
    ),
  E0061: `カラム数は CSVフャイルに正しくない。`,
  E0062: (field: string) => createMessage(`{0} ：無効`, field),
  E0063: `企業コードに対して拠点コードのコード値が正しくありません`,
  E0064: `スコープのコードはカテゴリのコードと一致しない。`,
  E0065: `ファイルアップロードから使用量の値が読み込めない為、他の写真をご使用ください。`,
  E0066: `入力ファイルがフォルダに格納されていません。`,
  E0067: `csvファイル名はデータベースのマスターテーブルと正しくないである。`,
  E0068: `出力開始年月と出力終了年月が1年を超えることはできません。`,
  E0069: `そのフャイルが存在しているのでご再確認ください`,
  E0070: (field: string) =>
    createMessage(`{0}のデータ型が間違っている或いは、null である`, field),
  E0071: (field: string) => createMessage(`{0}の文字数が最大長を超える`, field),
  E0072: (field: string) =>
    createMessage(`{0}の値が数字 形式に正しくない`, field),
  E0073: `許可されるファイル数を超えています`,
  E0074: `排出データにマイナスの値が存在する`,
  E0075: `データベースに対象年のLCI DBデータが既に存在するため登録できません。`,
  E0076: `アップロードするCSVファイルの数は22ファイルである必要があります。`,
  E0077: `CSVファイルフォーマットが存在しません`,
  E0078: `CO2 データを登録する必要がある年に適用できる LCI-DB データがないため、登録プロセスが続けません。`,
  E0079: `選択されたカテゴリーは企業設定により使用不可に指定されています。`,
  E0080: (field: string) =>
    createMessage(`{0}に機種依存文字が含まれています。`, field),
  E0081: `表示開始日時には表示終了日時より以前の値を設定してください。`,
  E0082: `表示終了日時には未来の日時を設定してください。`,
  E0083: (field1: string, field2: string) =>
    createMessage(`{0}は{1}文字以下で指定してください。`, field1, field2),
  E0084: (
    <>
      保存先のメッセージIDが見つかりません。他のユーザにより削除された可能性があります。
      <br />
      新規メッセージとして登録しますか？
    </>
  ),
  E0085: `入力されたメールアドレスは使用できません。`,
  E0086: (field1: string, field2: string, field3: string) =>
    createMessage(
      '{0}は {1}～{2} の範囲で設定できます。',
      field1,
      field2,
      field3,
    ),
  E0087: (field1: string, field2: string) =>
    createMessage('{0}選択時は{1}を指定する必要があります。', field1, field2),

  E0088: (field: string) => createMessage(`{0}桁で入力して下さい`, field),
  E0089: `合計が100%となるように入力してください。`,
  E0090: `このデータは他のユーザーにより変更されました。`,
  E0091: `このデータは回答受信側により変更されたため、回答送信側では変更できません。`,
  E0092: `グループが存在しません。再度に選択ください。`,
  E0093: `製品が存在しません。再度に選択ください。`,
  E0094: `製品情報が変更されました。`,
  E0095: `拠点・組織が存在しません。再度に選択ください。`,
  E0096: `拠点・組織情報が変更されました。`,
  E0097: `排出量が変更されています。`,
  E0098: `20XX年度 XXX に対する回答アロケーションの合計が100%を超えます。回答済みのアロケーション合計はXX%です。`,
  E0099: `サプライヤーが存在しません。再度に選択ください。`,
  E0100: `同じ会計年度の開始月と終了月を選択してください。`,
  E0101: `数値は100%以下で入力してください。`,
  E0102: `TSCで始まる10文字のアルファベットを入力してください。`,
  E0103: `入力した招待コードは既に利用されています、再度ご確認ください。`,
  E0104: (...params: number[]) => {
    if (params.length === 1) {
      return `${params[0]}桁で入力して下さい。`;
    }
    return `${params.join('桁/') + '桁で入力して下さい。'}`;
  },
  E0106: '14桁数を超える排出量ですので、数量を編集してください。',
  E0117: '利用開始日時には利用終了日時より以前の値を設定してください。',
  E0119: '排出量が15桁数を超えますので、修正してください。',
  E0113: '証書の使用量は使用量以下で入力してください。',
  E0116: '証書の使用量が利用可能な残量を超えているため、再入力してください。',
  I0001: '登録処理が正常に終了しました。',
  I0002: '削除処理が正常に終了しました。',
  I0003: '更新処理が正常に終了しました。',
  I0004: 'パスワードが変更されました。',
  I0005: '結果がありません',
  I0006: (userID: string, userName: string) =>
    createMessage('ユーザー情報{0}_{1}を削除しますか？', userID, userName),
  I0007: 'データがありません。',
  I0008: 'パートナー銀行を選択してください。',
  I0009: 'パートナー銀行と企業を選択してください。',
  I0010: '企業を選択してください。',
  I0011: 'パートナー銀行は存在しません。',
  I0012: '前月・当月のデータが存在しません。',
  I0013: 'データ登録を実行しますか？',
  I0014: 'データ更新を実行しますか？',
  I0015: 'ファイルが存在しないのでデータを登録する必要があります',
  I0016: 'CSVファイルの取り込みに成功しました。',
  I0017: 'LCI-DBのデータマスタに従って更新するデータが存在しない',
  I0018: '今年度のデータ マスター LCI-DB が更新されていません',
  I0019:
    '新しい LCI-DB データ マスターに基づいてデータを更新しています。お待ちください。',
  I0020: (field: string) => createMessage('{0} 削除のお知らせ', field),
  I0021: (
    <>
      この銀行パートナーに企業またはユーザーがあるため。
      <br /> 削除できません。
    </>
  ),

  I0022: (
    <>
      この企業に拠点・組織またはユーザーがあるため。
      <br />
      削除できません。
    </>
  ),
  I0023: (
    <>
      この拠点・組織に組織またはユーザーがあるため。
      <br />
      削除できません。
    </>
  ),
  I0024:
    'この機能はモバイルにサポートしないのでパソコン で実施をお願いします。',
  I0025: (
    <>
      ユーザーへ配信済みのメッセージは編集できません。
      <br />
      修正が必要な場合は訂正用のメッセージを新規作成して配信し、その後に誤ったメッセージを削除してください。
      <br />
      （送信済みメールは削除できません）
    </>
  ),
  I0026: (
    <>
      ユーザーへ配信済みのメッセージを削除しようとしています。
      <br />
      削除してよろしいですか？ <br />
      （送信済みメールは削除できません）
    </>
  ),
  I0027: '本申込URL有効期限切れ',
};
