import { HgbAntdButton } from '@common/antd/HgbAntdButton';
import { HgbAntdRadioGroup } from '@common/antd/HgbAntdRadioGroup';
import { HgbResponsive } from '@common/components';
import {
  HgbAntdInput,
  HgbSelect,
  parseToHgbSelectOptions,
} from '@common/forms';
import { API_URLS } from '@constants/API_URLS';
import { routs_en } from '@constants/ROUT';
import {
  PaymentErrorType,
  REACT_APP_E_SCOTT_URL,
  STATUS_CODE,
  getEscottToken,
  getMerchantId,
  getMerchantPass,
  paymentError,
} from '@constants/consts';
import { MONTH_OF_YEAR_OPTIONS } from '@constants/options';
import { LanguageContext } from '@contexts/LanguageContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAddress } from '@hooks/useAddress';
import { TemporaryTemplate } from '@layouts/templates/TemporaryTemplate';
import { codeMajors, codeMediums } from '@pages/Management/Setting/D08/data';
import {
  createMajorOptions,
  createMediumOptions,
} from '@utils/mapFixedDataD01';
import { toJPFormat } from '@utils/number';
import { Checkbox } from 'antd';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useHgbMutationPost,
  useHgbMutationPut,
} from 'services/common/mutation';
import { useHgbQuery } from 'services/common/query';
import {
  A07CheckValidURLResponse,
  A07RequestType,
  A07_DEFAULT_VALUE,
  CheckDurationRequestType,
  CheckValidCardRequestType,
  InsertCardRequest,
  PaymentTypeRequest,
  PaymentTypeResponse,
  PaymentTypeResponseA07,
  SendEmailBankComplimentaryRequest,
} from 'services/types/A07';
import * as yup from 'yup';
import './A07.scss';

declare var SpsvApi: any;

type CardType = {
  card?: string;
  token?: string;
};

const A07: FC = () => {
  const {
    text: { A07, A08, common, E0000 },
  } = useContext(LanguageContext)!;

  const [isLoading, setIsLoading] = useState(false);
  const [isCallPayment, setIsCallPayment] = useState(false);

  const [card, setCard] = useState<CardType>({
    card: '',
    token: '',
  });

  const { mutate: insertCardMutate } = useHgbMutationPut<
    undefined,
    InsertCardRequest
  >(API_URLS.INSERT_CARD_API_URL, {
    isAlert: false,
    onSuccess: () => {
      return navigate(routs_en['/temporarily-register/success'].link, {
        state: 'success',
      });
    },
  });

  const { mutate: sendEmailBankComplimentaryMutate } = useHgbMutationPut<
    undefined,
    SendEmailBankComplimentaryRequest
  >(API_URLS.SEND_EMAIL_BANK_COMPLIMENTARY, {
    isAlert: false,
    onSuccess: () => {
      return navigate(routs_en['/temporarily-register/success'].link, {
        state: 'success',
      });
    },
  });

  const { mutate: mutateCheckValidCard } = useHgbMutationPost<
    string,
    CheckValidCardRequestType
  >(API_URLS.FOUR_MEM_ADD_API_URL, {
    isAlert: false,
    onSuccess(data) {
      const responseParams = new URLSearchParams(data?.result);
      const status = responseParams.get(
        'ResponseCd',
      ) as PaymentErrorType | null;
      const statusMsg = status ? paymentError[status] : '';
      setIsLoading(false);
      if (status === 'OK') {
        setIsCallPayment(true);
        insertCardMutate({
          id: registerWatch.id,
          maskedCardNumber: card.card ?? '',
          email: registerWatch.picEmail,
          kaninId: KaiinId,
          kaninPass: KaiinPass,
        });
      } else {
        return navigate(routs_en['/temporarily-register/fail'].link, {
          state: statusMsg + ';/temporarily-register/' + registerId,
        });
      }
    },
  });

  const [closeCard, setCloseCard] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      const script: HTMLScriptElement = document.createElement('script');
      const src = new URL(REACT_APP_E_SCOTT_URL);
      const token = await getEscottToken();
      src.searchParams.set('k_TokenNinsyoCode', token);
      script.src = src.href;
      script.className = 'spsvToken';
      script.type = 'text/javascript';
      script.setAttribute('callBackFunc', 'setToken');
      document.body.appendChild(script);
      setCloseCard(false);
      return () => {
        document.body.removeChild(script);
      };
    })();
  }, [closeCard]);

  useEffect(() => {
    const setToken = (e: MessageEvent) => {
      if (typeof e.data === 'string' && !isEmpty(e.data)) {
        const dataJson = JSON.parse(e.data);
        if (
          dataJson.spsvOrigin.includes('e-scott.jp') &&
          !isEmpty(dataJson.cardNo)
        ) {
          setCard({ card: dataJson.cardNo, token: dataJson.token });
        }
        if (dataJson.token.includes('close')) {
          setCloseCard(true);
          const sonyFrame = document.getElementById('SPSVviewIframe');
          const htmlEl = document.querySelector('html');
          if (htmlEl?.style) {
            htmlEl.style.overflow = 'unset';
          }
          sonyFrame?.remove();
          setIsLoading(false);
          setStep(1);
        }
      }
    };
    window.addEventListener('message', setToken);

    return () => {
      window.removeEventListener('message', setToken);
    };
  }, []);

  const [step, setStep] = useState<number>(1);

  const schema = yup.object({
    enterpriseName: yup.string().required(E0000(common.label.enterpriseName)),
    enterpriseNameKana: yup
      .string()
      .required(E0000(common.label.enterpriseNameKana)),
    //tradeName: yup.string().required(A07.message.tradeName),
    representative: yup.string().required(E0000(common.label.representative)),
    representativeKana: yup
      .string()
      .required(E0000(common.label.representativeKana)),
    postalCode: yup
      .string()
      .required(E0000(common.label.postalCode))
      .length(7, A07.message.postalCodeFormat),
    // .notOneOf(['0000000'], A07.message.postalCodeFormat),
    phoneNumber: yup.string().required(E0000(common.label.phoneNumber)),
    // .length(11, A07.message.phoneNumberFormat),
    address1: yup.string().required(E0000(common.label.fullAddress)),
    // address2: yup.string().required(A07.message.address2),
    startMonthOfYear: yup
      .string()
      .required(E0000(common.label.startMonthOfYear)),
    paymentType: yup.string().when({
      is: () => registerWatch.subscriptionType !== 'COMPLIMENTARY',
      then: (schema) => schema.required(E0000(common.label.paymentMethod)),
      otherwise: (schema) => {
        return schema.notRequired();
      },
    }),
    hasAccount: yup.string().required(E0000(common.label.isCorporateAccount)),
    branchNumber: yup.string().when({
      is: () => registerWatch.hasAccount === '1',
      then: (schema) => {
        return schema
          .required(E0000(common.label.branchNumber))
          .length(3, A07.message.branchNumberFormat);
      },
    }),
    corporateAccountNumber: yup.string().when({
      is: () => registerWatch.hasAccount === '1',
      then: (schema) => {
        return schema
          .required(E0000(common.label.corporateAccountNumber))
          .length(7, A07.message.corporateAccountNumberFormat);
      },
    }),
    picName: yup.string().required(E0000(common.label.picName)),
    picNameKana: yup.string().required(E0000(common.label.picNameKana)),
    industryCodeMajorClassification: yup
      .string()
      .required(E0000(common.label.industryMajorCodeClassification)),
    industryCodeMediumClassification: yup
      .string()
      .required(E0000(common.label.industryMediumCodeClassification)),
  });

  const method = useForm<A07RequestType>({
    defaultValues: A07_DEFAULT_VALUE,
    resolver: yupResolver(schema),
  });
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    setError,
    clearErrors,
  } = method;

  const registerWatch = watch();
  const navigate = useNavigate();
  const { registerId } = useParams();

  const [insert, setInsert] = useState(false);
  const [edited, setEdited] = useState(false);
  const [addressFromPostalCode] = useAddress(registerWatch.postalCode);
  const codeMajorOptions = createMajorOptions(codeMajors);
  const [isEditedMajor, setIsEditedMajor] = useState(false);
  const codeMediumOptions = useMemo(() => {
    const rs = createMediumOptions(
      codeMediums,
      registerWatch.industryCodeMajorClassification,
    );
    if (isEditedMajor) {
      setValue('industryCodeMediumClassification', rs?.[0]?.value);
    } else {
      setValue(
        'industryCodeMediumClassification',
        registerWatch.industryCodeMediumClassification,
      );
    }
    return rs;
  }, [
    registerWatch.industryCodeMajorClassification,
    codeMediums,
    isEditedMajor,
  ]);

  const KaiinId = useMemo(() => {
    return (
      registerWatch.bankCode +
      registerWatch.enterpriseCode +
      dayjs(new Date()).format('YYMMDDHHmmss')
    );
  }, [registerWatch]);

  const KaiinPass = Math.floor(Math.random() * 100000000) + '';
  useEffect(() => {
    const currentDate = dayjs(new Date()).format('YYYYMMDD');
    (async () => {
      const merchantPass = await getMerchantPass();
      const merchantId = await getMerchantId();
      if (!isEmpty(card.card)) {
        mutateCheckValidCard({
          merchantId: merchantId,
          merchantPass: merchantPass,
          transactionDate: currentDate,
          operateId: '4MemAdd',
          token: card.token ?? '',
          kaiinId: KaiinId,
          kaiinPass: KaiinPass,
        });
      }
    })();
  }, [card]);

  useEffect(() => {
    if (registerWatch.hasAccount === '2') {
      setValue('branchNumber', '');
      setValue('corporateAccountNumber', '');
    }
  }, [registerWatch.hasAccount]);

  useEffect(() => {
    const beforeClosing = (e: BeforeUnloadEvent) => {
      if (step !== 4) {
        e.preventDefault();
        const msgClose = 'Are you sure you want to close?';
        e.returnValue = msgClose;
        return msgClose;
      }
    };

    if (insert) {
      window.addEventListener('beforeunload', beforeClosing);
    } else {
      window.removeEventListener('beforeunload', beforeClosing);
    }

    return () => {
      window.removeEventListener('beforeunload', beforeClosing);
    };
  }, [insert]);

  useEffect(() => {
    if (edited) {
      setValue('address1', addressFromPostalCode.arch.full);
      if (addressFromPostalCode.error) {
        setError('postalCode', { message: A07.message.postalCodeFormat });
      } else {
        clearErrors('postalCode');
        clearErrors('address1');
      }
    }
  }, [addressFromPostalCode]);

  const { mutateAsync: insertMutate } = useHgbMutationPost<
    undefined,
    A07RequestType
  >(API_URLS.ENTERPRISE_REGISTER_API_URL, {
    onSuccess: (data) => {
      if (data && data.statusCode === 10011) {
        mutateCheckDuration({
          registerId: registerId ?? '',
        });
        setStep(4);
      }
    },
  });

  const { mutateAsync: updateMutate } = useHgbMutationPut<
    undefined,
    A07RequestType
  >(API_URLS.ENTERPRISE_REGISTER_API_URL, {
    onSuccess: (data) => {
      if (data?.statusCode === STATUS_CODE.updateSuccess) {
        setStep(4);
      }
    },
  });

  const onSubmit = () => {
    switch (step) {
      case 1:
        setStep(2);
        break;
      case 2:
        setStep(3);
        break;
      default:
        if (insert) {
          return insertMutate({ ...registerWatch, privacyPolicyUrl: '' });
        }
        return updateMutate({ ...registerWatch, privacyPolicyUrl: '' });
    }
  };
  const { mutate: mutateCheckDuration } = useHgbMutationPost<
    A07CheckValidURLResponse,
    CheckDurationRequestType
  >(API_URLS.CHECK_DURATION_API_URL, {
    isAlert: false,
    onSuccess(data) {
      if (data.statusCode !== 10000 || data.result?.isExpired) {
        return navigate(routs_en['/temporarily-register/fail'].link, {
          state: 'fail' + data.result?.expirationPeriodHours,
        });
      }
      reset({
        ...registerWatch,
        ...data.result,
      });
      if (!data.result?.enterpriseLandingPage) {
        setInsert(true);
      } else {
        setInsert(false);
        reset({
          ...registerWatch,
          ...data.result,
          ...data.result.enterpriseLandingPage,
        });
      }
    },
  });

  useEffect(() => {
    if (!registerId) {
      return navigate(routs_en['/temporarily-register/fail'].link, {
        state: 'fail' + registerWatch.expirationPeriodHours,
      });
    }
    mutateCheckDuration({
      registerId: registerId,
    });
  }, [registerId]);

  const { data: paymentQuery } = useHgbQuery<
    PaymentTypeResponseA07,
    PaymentTypeRequest
  >(
    API_URLS.PAYMENT_TYPE_LISTBOX,
    {
      enterpriseId: registerWatch?.id,
      registerId: registerId,
    },
    {
      queryKey: [API_URLS.PAYMENT_TYPE_LISTBOX, registerWatch?.id],
      enabled: !isCallPayment,
    },
  );

  const paymentOption = useMemo(
    () => parseToHgbSelectOptions(paymentQuery?.result?.methodType),
    [paymentQuery?.result],
  );

  const { data: haveNoneQuery } = useHgbQuery<PaymentTypeResponse, undefined>(
    API_URLS.HAVE_NONE_LISTBOX,
    undefined,
    {
      queryKey: [API_URLS.HAVE_NONE_LISTBOX],
    },
  );

  const haveNoneOption = useMemo(
    () => parseToHgbSelectOptions(haveNoneQuery?.result),
    [haveNoneQuery?.result],
  );

  const title = useMemo(() => {
    switch (step) {
      case 2:
        return A07.L2Title;
      case 3:
        return A07.L3Title;
      case 4:
        return A08.mainTitle;
      default:
        return A07.L1Title;
    }
  }, [step]);

  useEffect(() => {
    if (step === 2) setValue('agreement', false);
  }, [step]);

  const handleSubmitBankComplimentary = () => {
    setIsCallPayment(true);
    sendEmailBankComplimentaryMutate({
      id: registerWatch.bankId,
      email: registerWatch.picEmail,
    });
  };

  return !isLoading ? (
    <TemporaryTemplate title={title}>
      <FormProvider {...method}>
        <form
          className="tw-flex tw-flex-col tw-gap-16 pc:tw-gap-24"
          onSubmit={handleSubmit(onSubmit)}
        >
          {step === 1 && (
            <>
              <HgbResponsive
                gap
                className="tw-grid tw-w-full tw-flex-auto tw-grid-cols-1 tw-items-start tw-self-start tw-rounded-16 tw-bg-white tw-p-16 pc:tw-grid-cols-2 pc:tw-p-24"
              >
                <div>
                  <h4 className="tw-font-bold">{A07.label.bankName}</h4>
                  <h4>{registerWatch.bankName}</h4>
                </div>
                <HgbAntdInput
                  {...register('enterpriseName')}
                  maxLength={100}
                  type="K"
                  required
                  className="tw-col-start-1"
                  label={common.label.enterpriseName}
                  placeholder={common.placeholder.enterpriseName}
                />
                <HgbAntdInput
                  {...register('enterpriseNameKana')}
                  maxLength={100}
                  type="K"
                  required
                  label={common.label.enterpriseNameKana}
                  placeholder={common.placeholder.enterpriseNameKana}
                />
                <HgbAntdInput
                  {...register('representative')}
                  maxLength={50}
                  type="K"
                  required
                  className="tw-col-start-1"
                  label={common.label.representative}
                  placeholder={A07.placeholder.representative}
                />
                <HgbAntdInput
                  {...register('representativeKana')}
                  maxLength={50}
                  type="K"
                  required
                  label={common.label.representativeKana}
                  placeholder={A07.placeholder.representativeKana}
                />
                <HgbAntdInput
                  {...register('postalCode')}
                  maxLength={7}
                  type={'integer'}
                  required
                  label={common.label.postalCode}
                  placeholder={common.placeholder.postalCode}
                  onChangeValue={() => setEdited(true)}
                />
                <HgbAntdInput
                  {...register('address1')}
                  maxLength={100}
                  type="K"
                  required
                  className="tw-col-start-1"
                  label={common.label.fullAddress}
                  placeholder={A07.placeholder.address1}
                />
                <HgbAntdInput
                  {...register('address2')}
                  maxLength={100}
                  type="K"
                  // required
                  className="tw-col-start-1"
                  label={common.label.address2}
                  placeholder={A07.placeholder.address2}
                />
                <HgbAntdInput
                  {...register('phoneNumber')}
                  maxLength={11}
                  type={'integer'}
                  required
                  className="tw-col-start-1"
                  label={common.label.phoneNumber}
                  placeholder={common.placeholder.phoneNumber}
                />
                <HgbSelect
                  {...register('startMonthOfYear')}
                  options={MONTH_OF_YEAR_OPTIONS}
                  label={common.label.startMonthOfYear}
                  required
                  placeholder={A07.placeholder.startMonthOfYear}
                />

                <HgbSelect
                  label={common.label.industryMajorCodeClassification}
                  className="tw-self-start"
                  {...register('industryCodeMajorClassification')}
                  placeholder={''}
                  options={codeMajorOptions}
                  onChangeValue={() => setIsEditedMajor(true)}
                  required
                />
                <HgbSelect
                  label={common.label.industryMediumCodeClassification}
                  // className="tw-self-start"
                  {...register('industryCodeMediumClassification')}
                  placeholder={''}
                  options={codeMediumOptions}
                  required
                />
                {registerWatch.subscriptionType !== 'COMPLIMENTARY' && (
                  <HgbSelect
                    {...register('paymentType')}
                    options={paymentOption}
                    label={common.label.paymentMethod}
                    required={
                      registerWatch.subscriptionType !== 'COMPLIMENTARY'
                    }
                    placeholder={''}
                    defaultActiveFirstOption={true}
                  />
                )}
                <HgbAntdRadioGroup
                  {...register('hasAccount')}
                  options={haveNoneOption}
                  label={common.label.isCorporateAccount}
                  required
                  // defaultValue={haveNoneOption[0].value}
                />
                {registerWatch.hasAccount === '1' && (
                  <HgbAntdInput
                    {...register('branchNumber')}
                    maxLength={3}
                    type={'integer'}
                    required
                    label={common.label.branchNumber}
                  />
                )}
                {registerWatch.hasAccount === '1' && (
                  <HgbAntdInput
                    {...register('corporateAccountNumber')}
                    maxLength={7}
                    type={'integer'}
                    required
                    label={common.label.corporateAccountNumber}
                  />
                )}
                <HgbAntdInput
                  {...register('picName')}
                  maxLength={50}
                  type={'K'}
                  required
                  label={common.label.picName}
                />
                <HgbAntdInput
                  {...register('picNameKana')}
                  maxLength={50}
                  type={'K'}
                  required
                  label={common.label.picNameKana}
                />
                <div>
                  <h4 className="tw-font-bold">{common.label.picEmail}</h4>
                  <h4>{registerWatch.picEmail}</h4>
                </div>
              </HgbResponsive>
              <div className="tw-shrink-0 tw-self-center">
                <HgbAntdButton
                  className="tw-flex-shrink-0"
                  type="primary"
                  htmlType="submit"
                  //   icon={<SaveOutlined />}
                >
                  <>{common.button.next}</>
                </HgbAntdButton>
              </div>
            </>
          )}
          {step === 2 && (
            <>
              <div className="tw-flex tw-flex-col tw-gap-8">
                <HgbResponsive
                  gap
                  className="tw-grid tw-w-full tw-flex-auto tw-grid-cols-1 tw-items-start tw-self-start tw-rounded-16 tw-bg-white tw-p-16 pc:!tw-gap-16 pc:tw-p-24"
                >
                  {/* <h2 className="tw-font-bold">{label.term1}</h2> */}
                  {/* <div className="tw-mt-4 tw-h-1 tw-bg-[#C7D9E4] pc:tw-mt-8" /> */}
                  <div className="tw-max-h-[60vh] tw-overflow-y-scroll">
                    <>
                      <h2 className="tw-mb-20 tw-font-bold">ご契約内容</h2>
                      <div className="tw-border-[1px] tw-border-solid tw-border-neutral-8">
                        <div className="tw-grid tw-grid-cols-5 tw-border-b-[1px] tw-border-l-0 tw-border-r-0 tw-border-t-0 tw-border-solid tw-border-neutral-8">
                          <div className="tw-border-b-0 tw-border-l-0 tw-border-r-[1px] tw-border-t-0 tw-border-solid tw-border-neutral-8 tw-bg-[#B9EBFE]">
                            <p className="tw-ml-6 tw-mt-6 tw-text-left">
                              サービス内容
                            </p>
                          </div>
                          <div className="tw-col-span-4">
                            <p className="tw-ml-6 tw-mt-6 tw-text-left">
                              ＣＯ2排出量算定システム <br />
                              「Zero-Carbon-System（炭削くん）」
                            </p>
                          </div>
                        </div>
                        <div className="tw-grid tw-grid-cols-5 tw-border-b-[1px] tw-border-l-0 tw-border-r-0 tw-border-t-0 tw-border-solid tw-border-neutral-8">
                          <div className="tw-border-b-0 tw-border-l-0 tw-border-r-[1px] tw-border-t-0 tw-border-solid tw-border-neutral-8 tw-bg-[#B9EBFE]">
                            <p className="tw-ml-6 tw-mt-6 tw-text-left">
                              基本利用料金{' '}
                            </p>
                          </div>
                          <div className="tw-col-span-4">
                            <p className="tw-ml-6 tw-mt-6 tw-text-left">
                              月額2,200円（税込）
                            </p>
                          </div>
                        </div>
                        <div className="tw-grid tw-grid-cols-5 tw-border-b-[1px] tw-border-l-0 tw-border-r-0 tw-border-t-0 tw-border-solid tw-border-neutral-8">
                          <div className="tw-border-b-0 tw-border-l-0 tw-border-r-[1px] tw-border-t-0 tw-border-solid tw-border-neutral-8 tw-bg-[#B9EBFE]">
                            <p className="tw-ml-6 tw-mt-6 tw-text-left">
                              お支払方法{' '}
                            </p>
                          </div>
                          <div className="tw-col-span-4">
                            <p className="tw-ml-6 tw-mt-6 tw-text-left">
                              クレジットカード払い
                            </p>
                          </div>
                        </div>
                        <div className="tw-grid tw-grid-cols-5 tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-t-0 tw-border-solid tw-border-neutral-8">
                          <div className="tw-border-b-0 tw-border-l-0 tw-border-r-[1px] tw-border-t-0 tw-border-solid tw-border-neutral-8 tw-bg-[#B9EBFE]">
                            <p className="tw-ml-6 tw-mt-6 tw-text-left">
                              利用期間
                            </p>
                          </div>
                          <div className="tw-col-span-4">
                            <p className="tw-ml-6 tw-mt-6 tw-text-left">
                              利用期間は、登録完了日から登録完了日の属する月の末日までとし、毎月１日に1カ月間自動的に延長されます。
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <p>利用料金詳細</p>
                        <div className="tw-border-[1px] tw-border-solid tw-border-neutral-8">
                          <div className="tw-grid tw-grid-cols-2 tw-border-b-[1px] tw-border-l-0 tw-border-r-0 tw-border-t-0 tw-border-solid tw-border-neutral-8">
                            <div className="tw-border-b-0 tw-border-l-0 tw-border-r-[1px] tw-border-t-0 tw-border-solid tw-border-neutral-8">
                              <p className="tw-ml-6 tw-mt-6 tw-text-left">
                                アカウント{' '}
                              </p>
                            </div>
                            <div className="">
                              <p className="tw-ml-6 tw-mt-6 tw-text-left">
                                料金
                              </p>
                            </div>
                          </div>
                          <div className="tw-grid tw-grid-cols-2 tw-border-b-[1px] tw-border-l-0 tw-border-r-0 tw-border-t-0 tw-border-solid tw-border-neutral-8">
                            <div className="tw-border-b-0 tw-border-l-0 tw-border-r-[1px] tw-border-t-0 tw-border-solid tw-border-neutral-8">
                              <p className="tw-ml-6 tw-mt-6 tw-text-left">
                                1~5ユーザー{' '}
                              </p>
                            </div>
                            <div className="">
                              <p className="tw-ml-6 tw-mt-6 tw-text-left">
                                月額2,200円（税込）
                              </p>
                            </div>
                          </div>
                          <div className="tw-grid tw-grid-cols-2 tw-border-b-[1px] tw-border-l-0 tw-border-r-0 tw-border-t-0 tw-border-solid tw-border-neutral-8">
                            <div className="tw-border-b-0 tw-border-l-0 tw-border-r-[1px] tw-border-t-0 tw-border-solid tw-border-neutral-8">
                              <p className="tw-ml-6 tw-mt-6 tw-text-left">
                                6~10ユーザー{' '}
                              </p>
                            </div>
                            <div className="">
                              <p className="tw-ml-6 tw-mt-6 tw-text-left">
                                月額3,300円（税込）
                              </p>
                            </div>
                          </div>
                          <div className="tw-grid tw-grid-cols-2 tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-t-0 tw-border-solid tw-border-neutral-8">
                            <div className="tw-border-b-0 tw-border-l-0 tw-border-r-[1px] tw-border-t-0 tw-border-solid tw-border-neutral-8">
                              <p className="tw-ml-6 tw-mt-6 tw-text-left">
                                11~15ユーザー{' '}
                              </p>
                            </div>
                            <div className="">
                              <p className="tw-ml-6 tw-mt-6 tw-text-left">
                                月額4,400円（税込）
                              </p>
                            </div>
                          </div>
                        </div>
                        <p>※以後、5ユーザー毎に加算</p>
                      </div>
                      <h2 className="tw-my-20 tw-font-bold">
                        炭削くん 利用規約
                      </h2>
                      <div>
                        <p>
                          本利用規約（以下「本規約」といいます。）は、株式会社肥後銀行（以下「当行」といいます。）が「Zero-Carbon-System（炭削くん）」の名称で提供するサービス（以下「本サービス」といいます。）の提供条件及びお客様と当行との間の権利義務関係を定めるものです。本サービスのご利用に際しては、本規約の全文をお読みください。{' '}
                        </p>
                        <br></br>
                        <p>
                          第1条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（適用）
                        </p>
                        <div className="tw-flex">
                          <p className="tw-mr-20">1.</p>
                          <p>
                            本規約は、本サービスの提供条件及び本サービスの利用に関するお客様と当行との権利義務関係を定めることを目的とし、お客様と当行との間の本サービスに関する一切の関係に適用されます。{' '}
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">2.</p>
                          <p>
                            本規約と、本利用契約（第2条第1項で定義します。）その他の本サービスに関して本規約とは別にお客様と当行との間で締結される契約又は規約等（以下これらを総称して「個別契約等」といいます。）が存在する場合、個別契約等も本規約の一部を構成するものとし、個別契約等の定めと本規約の定めが抵触するときは個別契約等の定めが優先するものとします。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">3.</p>
                          <p>
                            本規約の内容と本規約以外における本サービスの説明等とが異なる場合は、本規約の規定が優先して適用されるものとします。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">4.</p>
                          <p>
                            本規約のうち当行が本サービスの利用を許諾した時点ではお客様に適用されない規定がある場合、当該規定は、事情変更によって将来適用可能となった時点から適用されるものとします。
                          </p>
                        </div>
                        <br></br>
                        <p>
                          第2条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（定義）
                        </p>
                        <p>
                          本規約において使用する用語の定義は以下の通りとします。
                        </p>
                        <div className="tw-flex">
                          <p className="tw-mr-[18px]">1.</p>
                          <p>
                            「本利用契約」とは、お客様と当行との間で締結される本サービスの提供に関する契約をいいます。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-[18px]">2.</p>
                          <p>
                            「お客様」とは、本規約の内容に承諾の上、当行所定の手続に従って本サービスの利用を申し込み、当行がその申込を承諾した法人及び団体をいいます。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-[18px]">3.</p>
                          <p>
                            「当行ウェブサイト」とは、本サービスの専用ページをいいます。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-[18px]">4.</p>
                          <p>
                            「登録希望者」とは、本サービスの利用を希望し、登録の申込みを行い又は登録の申込みを行おうとする法人、団体又はそれらを代表する個人を意味します。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-[18px]">5.</p>
                          <p>
                            「登録データ」とは、お客様又はユーザーが本サービスを利用してアップロード等する情報（氏名、メールアドレス、会社情報、電力・ガス等の請求書のデータを含みますが、これらに限りません。）をいいます。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-[18px]">6.</p>
                          <p>
                            「アカウント」とは、本サービスを利用するための権限をいいます。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-[18px]">7.</p>
                          <p>
                            「ユーザー」とは、お客様の役員又は従業員のうち、アカウントを有する者をいいます。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-[18px]">8.</p>
                          <p>
                            「ユーザー責任者」とは、ユーザーのうち、お客様における本サービスを利用する上での責任者をいいます。初期のユーザー責任者は、お申込時に担当者としてご登録いただいたユーザーとし、企業情報ページにて確認・変更できます。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-[18px]">9.</p>
                          <p>
                            「アカウント情報」とは、ユーザーがアカウントに基づいて本サービスを利用するために必要なID、パスワード等の情報をいいます。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-[10px]">10.</p>
                          <p>
                            「ID」とは、パスワードと組み合わせて、ユーザーとその他の者を識別するために用いられる符号をいいます。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-[10px]">11.</p>
                          <p>
                            「パスワード」とは、IDと組み合わせて、ユーザーとその他の者を識別するために用いられる符号をいいます。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-[10px]">12.</p>
                          <p>
                            「グループ会社」とは、親会社、子会社及び親会社の子会社を指します。
                          </p>
                        </div>
                        <br></br>
                        <p>
                          第3条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（サービス内容）
                        </p>
                        <div className="tw-flex">
                          <p className="tw-mr-20">1.</p>
                          <p>
                            本サービスは、電力・ガス等のエネルギーのコストや使用実績、及び電力・ガス等のエネルギー使用量から換算されるCO₂排出量のデータ管理・可視化等を提供するサービスです。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">2.</p>
                          <p>
                            当行は本サービスの内容又は機能等を、随時追加、変更又は削除等ができるものとし、お客様は異議・申し立てを行わないものとします。また、当行はお客様に対し、本サービスの内容又は機能等の追加、変更、削除による責任を負わないものとします。
                          </p>
                        </div>
                        <br></br>
                        <p>
                          第4条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（登録）
                        </p>
                        <div className="tw-flex">
                          <p className="tw-mr-20">1.</p>
                          <p>
                            登録希望者は、当行が定める一定の情報（以下「登録事項」といいます。）を当行に提供することにより、本サービスの利用を申請することができます。なお、登録希望者は、本サービスの利用を申請した際に、本規約に同意したものとみなされます。また、当該申請を行った方は、当該申請により、登録希望者を代表して本規約を締結する権限を有することを表明したものとします。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">2.</p>
                          <p>
                            当行は、前項の申請を受理した後、登録の可否を当行の基準に従って判断し、当行が登録を認める場合にはその旨を登録申請者に通知します。本サービスに関するお客様としての登録は、当行が当該通知を行ったことをもって完了したものとします。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">3.</p>
                          <p>
                            前項の登録の完了をもって、本規約がお客様と当行との間に成立し、お客様は本サービスを本規約に従い利用できるようになります。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">4.</p>
                          <p>
                            当行は、以下の各号のいずれかの事由に該当する場合は、登録及び再登録を拒否することがあります。また、その理由について当行は一切開示義務を負わないものとし、審査の異議をお客様は述べられないものとします。
                          </p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-20">(1)</p>
                          <p>
                            当行に提供した登録事項の全部又は一部につき虚偽、誤記又は記載漏れがあった場合
                          </p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-20">(2)</p>
                          <p>
                            本サービスと類似・競合するサービス若しくはウェブサイトを運営している又はその運営に協力していると当行が判断した場合
                          </p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-20">(3)</p>
                          <p>
                            申請を行った方が、登録申請者を代表して、本サービスの利用を申請する正当な権限を有していなかった場合、又は、未成年者、成年被後見人、被保佐人又は被補助人のいずれかであり、法定代理人、後見人、保佐人又は補助人の同意等を得ていなかった場合
                          </p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-20">(4)</p>
                          <p>
                            第22条（反社会的勢力の排除）第1項に定める暴力団員等又は同項第1号ないし第4号に該当する者である又はそのおそれがあると当行が判断した場合
                          </p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-20">(5)</p>
                          <p>
                            過去に当行との契約に違反した者又はその関係者であると当行が判断した場合
                          </p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-20">(6)</p>
                          <p>
                            第13条（禁止事項）各号に掲げる行為を行ったことがある又は行うおそれがあると当行が判断した場合
                          </p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-20">(7)</p>
                          <p>
                            第16条（当行による利用停止又は解除等）に定める措置を受けたことがある場合
                          </p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-20">(8)</p>
                          <p>その他、当行が登録を適当でないと判断した場合</p>
                        </div>
                        <br></br>
                        <p>
                          第5条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（登録事項の変更）
                        </p>
                        <div className="tw-flex">
                          <p className="tw-mr-20">1.</p>
                          <p>
                            お客様は、登録事項に変更があった場合、本サービス内にて速やかに変更するものとします。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">2.</p>
                          <p>
                            当行は、お客様が前項の変更を怠ったことにより、お客様又は第三者が損害を被った場合であっても、一切責任を負わないものとします。
                          </p>
                        </div>
                        <br></br>
                        <p>
                          第6条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（アカウント）
                        </p>
                        <div className="tw-flex">
                          <p className="tw-mr-20">1.</p>
                          <p>
                            本サービスに関するお客様と当行との間の連絡、確認等は、原則として、ユーザー責任者を通じて行うものとします。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">2.</p>
                          <p>
                            お客様は、ユーザー責任者を変更する場合には、本サービス内にて速やかに変更するものとします。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">3.</p>
                          <p>
                            お客様は、自己の責任において、本サービスに関するアカウント情報を適切に管理及び保管するものとし、自ら又はユーザーをして、これを第三者に利用させ、又は貸与、譲渡、名義変更、売買等をしてはならないものとします。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">4.</p>
                          <p>
                            アカウント情報の管理不十分、使用上の過誤、第三者の使用等によって生じた損害に関する責任はお客様が負うものとし、当行は一切の責任を負いません。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">5.</p>
                          <p>
                            当行は、アカウント情報の提供後に行われた当該アカウント情報による本サービスの利用行為については、すべてお客様ご自身が実施したものとしてお客様にその効果が帰属するものとみなします。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">6.</p>
                          <p>
                            当行は、当行の基準により、お客様のアカウントが不正使用されている可能性があると当行が判断した場合は、当該アカウントを停止することができます。その場合、お客様は当行が定める手続きに伴い、利用停止措置の解除手続きを行うこととします。なお、当行がこのような措置をとったことにより、お客様がアカウントを使用できないことを理由として損害が生じても、当行は一切の責任を負いません。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">7.</p>
                          <p>
                            お客様は、第三者にアカウント情報を知られた場合は、直ちに当行に連絡するものとします。その際に、当行からお客様にアカウント停止等の要請があった場合は従うものとします。
                          </p>
                        </div>
                        <br></br>
                        <p>
                          第7条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（利用期間）
                        </p>
                        <p>
                          利用期間は、自動的に延長されます。解約の手続きは第17条に基づくものとします。
                        </p>
                        <br></br>
                        <p>
                          第8条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（利用料金）
                        </p>
                        <div className="tw-flex">
                          <p className="tw-mr-20">1.</p>
                          <p>
                            お客様は、当行が別途定める利用料金を支払うものとします。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">2.</p>
                          <p>
                            当行は、お客様の月末時点のユーザー数に応じて料金を確定し、お客様に請求するものとします。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">3.</p>
                          <p>
                            当行は、お客様の同意なく、当行の裁量により本サービスの利用料金を変更することがあります。この場合、当行は、利用料金の変更の3か月前までに、当行の定める方法によりお客様へ当該変更を通知します。お客様は、当該利用料金の変更に不服がある場合、第17条第1項に基づき、本規約及び個別契約等を解約し、自己のお客様としての登録を抹消できます。お客様への利用料金の変更の通知の後、当該変更が適用される日までの間に、お客様が本サービスを利用した場合又は解約の手続をとらなかった場合には、お客様は、当該利用料金の変更に同意したものとみなします。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">4.</p>
                          <p>
                            お客様は、当行が別途定める方法でのみ、本サービスに係る利用料金の決済を行うことができます。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">5.</p>
                          <p>
                            本サービスの利用期間が、暦月において１ヶ月に満たぬ利用期間となった場合にも1ヶ月分の利用料金をお客様に請求するものとします。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">6.</p>
                          <p>
                            第16条（当行による利用停止又は解除等）及び第17条（解約）に基づく解除又は解約があった場合であっても、当行は、利用料金の日割り計算及び払い戻しは行わないものとし、お客様は、解除又は解約日に関わらず利用期間分の利用料金を当行に支払うものとします。
                          </p>
                        </div>
                        <br></br>
                        <p>
                          第9条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（利用状況データ及びフィードバック）
                        </p>
                        <div className="tw-flex">
                          <p className="tw-mr-20">1.</p>
                          <p>
                            お客様は、当行が本サービスの利用状況を把握するための仕組みであるクッキー（Cookie）により利用状況を記録することに同意するものとします。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">2.</p>
                          <p>
                            当行は前項に基づき記録されたデータ、登録データその他のお客様及びユーザーに関するデータ（以下あわせて「利用状況データ」といいます。）を、本サービスを含む当行又は当行グループが提供し又は提供しようとしているサービスの提供、開発、改善、品質向上、お客様をはじめとする顧客の皆様へのご提案、ご案内の目的で利用し、かつ、当行のグループ会社に開示して共同利用することができるものとします。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">3.</p>
                          <p>
                            当行は、統計的目的で本サービスの利用状況又はお客様及びユーザーの状況を分析し、統計結果を一般に公表することがあります。但し、個々のお客様及び特定の個人が識別されることのないようにいたします。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">4.</p>
                          <p>
                            当行は、お客様から本サービスの利用情報を取得し、又はお客様に対して本サービスに関するフィードバックの提供を求める場合があります。お客様は、これらの利用情報又はフィードバックの内容につき、個別契約等期間中及び個別契約等終了後において当行が利用することに同意するものとします。ただし、これらの利用情報又はフィードバックの内容の利用の目的は、本サービスの運営、改善、プロモーション並びに当行及び当行のグループ会社による新サービスの開発に限定されます。
                          </p>
                        </div>
                        <br></br>
                        <p>
                          第10条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（電力ガス等関連データ）
                        </p>
                        <div className="tw-flex">
                          <p className="tw-mr-20">1.</p>
                          <p>
                            お客様は、本サービスにおいて、当行がお客様から受領するお客様の電力・ガス等の使用に関する使用量、料金、CO₂排出量その他の情報及びお客様の属性に関する情報(業種、拠点数、その他の企業の属性に関する情報を含みます。）（以下「電力ガス等関連データ」といいます。）について、当行が本条の定めに従い使用することに同意するものとします。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">2.</p>
                          <p>
                            当行は、電力ガス等関連データ（お客様を特定できないデータに限る。）を、本サービスを含む当行又は当行グループが提供し又は提供しようとしているサービスの提供、開発、改善、品質向上、お客様をはじめとする顧客の皆様へのご提案、ご案内の目的で利用し、かつ、当行のグループ会社に開示して共同利用することができるものとします。また、当行及び当行グループ会社は、その委託先に対し、電力ガス等関連データを開示できるものとします。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">3.</p>
                          <p>
                            当行は、統計的目的で電力ガス等関連データを分析し、統計結果を一般に公表することがあります。但し、個々のお客様及び特定の個人が識別されることのないようにいたします。
                          </p>
                        </div>
                        <br></br>
                        <p>
                          第11条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（委託）
                        </p>
                        <p>
                          当行は、本サービスの提供に関して、業務の全部又は一部を、当行の責任において第三者に委託することがあります。ただし、その場合、当行は責任をもって委託先を管理するものとします。
                        </p>
                        <br></br>
                        <p>
                          第12条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（権利帰属）
                        </p>
                        <div className="tw-flex">
                          <p className="tw-mr-20">1.</p>
                          <p>
                            本サービス及び当行ウェブサイトに関する知的財産権等その他一切の有体・無体の財産権は全て当行又は当行にライセンスを許諾している者に帰属しており、お客様に対して譲渡又は本規約に定める以上の使用許諾を行うものではなく、かつその他の権利を付与するものではありません。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">2.</p>
                          <p>
                            当行はお客様の名称を本サービス導入企業として公開することができるものとします。この場合、当行は事例を公開するために必要な範囲で、お客様のロゴ及び商標等を無償で使用できるものとします。お客様は、当該公開又はロゴ及び商標等の使用の停止や削除、その他必要な措置を当行に請求することができるものとします。
                          </p>
                        </div>
                        <br></br>
                        <p>
                          第13条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（禁止事項）
                        </p>
                        <div className="tw-flex">
                          <p className="tw-mr-20">1.</p>
                          <p>
                            お客様は、本サービスの利用にあたり、自ら又はユーザーをして、以下の各号のいずれかに該当する行為又は該当すると当行が判断する行為をしてはなりません。また、お客様の以下の行為によって当行に損害が生じた場合、お客様は責任を負うものとします。
                          </p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-20">(1)</p>
                          <p>法令に違反する行為又は犯罪行為に関連する行為</p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-20">(2)</p>
                          <p>
                            当行、他のお客様若しくはユーザー又はその他の第三者に対する詐欺又は脅迫行為
                          </p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-20">(3)</p>
                          <p>公序良俗に反する行為</p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-20">(4)</p>
                          <p>
                            当行、他のお客様若しくはユーザー又はその他の第三者の著作権、特許権、実用新案権、意匠権、商標権その他の知的財産権（それらの権利を取得し又はそれらの権利につき登録等を出願する権利を含み、以下「知的財産権等」と総称します。）、肖像権、プライバシーの権利、名誉、その他の権利又は利益を侵害する行為
                          </p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-20">(5)</p>
                          <p>
                            本サービスのネットワーク又はシステム等に過度な負担をかける行為
                          </p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-20">(6)</p>
                          <p>
                            本サービスの運営を妨害するおそれのある行為、当行のネットワーク若しくはシステム等に不正にアクセスする行為、又は不正なアクセスを試みる行為
                          </p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-20">(7)</p>
                          <p>
                            逆コンパイル又は逆アセンブル等、当行のソフトウェア等を解析するための一切のリバースエンジニアリング行為
                          </p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-20">(8)</p>
                          <p>第三者に成りすます行為</p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-20">(9)</p>
                          <p>
                            同一の法人、団体又は人物が、その必要がないにも関わらず複数のお客様登録を行なう行為
                          </p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-12">(10)</p>
                          <p>
                            他のお客様又はユーザーのアカウントを利用する行為
                          </p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-12">(11)</p>
                          <p>
                            当行が事前に承諾しない本サービス上での宣伝、広告、勧誘又は営業行為
                          </p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-12">(12)</p>
                          <p>本サービスの他のお客様の情報の収集行為</p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-12">(13)</p>
                          <p>
                            当行、他のお客様若しくはユーザー又はその他の第三者に不利益、損害、不快感を与える行為
                          </p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-12">(14)</p>
                          <p>反社会的勢力等への利益供与行為</p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-12">(15)</p>
                          <p>
                            前各号の行為を直接又は間接に惹起し、又は容易にする行為
                          </p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-12">(16)</p>
                          <p>その他、当行が不適切と判断する行為</p>
                        </div>
                        <br></br>
                        <p>
                          第14条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（本サービスの停止等）
                        </p>
                        <div className="tw-flex">
                          <p className="tw-mr-20">1.</p>
                          <p>
                            当行は以下のいずれかに該当する場合には、お客様に事前に通知することなく、本サービスの全部又は一部の提供を停止又は中断できるものとします。
                          </p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-20">(1)</p>
                          <p>
                            本サービスに係るコンピューター・システムの点検又は保守作業を緊急に行う場合
                          </p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-20">(2)</p>
                          <p>
                            コンピューター又は通信回線等が事故により停止した場合、予期しない不正アクセス等の行為が判明した場合
                          </p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-20">(3)</p>
                          <p>
                            本サービスの提供に必要な外部システム（Microsoft
                            Azure等）の提供又は利用が遮断された場合
                          </p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-20">(4)</p>
                          <p>
                            地震、落雷、火災、風水害、停電、疾病の蔓延、天災地変等の不可抗力により本サービスの運営ができなくなった場合
                          </p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-20">(5)</p>
                          <p>その他、当行が停止又は中断を必要と判断した場合</p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">2.</p>
                          <p>
                            当行は、本条に基づき当行が行った措置によって生じた損害について一切の責任を負いません。
                          </p>
                        </div>
                        <br></br>
                        <p>
                          第15条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（本サービスの変更又は終了）
                        </p>
                        <div className="tw-flex">
                          <p className="tw-mr-20">1.</p>
                          <p>
                            当行は、当行の都合により、本サービスの内容の変更又は提供の終了ができるものとします。本サービスの提供を終了する場合、当行はお客様に対して終了予定日の3ヶ月前までに通知を行うものとします。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">2.</p>
                          <p>
                            当行は、本条に基づき当行が行った措置によって生じた損害について一切の責任を負いません。
                          </p>
                        </div>
                        <br></br>
                        <p>
                          第16条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（当行による利用停止又は解除等）
                        </p>
                        <div className="tw-flex">
                          <p className="tw-mr-20">1.</p>
                          <p>
                            当行は、お客様が以下の各号のいずれかの事由に該当する場合、事前の通知又は催告をすることなく、保存されているデータ（登録事項及び登録データを含みますが、これに限られません。）の削除、本サービス利用の一時的な停止、登録の抹消又は個別契約等の解除を行うことができるものとします。
                          </p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-20">(1)</p>
                          <p>
                            本規約及び個別契約等のいずれかの条項に違反した場合
                          </p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-20">(2)</p>
                          <p>登録事項に虚偽の事実があることが判明した場合</p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-20">(3)</p>
                          <p>
                            当行からの問い合わせ、その他の回答を求める連絡に対して14日間以上応答がない場合
                          </p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-20">(4)</p>
                          <p>第4条（登録）第4項各号に該当する場合</p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-20">(5)</p>
                          <p>
                            3ヶ月連続で利用料金のお支払が確認できなかった場合
                          </p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-20">(6)</p>
                          <p>
                            支払停止若しくは支払不能となり、又は破産手続開始、民事再生手続開始、会社更生手続、特別清算開始若しくはこれらに類する手続の開始の申立てがあった場合
                          </p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-20">(7)</p>
                          <p>
                            差押え、仮差押え、仮処分、競売の申立て、租税滞納処分、その他公権力の処分を受けたとき、又はそれらのおそれがある場合（ただし、本規約の履行に重大な影響を与えない軽微なものは除く）
                          </p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-20">(8)</p>
                          <p>
                            監督官庁により事業停止処分、又は事業免許若しくは事業登録の取消処分を受けた場合
                          </p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-20">(9)</p>
                          <p>
                            その他、当行が、お客様の本サービスの利用、登録又は個別契約等の継続を適当でない、又は、当行が定める品質で本サービスを提供することが困難であると判断した場合
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">2.</p>
                          <p>
                            前項各号のいずれかの事由に該当した場合、お客様は、当行に対して負っている債務の一切について当然に期限の利益を失い、直ちに当行に対して全ての債務の支払いを行なわなければなりません。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">3.</p>
                          <p>
                            当行は、本条に基づき当行が行った行為によりお客様及びユーザーに生じた損害について一切の責任を負いません。
                          </p>
                        </div>
                        <br></br>
                        <p>
                          第17条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（解約）
                        </p>
                        <div className="tw-flex">
                          <p className="tw-mr-20">1.</p>
                          <p>
                            お客様は、別途定める当行所定の方法で当行に通知することにより、本規約及び個別契約等を解約し、自己のお客様としての登録を抹消できます。退会月の末日まで本サービスは利用可能です。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">2.</p>
                          <p>
                            解約にあたりお客様が当行に対して債務を負っている場合、お客様は、当行に対する債務の一切について当然に期限の利益を失い、直ちに当行に対する債務の全てについて履行しなければなりません。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">3.</p>
                          <p>
                            お客様の解約により、お客様・第三者に対して損害が発生した場合は、お客様が責任を負うものとします。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">4.</p>
                          <p>
                            お客様は、解約後に再登録手続きをした場合、解約前のデータが引き継がれない可能性があることについて、承諾します。
                          </p>
                        </div>
                        <br></br>
                        <p>
                          第18条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（契約終了後の効力）
                        </p>
                        <p>
                          本規約及び個別契約等が、期間満了若しくは解除等により終了した後においても、第12条（権利帰属）、第20条（損害賠償）、第21条（秘密保持）、第22条（反社会的勢力の排除）、第30条（準拠法及び管轄裁判所）及び本条の規定はなお有効とし、お客様及び当行は当該条項に基づく債務等を履行又は遵守します。
                        </p>
                        <br></br>
                        <p>
                          第19条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（お客様の責任及び保証の制限）
                        </p>
                        <div className="tw-flex">
                          <p className="tw-mr-20">1.</p>
                          <p>
                            お客様は、本サービスをお客様が利用するために必要な環境や設備（インターネット回線、パソコン等のハードウェア、Webブラウザ等のソフトウェア等を含みますがこれらに限りません。）を自己の責任と費用において適切に用意する必要があり、当行は、これらの用意に関する責任を負いません。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">2.</p>
                          <p>
                            お客様が本サービスの利用して行った行為は、全てお客様が責任を負うものとします。本サービスの不具合によって、お客様・第三者に生じた不利益・損害は当行に故意・重過失が無い場合は一切責任を負わないものとし、本サービスの利用により、第三者からクレームを受けた場合もお客様の責任・費用を持って処理及び解決するものとします。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">3.</p>
                          <p>
                            当行は本サービスの提供について、正確性・完全性・合目的性・確実性・安全性・適法性・可用性を実現できるようには努めますが、これについて如何なる保証も行わず、一切責任を負わないものとします。尚、本サービスにおける掲載情報は掲載した時点の情報であり、お客様の閲覧時においては古い情報が掲載されている場合があることについて、お客様は了承するものとします。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">4.</p>
                          <p>
                            当行は、登録データの消去、喪失等に関連してお客様が被った損害について、一切の責任を負いません。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">5.</p>
                          <p>
                            当行は、本サービスの利用不能若しくは本サービスの利用による機器の故障若しくは損傷によって生じた損害、又は第三者が当行ウェブサイト上で提供される情報を当行ウェブサイト以外のウェブサイト、文書等に転載したことにより発生した損害、その他の第三者の行為に起因して生じた損害について、一切の責任を負いません。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">6.</p>
                          <p>
                            本サービス又は当行ウェブサイトに関連してお客様と提携企業又は第三者との間において生じた取引、連絡若しくは紛争等について、当行は一切の責任を負いません。もしそれらに関連して当行に費用が発生した場合又は当行が賠償金の支払いを行なった場合には、お客様は当行に対して、当該費用（弁護士等の専門家の費用を含む）及び賠償金を補償するものとし、当行はお客様にこれらの合計額の支払いを請求できるものとします。
                          </p>
                        </div>
                        <br></br>
                        <p>
                          第20条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（損害賠償）
                        </p>
                        <div className="tw-flex">
                          <p className="tw-mr-20">1.</p>
                          <p>
                            当行は、本規約の違反に起因して、お客様が損害を被った時は、理由を問わず、当行は一切の責任を負いません。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">2.</p>
                          <p>
                            本サービスに掲載している情報を、不正アクセス等で第三者に取得され、お客様又は第三者が被った被害について、理由や情報取得の経路、経緯を問わず、当行は一切の責任を負いません。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">3.</p>
                          <p>
                            本規約の違反等、お客様の責で当行及び第三者に損害を与えた場合は、お客様が自己の費用と責任を持って解決するものとします。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">4.</p>
                          <p>
                            お客様が本規約に違反する行為又は不正若しくは違法な行為によって当行に損害を与えた場合、当行は当該お客様などに対してその損害の賠償を請求できるものとします。
                          </p>
                        </div>
                        <br></br>
                        <p>
                          第21条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（秘密保持）
                        </p>
                        <div className="tw-flex">
                          <p className="tw-mr-20">1.</p>
                          <p>
                            お客様は、当行に情報を開示する場合、自らの意思で開示したものとし、本サービスの利用により生ずるすべての結果について自ら責任を負うものとします。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">2.</p>
                          <p>
                            お客様及び当行は、本規約又は本サービスの利用に際して当行ウェブサイトを介して相手方から開示された情報（当行が本サービスにおいてお客様に提供する一切の情報、画像、データとこれらのデザイン、機能に関する情報を含みます。）、及び、開示に際して開示当事者が秘密であることを示して開示した情報について、相手方の事前の書面による承諾がある場合を除き、秘密情報として取り扱うものとし、そのいかなる部分も、開示当事者の事前の書面による承諾なく第三者に対して開示せず、かつ、本サービスの提供又は利用の目的以外で使用しないものとします。但し、第9条に基づく利用状況データ及びフィードバックに関する情報、第10条に基づく電力ガス等関連データ、並びに次の各号のいずれかに該当するものについては秘密情報には該当しないものとします。
                          </p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-20">(1)</p>
                          <p>開示を受けたときに既に保有していた情報</p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-20">(2)</p>
                          <p>
                            開示を受けた後、秘密保持義務を負うことなく第三者から正当に入手した情報
                          </p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-20">(3)</p>
                          <p>
                            開示を受けた後、相手方から開示を受けた情報に関係なく独自に取得し、又は創出した情報
                          </p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-20">(4)</p>
                          <p>開示を受けたときに既に公知であった情報</p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-20">(5)</p>
                          <p>
                            開示を受けた後、自己の責めに帰し得ない事由により公知となった情報
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">3.</p>
                          <p>
                            前項に拘らず、秘密情報の受領当事者は、本サービスの提供又は利用に際して秘密情報を知る必要のある自己の役職員、弁護士及びコンサルタント等の専門家、グループ会社、提携企業、並びに、当行については、第26条第1項に定める規程等に定める共同利用の相手方、第27条第2項に定める譲渡の譲受人、業務委託先、及び本ソフトウェア開発の委託先に対して秘密情報を開示することができるものとします。但し、秘密情報の受領当事者は、秘密情報の開示に先立ち、これらの開示を受ける者に対して、法令上の守秘義務を負う場合を除き、本規約に定めるのと同等の秘密保持義務を課すものとします。受領当事者は、これらの開示を受ける者の秘密保持義務違反及び守秘義務違反について一切の責任を負うものとします。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">4.</p>
                          <p>
                            本条第2項に拘らず、受領当事者が、法令若しくは金融商品取引所規則に基づいて秘密情報の開示が必要となる場合、又は裁判所、監督官庁等の行政機関等より秘密情報の開示を命じられた場合には、必要な限度で開示を行うことができるものとします。この場合、受領当事者は速やかに、法令上許容される限度で、開示が必要となり、又は開示命令を受けた旨を開示当事者に通知するものとします。
                          </p>
                        </div>
                        <br></br>
                        <p>
                          第22条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（反社会的勢力の排除）
                        </p>
                        <div className="tw-flex">
                          <p className="tw-mr-20">1.</p>
                          <p>
                            お客様及び当行は、相手方に対し、自己又は自己の役員若しくは自己の従業員が、現時点において、暴力団、暴力団員、暴力団員でなくなったときから5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動標榜ゴロ又は特殊知能暴力集団等、その他これらに準ずる者（以下これらを「暴力団員等」といいます。）に該当しないこと及び各号のいずれにも該当しないことを表明し、かつ将来にわたっても該当しないことを確約するものとします。
                          </p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-20">(1)</p>
                          <p>
                            暴力団員等が経営を支配している又は経営に実質的に関与していると認められる関係を有すること
                          </p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-20">(2)</p>
                          <p>
                            自己又は第三者の不正の利益を図る目的又は第三者に損害を加える目的をもってする等、不当に暴力団員等を利用していると認められる関係を有すること
                          </p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-20">(3)</p>
                          <p>
                            暴力団員等に対して資金等を提供し、又は便宜を供与する等の関与をしていると認められる関係を有すること
                          </p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-20">(4)</p>
                          <p>
                            役員又は経営に実質的に関与している者が暴力団員等と社会的に非難されるべき関係を有すること{' '}
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">2.</p>
                          <p>
                            お客様及び当行は、自ら又は第三者を利用して各号のいずれかに該当する行為を行わないことを確約します。
                          </p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-20">(1)</p>
                          <p>暴力的な要求行為</p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-20">(2)</p>
                          <p>法的な責任を超えた不当な要求行為</p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-20">(3)</p>
                          <p>
                            取引に関して、脅迫的な言動をし、又は暴力を用いる行為
                          </p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-20">(4)</p>
                          <p>
                            風説を流布し、偽計を用い又は威力を用いて相手方の信用を毀損し、又は相手方の業務を妨害する行為
                          </p>
                        </div>
                        <div className="tw-ml-[10px] tw-flex">
                          <p className="tw-mr-20">(5)</p>
                          <p>その他前各号に準ずる行為</p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">3.</p>
                          <p>
                            お客様及び当行は、相手方が前２項のいずれかに違反した場合は、本規約、個別契約等の全部又は一部につき相手方の有する期限の利益を損失させ、また、何らの催告をせず、本規約、個別契約等を解除することができるものとします。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">4.</p>
                          <p>
                            お客様及び当行は、前項に基づく解除により解除された当事者が被った損害につき、一切の義務及び責任を負わないものとします。
                          </p>
                        </div>
                        <br></br>
                        <p>
                          第23条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（本規約等の変更）
                        </p>
                        <div className="tw-flex">
                          <p className="tw-mr-20">1.</p>
                          <p>
                            当行は、お客様の一般の利益に適合する場合、又は社会情勢、経済事情若しくは本サービスに関する実情の変化若しくは法令の変更その他合理的な事由があると認められる場合には、民法の定型約款の変更に係る規定に基づき、本サービスの目的に反しない範囲で本規約の内容を変更できるものとします。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">2.</p>
                          <p>
                            当行は、前項の定めに基づいて本規約の変更を行う場合は、変更後の利用規約の内容を、当行ウェブサイト（お客様がID及びパスワードを入力した上で閲覧可能な本サービス用ウェブサイトを指します。リンク先及び閲覧方法については、別途当行より通知します。）上に表示し、又は当行の定める方法によりお客様に通知することでお客様に周知するものとし、その周知の際に定める相当な期間を経過した日から、変更後の利用規約が適用されるものとします。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">3.</p>
                          <p>
                            当行は、本条第1項の定めに基づかずに本規約の変更を行う場合、変更後の利用規約の内容についてお客様の同意を得るものとします。この場合も、当行は、変更後の利用規約の内容を前項の定めに従って周知するものとします。お客様は、かかる変更に不服がある場合、第17条第1項の定めに従い、本規約及び個別契約等を解約し、自己のお客様としての登録を抹消できます。かかる周知の後、当該変更後の利用規約が適用される日までの間に、お客様が本サービスを利用した場合又は解約の手続をとらなかった場合には、お客様は当該変更後の利用規約の内容に同意したものとみなします。
                          </p>
                        </div>
                        <br></br>
                        <p>
                          第24条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（連絡又は通知）
                        </p>
                        <div className="tw-flex">
                          <p className="tw-mr-20">1.</p>
                          <p>
                            お客様から当行に対する本サービスに関する問い合わせその他の連絡又は通知は、当行の定める方法で行うものとします。尚、当行はお客様からの問い合わせやその他連絡又は通知が、インターネット上の不具合等により、着信しなかった場合の責任は負わないものとします。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">2.</p>
                          <p>
                            当行からお客様に対する本サービスに関する連絡又は通知は、ユーザーに対して、登録事項に含まれるメールアドレスに電子メールを送る方法その他当行の定める方法（炭削くん内のお知らせ含む）によって行うものとします。当行がユーザーの登録事項に含まれるメールアドレスその他の連絡先に対して連絡又は通知を行った場合、お客様は当該連絡又は通知を受領し、理解したものとみなします。
                          </p>
                        </div>
                        <br></br>
                        <p>
                          第25条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（お客様への告知・アンケート等）
                        </p>
                        <div className="tw-flex">
                          <p className="tw-mr-20">1.</p>
                          <p>
                            当行は、お客様に対し、本サービスの利用条件等につき、必要に応じ随時当サイト上への掲示により告知を行うことができるものとします。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">2.</p>
                          <p>
                            当行は、お客様に対し、本サービスの運営にあたり、必要に応じ随時電子メール、ダイレクトメール・電話等により連絡および要請を行うことができるものとします。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">3.</p>
                          <p>
                            当行は、お客様に対し、今後のサービスの充実や検討にあたり、必要に応じ随時電子メール、ダイレクトメール・電話等によりアンケートのご協力要請を行うことができるものとします。
                          </p>
                        </div>
                        <br></br>
                        <p>
                          第26条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（個人情報の取扱い等）
                        </p>
                        <div className="tw-flex">
                          <p className="tw-mr-20">1.</p>
                          <p>
                            当行は、別途当行ウェブサイトに掲示する個人情報の取り扱いに関する規程（以下本条において「規程等」といいます。）に基づき、適切に取扱います。お客様は、当行が規程等に従って、個人情報の第三者への開示及び利用（共同利用を含みます。）を実施することに同意します。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">2.</p>
                          <p>
                            当行は、規程等に基づき、本サービス提供およびこれに付随する業務に必要は範囲で、個人情報の取り扱いを業務委託先に委託することができるものとします。この場合、委託先の選定及び監督に関して、当行は善管注意義務に従いこれをなすものとします。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">3.</p>
                          <p>
                            前項の規定に拘らず、当行は、お客様が当行に提供した情報、データ等を、個人を特定できない統計的な情報として、当行の裁量で、利用及び公開することができるものとし、お客様は当行の利用及び公開に対して異議を唱えません。
                          </p>
                        </div>
                        <br></br>
                        <p>
                          第27条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（本契約上の地位の譲渡等）
                        </p>
                        <div className="tw-flex">
                          <p className="tw-mr-20">1.</p>
                          <p>
                            お客様は、当行の書面による事前の承諾なく、本規約、本利用契約及び個別契約等上の地位又は本規約、本利用契約及び個別契約等に基づく権利若しくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分はできません。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">2.</p>
                          <p>
                            当行は、本サービスにかかる事業を他社に譲渡した場合には、当該譲渡に伴い本規約、本利用契約及び個別契約等上の地位、本規約、本利用契約及び個別契約等に基づく権利及び義務並びにお客様の登録事項その他の顧客情報を当該譲渡の譲受人に譲渡できるものとし、お客様は、かかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、事業会社設立、会社分割、合併、その他事業が移転するあらゆる場合を含むものとします。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">3.</p>
                          <p>
                            当行は、前項に基づき、本規約、本利用契約及び個別契約等上の地位、本規約、本利用契約及び個別契約等に基づく権利及び義務並びにお客様の登録事項その他の顧客情報を他社に譲渡する場合には、当行の定める方法により、当該他社の名称及び住所並びに当該譲渡を行う旨を当該譲渡前にお客様に通知します。
                          </p>
                        </div>
                        <br></br>
                        <p>
                          第28条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（分離可能性）
                        </p>
                        <div className="tw-flex">
                          <p className="tw-mr-20">1.</p>
                          <p>
                            本規約のいずれかの条項の全部又は一部が法令等により無効又は執行不能と判断された場合であっても、本規約における残りの条項、及び条項の一部が無効又は執行不能と判断された場合の当該条項の残りの部分は、継続して完全に効力を有するものとします。
                          </p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">2.</p>
                          <p>
                            本規約のいずれかの条項又はその一部が、あるお客様との関係で無効又は執行不能と判断された場合であっても、他のお客様との関係における有効性等には影響を及ぼさないものとします。
                          </p>
                        </div>
                        <br></br>
                        <p>
                          第29条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（完全合意条項）
                        </p>
                        <p>
                          本規約は、本規約の対象事項に関する当事者間の完全な合意を示すものであり、本規約締結までにお客様・当行間でなされた書面、口頭又は黙示的になされたあらゆる合意はその効力を失うものとします。
                        </p>
                        <br></br>
                        <p>
                          第30条&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（準拠法及び管轄裁判所）
                        </p>
                        <div className="tw-flex">
                          <p className="tw-mr-20">1.</p>
                          <p>本規約の準拠法は、日本法とします。</p>
                        </div>
                        <div className="tw-flex">
                          <p className="tw-mr-20">2.</p>
                          <p>
                            本規約に起因又は関連する一切の紛争については、当行の本店所在地を管轄する地方裁判所を第一審の専属的合意管轄裁判所とします。
                          </p>
                        </div>
                        <br></br>
                        <br></br>
                        <p>2024年1月29日制定</p>
                      </div>
                    </>
                  </div>
                </HgbResponsive>
                <a href={registerWatch.privacyPolicyUrl} target="__blank">
                  <p className="tw-text-primary-8">{A07.label.policy}</p>
                </a>
                <Checkbox
                  value={registerWatch.agreement}
                  checked={registerWatch.agreement}
                  onChange={(e) => setValue('agreement', e.target.checked)}
                >
                  <p>{A07.label.L2Agreement}</p>
                </Checkbox>
              </div>
              <div className="tw-flex tw-shrink-0 tw-gap-16 tw-self-center">
                <HgbAntdButton
                  className="tw-flex-shrink-0 !tw-bg-white"
                  type="primary"
                  htmlType="button"
                  ghost
                  onClick={() => setStep(1)}
                >
                  <>{common.button.return}</>
                </HgbAntdButton>
                <HgbAntdButton
                  className="tw-flex-shrink-0"
                  type="primary"
                  htmlType="submit"
                  disabled={!registerWatch.agreement}
                >
                  <>{common.button.next}</>
                </HgbAntdButton>
              </div>
            </>
          )}
          {step === 3 && (
            <>
              <HgbResponsive
                gap
                className="a07_3 tw-grid tw-w-full tw-flex-auto tw-grid-cols-1 tw-items-start tw-self-start tw-rounded-16 tw-bg-white tw-p-16 pc:tw-grid-cols-2 pc:tw-p-24"
              >
                <div className="tw-col-start-1">
                  <h4 className="tw-font-bold">{A07.label.bankName}</h4>
                  <h4>{registerWatch.bankName}</h4>
                </div>
                <div className="tw-col-start-1">
                  <h4 className="tw-font-bold">
                    {common.label.enterpriseName}
                  </h4>
                  <h4>{registerWatch.enterpriseName}</h4>
                </div>
                <div>
                  <h4 className="tw-font-bold">
                    {common.label.enterpriseNameKana}
                  </h4>
                  <h4>{registerWatch.enterpriseNameKana}</h4>
                </div>
                <div className="tw-col-start-1">
                  <h4 className="tw-font-bold">
                    {common.label.representative}
                  </h4>
                  <h4>{registerWatch.representative}</h4>
                </div>
                <div>
                  <h4 className="tw-font-bold">
                    {common.label.representativeKana}
                  </h4>
                  <h4>{registerWatch.representativeKana}</h4>
                </div>
                <div className="tw-col-start-1">
                  <h4 className="tw-font-bold">{common.label.postalCode}</h4>
                  <h4>{registerWatch.postalCode}</h4>
                </div>
                <div className="tw-col-start-1">
                  <h4 className="tw-font-bold">{common.label.fullAddress}</h4>
                  <h4>{registerWatch.address1}</h4>
                </div>
                <div>
                  <h4 className="tw-font-bold">{common.label.address2}</h4>
                  <h4>{registerWatch.address2}</h4>
                </div>
                <div>
                  <h4 className="tw-font-bold">{common.label.phoneNumber}</h4>
                  <h4>{registerWatch.phoneNumber}</h4>
                </div>
                <div>
                  <h4 className="tw-font-bold">
                    {common.label.startMonthOfYear}
                  </h4>
                  <h4>
                    {
                      MONTH_OF_YEAR_OPTIONS.find(
                        (s) => s.value === registerWatch.startMonthOfYear,
                      )?.label
                    }
                  </h4>
                </div>
                <div>
                  <h4 className="tw-font-bold">
                    {common.label.industryMajorCodeClassification}
                  </h4>
                  <h4>
                    {
                      codeMajorOptions.find(
                        (s) =>
                          s.value ===
                          registerWatch.industryCodeMajorClassification,
                      )?.label
                    }
                  </h4>
                </div>
                <div>
                  <h4 className="tw-font-bold">
                    {common.label.industryMediumCodeClassification}
                  </h4>
                  <h4>
                    {
                      codeMediumOptions.find(
                        (s) =>
                          s.value ===
                          registerWatch.industryCodeMediumClassification,
                      )?.label
                    }
                  </h4>
                </div>
                {registerWatch.subscriptionType !== 'COMPLIMENTARY' && (
                  <div>
                    <h4 className="tw-font-bold">
                      {common.label.paymentMethod}
                    </h4>
                    <h4>
                      {
                        paymentOption.find(
                          (s) => s.value === registerWatch.paymentType,
                        )?.label
                      }
                    </h4>
                  </div>
                )}
                <div>
                  <h4 className="tw-font-bold">
                    {common.label.isCorporateAccount}
                  </h4>
                  <h4>
                    {
                      haveNoneOption.find(
                        (s) => s.value === registerWatch.hasAccount,
                      )?.label
                    }
                  </h4>
                </div>

                {registerWatch.hasAccount === '1' && (
                  <div>
                    <h4 className="tw-font-bold">
                      {common.label.branchNumber}
                    </h4>
                    <h4>{registerWatch.branchNumber}</h4>
                  </div>
                )}
                {registerWatch.hasAccount === '1' && (
                  <div>
                    <h4 className="tw-font-bold">
                      {common.label.corporateAccountNumber}
                    </h4>
                    <h4>{registerWatch.corporateAccountNumber}</h4>
                  </div>
                )}
                <div>
                  <h4 className="tw-font-bold">{common.label.picName}</h4>
                  <h4>{registerWatch.picName}</h4>
                </div>
                <div>
                  <h4 className="tw-font-bold">{common.label.picNameKana}</h4>
                  <h4>{registerWatch.picNameKana}</h4>
                </div>

                <div>
                  <h4 className="tw-font-bold">{common.label.picEmail}</h4>
                  <h4>{registerWatch.picEmail}</h4>
                </div>
                <Checkbox
                  className="tw-col-start-1"
                  value={registerWatch.agreement}
                  checked={registerWatch.agreement}
                >
                  <p>{A07.label.L3Agreement}</p>
                </Checkbox>
              </HgbResponsive>
              <div className="tw-flex tw-shrink-0 tw-gap-16 tw-self-center">
                <HgbAntdButton
                  className="tw-flex-shrink-0 !tw-bg-white"
                  type="primary"
                  htmlType="button"
                  ghost
                  onClick={() => setStep(2)}
                >
                  <>{common.button.return}</>
                </HgbAntdButton>
                <HgbAntdButton
                  className="tw-flex-shrink-0"
                  type="primary"
                  htmlType="submit"
                  disabled={!registerWatch.agreement}
                >
                  <>{common.button.enterPayment}</>
                </HgbAntdButton>
              </div>
            </>
          )}
          {step === 4 && (
            <>
              <HgbResponsive
                gap
                className="tw-flex tw-w-360 tw-flex-auto tw-flex-col tw-items-center tw-gap-24 tw-self-center tw-rounded-16 tw-bg-white tw-p-16 pc:tw-w-440 pc:tw-p-24"
              >
                <h2 className="tw-text-[28px] tw-font-bold">{A08.title}</h2>
                <p className="tw-text-center">
                  {A08.subTitle(
                    toJPFormat(
                      paymentQuery?.result?.enterpriseMonthlyFree || 0,
                      0,
                      false,
                      'G01',
                    ),
                    paymentQuery?.result?.enterpriseTrialMonth,
                    registerWatch.subscriptionType,
                  )}
                </p>
                {registerWatch.subscriptionType !== 'COMPLIMENTARY' && (
                  <div className="tw-flex tw-flex-col tw-gap-24 tw-rounded-16 tw-bg-[#FFF4C9] tw-p-[10px]">
                    {A08.content(
                      toJPFormat(
                        paymentQuery?.result?.enterpriseMonthlyFree || 0,
                        0,
                        false,
                        'G01',
                      ),
                    )}
                  </div>
                )}
              </HgbResponsive>
              <div className="tw-flex tw-shrink-0 tw-gap-16 tw-self-center">
                <HgbAntdButton
                  className="tw-flex-shrink-0 !tw-bg-white"
                  type="primary"
                  htmlType="button"
                  ghost
                  onClick={() => {
                    setStep(3);
                    mutateCheckDuration({
                      registerId: registerId ?? '',
                    });
                  }}
                >
                  <>{common.button.return}</>
                </HgbAntdButton>
                {registerWatch.subscriptionType !== 'COMPLIMENTARY' ? (
                  <HgbAntdButton
                    className="tw-flex-shrink-0"
                    type="primary"
                    htmlType="button"
                    onClick={() => {
                      setIsLoading(true);
                      SpsvApi.spsvCardInfoScreen();
                    }}
                  >
                    <>{common.button.enterPayment}</>
                  </HgbAntdButton>
                ) : (
                  <HgbAntdButton
                    className="tw-flex-shrink-0"
                    type="primary"
                    htmlType="button"
                    onClick={() => {
                      handleSubmitBankComplimentary();
                    }}
                  >
                    <>{common.button.passedPayment}</>
                  </HgbAntdButton>
                )}
              </div>
              {registerWatch.subscriptionType !== 'COMPLIMENTARY' && (
                <p className="tw-self-center pc:tw-mb-24">{A08.notice}</p>
              )}
            </>
          )}
        </form>
      </FormProvider>
    </TemporaryTemplate>
  ) : (
    <div
      className={`tw-fixed tw-z-10 tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center`}
    >
      <svg
        aria-hidden="true"
        className="tw-h-80 tw-w-80 tw-animate-spin tw-fill-primary-5 tw-text-white"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
    </div>
  );
};

export default A07;
