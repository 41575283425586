import { AxiosResponseType } from '@hooks/useApi';
import { hgbAxios } from '@utils/axios';
import { LocalDataClass } from 'data-class/LocalDataClass';
import { API_URLS } from './API_URLS';

export const REACT_APP_ENV = (process.env.REACT_APP_ENV?.trim() || '') as
  | 'development'
  | 'staging'
  | 'production'
  | '';
export const PUBLIC_URL = process.env.PUBLIC_URL?.trim() || '';
export const REACT_APP_BASE_NAME =
  process.env.REACT_APP_BASE_NAME?.trim() || '';
export const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL?.trim() || '';
export const REACT_APP_UPLOAD_OCR_URL = process.env.REACT_APP_UPLOAD_OCR_URL?.trim() || '';
export const REACT_APP_E_SCOTT_URL =
  process.env.REACT_APP_E_SCOTT_URL?.trim() || '';

export const E_SCOTT_TOKEN = '8TRpzqXuK2xq7CnbireStH8jLK5bRkEc';
export const MERCHANT_PASS = 'R7cNTHYn';
export const MERCHANT_ID = '00013994';
export const KAIN_ID_ADD = '4MemAdd';
export const KAIN_ID_CHANGE = '4MemChg';

export const getMerchantId = async () => {
  if (REACT_APP_ENV === 'development') return MERCHANT_ID;
  const response = await hgbAxios().get<AxiosResponseType<any>>(
    `${API_URLS.MERCHANT_INFORMATION}?lang=${LocalDataClass.language}`,
  );
  return response?.data?.result?.merchantId || '';
  // need to test
};

export const getMerchantPass = async () => {
  if (REACT_APP_ENV === 'development') return MERCHANT_PASS;
  const response = await hgbAxios().get<AxiosResponseType<any>>(
    `${API_URLS.MERCHANT_INFORMATION}?lang=${LocalDataClass.language}`,
  );
  return response?.data?.result?.merchantPass || '';
  // need to test
};

export const getEscottToken = async () => {
  if (REACT_APP_ENV === 'development') return E_SCOTT_TOKEN;
  const response = await hgbAxios().get<AxiosResponseType<any>>(
    `${API_URLS.MERCHANT_INFORMATION}?lang=${LocalDataClass.language}`,
  );
  return response?.data?.result?.tokenNinsyoCode || '';
  // need to test
};

export const LANG_KEY = 'i18nextLng';
export const STATUS_CODE = {
  mustChangePassword: 10003,
  shouldChangePassword: 10015,
  tokenExpired: 10110,
  invalidToken: 10109,
  success: 10000,
  notFound: 10104,
  registerSuccess: 10011,
  deleteSuccess: 10012,
  updateSuccess: 10013,
  productNotExit: 10244,
  orgBaseNotExit: 10246,
  orgBaseAllocation: 10251,
  dataNotExit: 10241,
  dataNotExitEmission: 10256,
  scopeEmissionsChanged: 10250
};

export const ROLES = {
  ROLE_PRESIDING_BANK: 'ROLE_PRESIDING_BANK',
  ROLE_MANAGEMENT_BANK: 'ROLE_MANAGEMENT_BANK',
  ROLE_ENTERPRISE_ADMIN: 'ROLE_ENTERPRISE_ADMIN',
  ROLE_ENTERPRISE_USER: 'ROLE_ENTERPRISE_USER',
} as const;

export type Role = keyof typeof ROLES;

export const SESSION_TIME = 3600000;

export const USER_LOCAL_STORAGE_NAME: `${'USER_LOCAL'}${string}` = `${'USER_LOCAL'}${REACT_APP_ENV}`;

export const MY_SECRET_KEY = 'HIGOBANK_2023';

export const paymentError = {
  OK: `ProcessId/ProcessPass が発行されます。`,
  K01: `当該 OperateId の設定値を網羅しておりません。（送信項目不足、または項目エラー）設定値をご確認の上、再処理を行ってください。`,
  K02: `システムにエラーが発生しております。カードでは取引をする事ができません。`,
  K03: `システムにエラーが発生しております。カードでは取引をする事ができません。`,
  K04: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K05: `システムにエラーが発生しております。カードでは取引をする事ができません。`,
  K06: `システムにエラーが発生しております。カードでは取引をする事ができません。`,
  K07: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K08: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K09: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K10: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K11: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K12: `Master  電文で発行された「ProcessId」または「ProcessPass」では無いことを意味します。
設定値をご確認の上、再処理を行ってください。`,
  K14: `要求された Process 電文の「OperateId」が要求対象外です。
例：「1Delete：取消」に対して再度「1Delete：取消」を送信したなど。`,
  K15: `返戻対象となる会員の数が、最大件数（30 件）を超えました。`,
  K20: `システムにエラーが発生しております。カードでは取引をする事ができません。`,
  K21: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K22: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K24: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K25: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K26: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K27: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K28: `半角数字 4 桁の設定値であることをご確認の上、再処理を行ってください。`,
  K30: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K31: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K32: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K33: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K34: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K35: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K36: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K37: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K39: `YYYYMMDD 形式では無い、または未来日付であることを意味します。設定値をご確
認の上、再処理を行ってください。`,
  K40: `取引は OK 判定でしたが McSecCd が「１」アンマッチです。
※認証アシストマッチング検証利用時のみ応答します。`,
  K41: `取引は OK 判定でしたが McBirthDay が「１」アンマッチです。
※認証アシストマッチング検証利用時のみ応答します。`,
  K42: `取引は OK 判定でしたが McTelNo が「１」アンマッチです。
※認証アシストマッチング検証利用時のみ応答します。`,
  K43: `取引は OK 判定でしたが McKanaSei が「１」アンマッチです。
※認証アシストマッチング検証利用時のみ応答します。`,
  K44: `取引は OK 判定でしたが McKanaMei が「１」アンマッチです。
※認証アシストマッチング検証利用時のみ応答します。`,
  K45: `システムにエラーが発生しております。カードでは取引をする事ができません。`,
  K46: `システムにエラーが発生しております。カードでは取引をする事ができません。`,
  K47: `システムにエラーが発生しております。カードでは取引をする事ができません。`,
  K48: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K62: `形式エラーです。設定値をご確認の上、再処理を行ってください。
※画面アップロード、ファイル伝送時のみ応答されます。`,
  K63: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K64: `形式エラーです。設定値をご確認の上、再処理を行ってください。
※画面アップロード、ファイル伝送時のみ応答されます。`,
  K65: `形式エラーです。設定値をご確認の上、再処理を行ってください。
※画面アップロード、ファイル伝送時のみ応答されます。`,
  K66: `形式エラーです。設定値をご確認の上、再処理を行ってください。
※画面アップロード、ファイル伝送時のみ応答されます。`,
  K67: `形式エラーです。設定値をご確認の上、再処理を行ってください。
※カード決済  Master  データファイル大量データ処理をご利用の場合のみ応答されます。`,
  K68: `会員登録機能が未設定となっております。`,
  K69: `この会員 ID はすでに使用されています。`,
  K70: `会員削除電文に対して会員が無効状態ではありません`,
  K71: `システムにエラーが発生しております。カードでは取引をする事ができません。`,
  K73: `会員無効解除電文に対して会員が既に有効となっています`,
  K74: `会員認証に連続して失敗し、ロックアウトされました。`,
  K75: `会員は有効ではありません。`,
  K79: `現在はカード無効、Login回数無効または会員無効状態です。`,
  K80: `Master 電文は会員 ID が設定されています。Process 電文も会員 ID を設定してください。`,
  K81: `Master 電文は会員 ID が未設定です。Process 電文の会員 ID も未設定としてください。`,
  K82: `カード番号がセットされておりません。`,
  K83: `カード有効期限がセットされておりません。`,
  K84: `会員 ID が適切ではありません。`,
  K85: `会員パスワードが適切ではありません。`,
  K86: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K87: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  K88: `取引の対象が複数件存在します。弊社までお問い合わせください。`,
  K89: `この処理番号は既に使用されています。`,
  K95: `ファイルフォーマットが異なります。
※ファイル伝送時のみ応答されます。`,
  K96: `障害報が通知されている場合は、回復報を待って再処理を行ってください。
その他は、弊社までお問い合わせください。`,
  K98: `障害報が通知されている場合は、回復報を待って再処理を行ってください。
その他は、弊社までお問い合わせください。`,
  K99: `弊社までお問い合わせください。`,
  KBX: `重複した電文を受信しました。
利用者によりブラウザバックや二重押下が行われた可能性があります。`,
  KBY: `元取引が完了していません。取引状態をご確認ください。`,
  KBZ: `元取引が存在しません。取引状態をご確認ください。`,
  KE0: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  KE1: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  KE2: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  KE3: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  KE4: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  KE5: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  KE6: `形式エラーです。設定値をご確認の上、再処理を行ってください。`,
  KEA: `取引は OK 判定でしたが McSex が「１」アンマッチです。
※認証アシストマッチング検証利用時のみ応答します。`,
  KEB: `取引は OK 判定でしたが McBirthYear が「１」アンマッチです。
※認証アシストマッチング検証利用時のみ応答します。`,
  KEC: `取引は OK 判定でしたが McPostal が「１」アンマッチです。
※認証アシストマッチング検証利用時のみ応答します。`,
  KED: `取引は OK 判定でしたが McAcntNo1 が「１」アンマッチです。
※認証アシストマッチング検証利用時のみ応答します。`,
  KEE: `取引は OK 判定でしたが McAcntNo2 が「１」アンマッチです。
※認証アシストマッチング検証利用時のみ応答します。`,
  KEF: `取引は OK 判定でしたが McCardMei が「１」アンマッチです。
※認証アシストマッチング検証利用時のみ応答します。`,
  KG8: `マーチャント  ID、マーチャントパスワード認証に連続して失敗し、ロックアウトされました。`,
  KGH: `会員参照の利用は制限されています。`,
  KHW: `セッションタイムアウトが発生しました。`,
  KHX: `形式エラー。設定値を確認の上、再処理を行ってください。※トークン取引で使用`,
  KHZ: `利用可能なトークンがありません。※トークン取引で使用`,
  KI1: `形式エラー。設定値を確認の上、再処理を行ってください。※トークン取引で使用`,
  KI2: `すでに利用されたトークンです。※トークン取引で使用`,
  KI3: `トークンの有効期限が切れています。※トークン取引で使用`,
  KI4: `形式エラー。設定値を確認の上、再処理を行ってください。※トークン取引で使用`,
  KI5: `同一カード番号の連続入力によりロックされています。※トークン取引で使用`,
  KI6: `同一端末からの連続入力により端末がロックされています。※トークン取引で使用`,
  KI8: `取引の対象が複数件存在します。※トークン取引で使用`,
  KI9: `形式エラー。設定値を確認の上、再処理を行ってください。`,
  KIA: `形式エラー。設定値を確認の上、再処理を行ってください。`,
  KIB: `形式エラー。設定値を確認の上、再処理を行ってください。`,
  KIE: `形式エラー。設定値を確認の上、再処理を行ってください。`,
  KIF: `オブジェクト識別子が含まれていません。設定値を確認の上、再処理を行ってください。`,
  KIH: `署名時間と取引日時の差が60秒を超えています。（攻撃の可能性があります）※テスト環境では発生しません。`,
  KIJ: `トークンとプロセスIDが設定されています、いずれかを設定ください。`,
  KIK: `元取引がApplePay取引ではありません。ApplePay会員登録・会員更新時にクレジットカード決済のプロセスIDで更新した場合に発生いたします。`,
  KIM: `取引の対象が複数件存在します。※トークン取引で使用`,
  KIN: `売上障害取消時に内部で既に取り消された場合に発生いたします。`,
  KIR: `形式エラー。設定値を確認の上、再処理を行ってください。`,
  KIS: `オーソリ・会員同時登録は出来ません。`,
  KIW: `形式エラー。設定値を確認の上、再処理を行ってください。`,
  KIU: `会員IDの連続入力によりロックされています。`,
  C01: `システムエラーが発生しました。お手数ですが、炭削くん事務局までお問い合わせください。`,
  C02: `システムエラーが発生しました。お手数ですが、炭削くん事務局までお問い合わせください。`,
  C03: `システムエラーが発生しました。お手数ですが、炭削くん事務局までお問い合わせください。`,
  C10: `ご契約のある支払回数(区分）をセットし再処理を行ってください。`,
  C11: `ボーナス払いご利用対象外期間のため、支払区分を変更して再処理を行
ってください。`,
  C12: `ご契約のある分割回数(区分）をセットし再処理を行ってください。`,
  C13: `カード有効期限の年月入力相違、または、有効期限切れカードです。`,
  C14: `取消処理が既に行われております。管理画面で処理状況をご確認ください。`,
  C15: `ボーナス払いの下限金額未満によるエラーのため、支払方法を変更して再
処理を行ってください。`,
  C16: `カード番号が正しくありません。`,
  C17: `カード番号体系が正しくありません。`,
  C18: `オーソリ除外となるカード番号。本エラーを発生するには個別に設定が必
要になります。弊社までお問い合わせください。`,
  C70: `システムエラーが発生しました。お手数ですが、炭削くん事務局までお問い合わせください。`,
  C71: `貴社送信内容が仕様に沿っているかご確認の上、弊社までお問い合わせ
ください。`,
  C80: `システムエラーが発生しました。お手数ですが、炭削くん事務局までお問い合わせください。`,
  C82: `カード会社システムの停止を意味します。`,
  C98: `システムエラーが発生しました。お手数ですが、炭削くん事務局までお問い合わせください。`,
  C99: `貴社送信内容が仕様に沿っているかご確認の上、弊社までお問い合わせ
ください。`,
  G12: `このカードでは取引をする事ができません。`,
  G22: `このカードでは取引をする事ができません。`,
  G30: `取引中にエラーが発生しております。後ほどお試しください。`,
  G42: `暗証番号が正しくありません。
※デビットカードの場合、発生する場合があります。`,
  G44: `入力されたセキュリティコードが正しくありません。`,
  G45: `セキュリティコードが入力されていません。`,
  G54: `1 日利用回数または金額オーバーです。`,
  G55: `1日利用回数または金額オーバーです。`,
  G56: `クレジットカードが無効です。`,
  G60: `クレジットカードが無効です。`,
  G61: `無効カードが入力されました。有効カードでお試しください。`,
  G65: `カード番号が正しくありません。`,
  G68: `金額が正しくありません。`,
  G72: `ボーナス金額が正しくありません。`,
  G74: `分割回数の入力が正しくありません。`,
  G75: `分割払いの下限金額を下回っています。`,
  G78: `支払方法の入力が正しくありません。`,
  G83: `有効期限の入力が正しくありません。`,
  G84: `承認番号の入力が正しくありません。`,
  G85: `このカードでは取引をする事ができません。`,
  G92: `このカードでは取引をする事ができません。`,
  G94: `このカードでは取引をする事ができません。`,
  G95: `このカードでは取引をする事ができません。`,
  G96: `このカードでは取引をする事ができません。`,
  G97: `このカードでは取引をする事ができません。`,
  G98: `このカードでは取引をする事ができません。`,
  G99: `このカードでは取引をする事ができません。`,
  U01: `エンドユーザーの3Dセキュアパスワードが未設定です。`,
  U02: `3Dセキュア未対応カード発行会社です。`,
  U03: `取引は正常終了しましたが、ネットワーク等でエラーが発生し3D認証ができませんでした。`,
  U04: `3Dセキュア認証ができませんでした。各カード会社の基準により3Dセキュア認証画面を表示せずにNGとなる場合がございます。`,
  U05: `認証システムで改ざんをチェックし3Dセキュア認証ができませんでした。`,
  U06: `「登録確認」と「認証結果確認」との間が40分以上経過しています。`,
  U07: `認証システムにて電文処理中に同じ電文を受信しました。`,
  U08: `退避したセッション情報が削除されていた`,
  U09: `3Dセキュア未対応ブランドです。`,
  U10: `ブランドのサーバーが停止、または接続不可により3Dセキュア認証ができませんでした。`,
  U11: `必須項目のインターフェイスが無い、カード番号所定の桁数範囲外など`,
  U12: `弊社までお問い合せください。`,
  U13: `弊社までお問い合せください。`,
  U14: `計画停止案内が通知されている場合は、計画停止終了を待って再処理を行ってください。その他は、弊社までお問い合わせください。`,
  U95: `弊社システム内部エラー。`,
  U96: `障害報が通知されている場合は、回復報を待って再処理を行ってください。その他は弊社までお問い合せください。`,
  U99: `弊社までお問い合せください。`,
  K23: `半角数字ではないことまたは、利用額変更で元取引と利用金額が同一となっていることを意味します。8 桁以下(0  以外)の半角数字であること、利用額変更で元取引と
利用金額が同一でないことをご確認の上、再処理を行ってください。`,
  KIL: `元取引が通常取引ではありません。クレジットカード決済の会員登録・会員更新時にApple Pay決済のプロセスIDで更新した場合、または、Apple Pay会員に対して、会員更新時にカード番号、カード有効期限を設定して更新した場合に発生いたします。`,
};

export type PaymentErrorType = keyof typeof paymentError;
