import { AuthenTemplate } from '@layouts/templates';
import * as React from 'react';

interface IMaintenanceProps {}

const Maintenance: React.FunctionComponent<IMaintenanceProps> = (props) => {
  return (
    <AuthenTemplate>
      <br />
      <p className="tw-text-center tw-font-bold">
        【10/1 (火)】システム改修に伴うサービス停止のお知らせ
      </p>
      <br />
      <p>
        平素よりZero-Carbon-System（炭削くん）をご利用いただき、誠にありがとうございます。
      </p>
      <br />
      <p>
        このたび、以下の日程でシステム改修に伴うメンテナンス作業を実施いたします。
      </p>
      <p>
        これに伴い、当該時間帯は本サービスをご利用いただくことができませんので
      </p>
      <p>あらかじめご了承くださいますようお願い申し上げます。</p>
      <br />
      <p>
        <b>【メンテナンス日時】 2024年10月1日（火）12:00～14:00</b>
      </p>
      <p>※作業状況により終了時刻は前後する場合がございます。</p>
    </AuthenTemplate>
  );
};

export default Maintenance;
