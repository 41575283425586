import { SaveOutlined } from '@ant-design/icons';
import { HgbAntdButton } from '@common/antd/HgbAntdButton';
import { HgbAntdModal } from '@common/antd/HgbAntdModal';
import { HgbAntdRadioGroup } from '@common/antd/HgbAntdRadioGroup';
import { HgbResponsive } from '@common/components';
import {
  HgbAntdInput,
  HgbDatePicker,
  HgbSelect,
  parseScopeCategoryToHgbSelectOptions,
  parseToHgbSelectOptions,
} from '@common/forms';
import { API_URLS } from '@constants/API_URLS';
import { routs_en } from '@constants/ROUT';
import {
  KAIN_ID_ADD,
  KAIN_ID_CHANGE,
  PaymentErrorType,
  REACT_APP_E_SCOTT_URL,
  getEscottToken,
  getMerchantId,
  getMerchantPass,
  paymentError,
} from '@constants/consts';
import { MONTH_OF_YEAR_OPTIONS } from '@constants/options';
import { DATE_REGEX, EMAIL_REGEX } from '@constants/regex';
import { AuthContext } from '@contexts/AuthContext';
import { LanguageContext } from '@contexts/LanguageContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAddress } from '@hooks/useAddress';
import { DataTableTemplate } from '@layouts/templates';
import { ManagementTemplate } from '@layouts/templates/ManagementTemplate';
import {
  createMajorOptions,
  createMediumOptions,
} from '@utils/mapFixedDataD01';
import { LocalDataClass } from 'data-class/LocalDataClass';
import dayjs from 'dayjs';
import { isEmpty, isEqual } from 'lodash';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import {
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  useHgbMutationPost,
  useHgbMutationPut,
} from 'services/common/mutation';
import { useHgbQuery } from 'services/common/query';
import {
  CheckValidCardRequestType,
  PaymentTypeRequest,
  PaymentTypeResponse,
  PaymentTypeResponseA07,
} from 'services/types/A07';
import {
  GetCategoryListBoxRequest,
  GetCategoryListBoxResponse,
} from 'services/types/category';
import {
  ENTERPRISE_FORM_DEFAULT_VALUE,
  EmailByPicIDRequest,
  EmailByPicIDResponse,
  Enterprise,
  GetEnterpriseByIdRequest,
  GetEnterpriseByIdResponse,
  PicIDListboxResponse,
  UpdateCardRequest,
  UpdateEnterpriseRequest,
  UpdateEnterpriseResponse,
} from 'services/types/enterprise';
import * as yup from 'yup';
import { codeMajors, codeMediums } from './data';
declare var SpsvApi: any;

type CardType = {
  card?: string;
  token?: string;
};

export const EnterpriseAdmin: FC = () => {
  const {
    text: { D01, common, E0000 },
    language,
  } = useContext(LanguageContext)!;
  const { user } = useContext(AuthContext)!;
  const navigate = useNavigate();

  const enterpriseSchema = yup.object({
    enterpriseName: yup.string().required(E0000(common.label.enterpriseName)),
    enterpriseNameKana: yup
      .string()
      .required(E0000(common.label.enterpriseNameKana)),
    representative: yup.string().required(E0000(common.label.representative)),
    representativeKana: yup
      .string()
      .required(E0000(common.label.representativeKana)),
    postalCode: yup
      .string()
      .required(E0000(common.label.postalCode))
      .length(7, D01.message.postalCodeFormat),
    address1: yup.string().required(E0000(common.label.fullAddress)),
    startMonthOfYear: yup
      .string()
      .required(E0000(common.label.startMonthOfYear)),
    billingStartDate: yup
      .string()
      .required(E0000(common.label.billingDate))
      .when({
        is: () => user.subscriptionType !== 'COMPLIMENTARY',
        then: (schema) =>
          schema.matches(DATE_REGEX, E0000(common.label.billingDate)),
        otherwise: (schema) => {
          return schema.notRequired();
        },
      }),
    availableScope3CategoryArray: yup
      .array()
      .min(1, E0000(D01.label.scope3DirectInput))
      .required(E0000(D01.label.scope3DirectInput))
      .typeError(E0000(D01.label.scope3DirectInput)),
    paymentType: yup.string().when({
      is: () => user.subscriptionType !== 'COMPLIMENTARY',
      then: (schema) => schema.required(E0000(common.label.paymentMethod)),
      otherwise: (schema) => {
        return schema.notRequired();
      },
    }),
    hasAccount: yup.string().required(E0000(common.label.isCorporateAccount)),
    branchNumber: yup.string().when({
      is: () => enterprisePayload.hasAccount === '1',
      then: (schema) => {
        return schema
          .required(E0000(common.label.branchNumber))
          .length(3, D01.message.branchNumberFormat);
      },
    }),
    corporateAccountNumber: yup.string().when({
      is: () => enterprisePayload.hasAccount === '1',
      then: (schema) => {
        return schema
          .required(E0000(common.label.corporateAccountNumber))
          .length(7, D01.message.corporateAccountNumberFormat);
      },
    }),
    picName: yup.string().when({
      is: () => enterprisePayload.id?.toString() === '',
      then: (schema) => {
        return schema.required(E0000(common.label.picName));
      },
      otherwise: (schema) => {
        return schema.notRequired();
      },
    }),

    picNameKana: yup.string().when({
      is: () => enterprisePayload.id?.toString() === '',
      then: (schema) => {
        return schema.required(E0000(common.label.picNameKana));
      },
      otherwise: (schema) => {
        return schema.notRequired();
      },
    }),
    // picName: yup.string().required(D01.message.picName),
    // picNameKana: yup.string().required(D01.message.picNameKana),
    picEmail: yup
      .string()
      .required(E0000(common.label.picEmail))
      .matches(EMAIL_REGEX, common.message.emailFormat),
    industryCodeMajorClassification: yup
      .string()
      .required(E0000(common.label.industryMajorCodeClassification)),
    industryCodeMediumClassification: yup
      .string()
      .required(E0000(common.label.industryMediumCodeClassification)),
  });

  const updateMethods = useForm<Enterprise>({
    values: ENTERPRISE_FORM_DEFAULT_VALUE,
    resolver: yupResolver(enterpriseSchema),
  });

  const {
    register,
    handleSubmit: handleSubmitUpdate,
    watch: watchUpdate,
    reset: resetUpdate,
    setValue: setValueUpdate,
    setError,
    clearErrors,
    getValues: getValueUpdate,
  } = updateMethods;
  const enterprisePayload = watchUpdate();

  const [loadedEnterprise, setLoadedEnterprise] =
    useState<GetEnterpriseByIdResponse>();

  const { mutate: updateEnterpriseMutate } = useHgbMutationPut<
    UpdateEnterpriseResponse,
    UpdateEnterpriseRequest
  >(API_URLS.ENTERPRISE_API_URL, {
    onSuccess: () => {
      setUpdateModal(false);
    },
  });

  useHgbQuery<GetEnterpriseByIdResponse, GetEnterpriseByIdRequest>(
    API_URLS.ENTERPRISE_API_URL + `/${user.enterpriseId}`,
    undefined,
    {
      queryKey: [user.enterpriseId],
      onSuccess: (data) => {
        LocalDataClass.user = {
          ...user,
          departmentName: data.result?.enterpriseName ?? '',
        };
        // setUser({ ...user, departmentName: data.result?.enterpriseName ?? '' });
        resetUpdate({
          ...data?.result,
          availableScope3CategoryArray:
            enterprisePayload?.availableScope3Category?.length > 0
              ? enterprisePayload?.availableScope3Category?.split(',')
              : [],
        });
        setLoadedEnterprise({
          ...data?.result,
          availableScope3CategoryArray:
            enterprisePayload?.availableScope3Category?.length > 0
              ? enterprisePayload?.availableScope3Category?.split(',')
              : [],
        });
      },
    },
  );

  const { data: categoryLBQuery } = useHgbQuery<
    GetCategoryListBoxResponse,
    GetCategoryListBoxRequest
  >(
    API_URLS.CATEGORY_LIST_BOX_API_URL,
    { scopeCode: '3' },
    {
      enabled: true,
      queryKey: [language],
    },
  );

  const categoryCodeOptions = useMemo(() => {
    const rs = parseScopeCategoryToHgbSelectOptions(categoryLBQuery?.result);
    return rs;
  }, [categoryLBQuery?.result]);

  useEffect(() => {
    setValueUpdate(
      'availableScope3CategoryArray',
      enterprisePayload?.availableScope3Category?.length > 0
        ? enterprisePayload?.availableScope3Category?.split(',')
        : [],
    );
  }, [categoryCodeOptions, enterprisePayload?.availableScope3Category]);

  const [edited, setEdited] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [updateCreditCardSuccessedModal, setUpdateCreditCardSuccessedModal] =
    useState(false);
  const [addressFromPostalCode] = useAddress(enterprisePayload.postalCode);

  const onError: SubmitErrorHandler<Enterprise> = (err) => {
    if (!getValueUpdate('branchNumber')) {
      setValueUpdate('branchNumber', '');
      clearErrors('branchNumber');
    }

    if (!getValueUpdate('corporateAccountNumber')) {
      setValueUpdate('corporateAccountNumber', '');
      clearErrors('corporateAccountNumber');
    }

    if (!getValueUpdate('picName')) {
      setValueUpdate('picName', '');
      clearErrors('picName');
    }

    if (!getValueUpdate('picNameKana')) {
      setValueUpdate('picNameKana', '');
      clearErrors('picNameKana');
    }

    if (addressFromPostalCode.error) {
      setError('postalCode', {
        message: D01.message.postalCodeFormat,
      });
      return;
    }
  };

  const submitEnterprise = (payload: Enterprise) => {
    let passedPayload: Enterprise = { ...payload };
    if (addressFromPostalCode.error) {
      setError('postalCode', {
        message: D01.message.postalCodeFormat,
      });
      return;
    }

    if (payload.hasAccount === '2') {
      passedPayload.branchNumber = '';
      passedPayload.corporateAccountNumber = '';
    }
    passedPayload = {
      ...passedPayload,
      availableScope3Category:
        passedPayload.availableScope3CategoryArray?.length > 0
          ? passedPayload.availableScope3CategoryArray.join(',')
          : '',
    };
    updateEnterpriseMutate(passedPayload);
  };

  const onSubmit: SubmitHandler<Enterprise> = (payload) => {
    if (showUpdateConfirm()) {
      setUpdateModal(true);
      return;
    }
    submitEnterprise(payload);
  };

  useEffect(() => {
    if (edited) {
      setValueUpdate('address1', addressFromPostalCode.arch.full);
      if (addressFromPostalCode.error) {
        setError('postalCode', { message: D01.message.postalCodeFormat });
      } else {
        clearErrors('postalCode');
        clearErrors('address1');
      }
    }
  }, [addressFromPostalCode]);

  const codeMajorOptions = createMajorOptions(codeMajors);

  const [isEditedMajor, setIsEditedMajor] = useState(false);
  const codeMediumOptions = useMemo(() => {
    const rs = createMediumOptions(
      codeMediums,
      enterprisePayload.industryCodeMajorClassification,
    );
    if (isEditedMajor) {
      setValueUpdate('industryCodeMediumClassification', rs?.[0]?.value);
    } else {
      setValueUpdate(
        'industryCodeMediumClassification',
        enterprisePayload.industryCodeMediumClassification,
      );
    }
    return rs;
  }, [
    enterprisePayload.industryCodeMajorClassification,
    codeMediums,
    isEditedMajor,
  ]);

  const { data: paymentQuery } = useHgbQuery<
    PaymentTypeResponseA07,
    PaymentTypeRequest
  >(
    API_URLS.PAYMENT_TYPE_LISTBOX,
    {
      enterpriseId: enterprisePayload?.id,
    },
    {
      queryKey: [API_URLS.PAYMENT_TYPE_LISTBOX, enterprisePayload?.id],
    },
  );

  const paymentOption = useMemo(
    () => parseToHgbSelectOptions(paymentQuery?.result?.methodType),
    [paymentQuery?.result],
  );

  useEffect(() => {
    setValueUpdate(
      'paymentType',
      (enterprisePayload?.paymentType ?? '').toString(),
    );
  }, [enterprisePayload.paymentType, enterprisePayload.id, paymentOption]);

  const { data: haveNoneQuery } = useHgbQuery<PaymentTypeResponse, undefined>(
    API_URLS.HAVE_NONE_LISTBOX,
    undefined,
    {
      queryKey: [API_URLS.HAVE_NONE_LISTBOX, language],
    },
  );

  const haveNoneOption = useMemo(
    () => parseToHgbSelectOptions(haveNoneQuery?.result),
    [haveNoneQuery?.result],
  );

  useEffect(() => {
    setValueUpdate('hasAccount', enterprisePayload?.hasAccount);
  }, [enterprisePayload?.hasAccount]);

  const { data: picIdQuery } = useHgbQuery<PicIDListboxResponse, undefined>(
    API_URLS.PICID_LISTBOX_API_URL,
    undefined,
    {
      enabled: !!enterprisePayload.id,
      queryKey: [
        API_URLS.PICID_LISTBOX_API_URL,
        enterprisePayload.groupStatus,
        enterprisePayload.id,
      ],
    },
  );

  const picIdOption = useMemo(
    () => parseToHgbSelectOptions(picIdQuery?.result),
    [picIdQuery?.result],
  );

  const { data: emailQuery, refetch: emailRefetch } = useHgbQuery<
    EmailByPicIDResponse,
    EmailByPicIDRequest
  >(
    API_URLS.PICEMAIL_API_URL,
    {
      picId: enterprisePayload.picId,
      enterpriseId: undefined,
    },
    {
      enabled: !!enterprisePayload.picId,
      queryKey: [API_URLS.PICEMAIL_API_URL],
    },
  );

  useEffect(() => {
    setValueUpdate('picId', (enterprisePayload?.picId ?? '').toString());
  }, [enterprisePayload.picId]);

  useEffect(() => {
    if (!enterprisePayload.picId) return;
    emailRefetch();
  }, [enterprisePayload.picId]);

  useEffect(() => {
    setValueUpdate('picEmail', emailQuery?.result ?? '');
  }, [emailQuery]);

  const showUpdateConfirm = () => {
    for (let key in loadedEnterprise) {
      if (
        !isEqual(
          loadedEnterprise[key as keyof GetEnterpriseByIdResponse],
          enterprisePayload[key as keyof GetEnterpriseByIdResponse],
        )
      ) {
        return true;
      }
    }
    return false;
  };

  const { mutate: updateCardMutate } = useHgbMutationPut<
    undefined,
    UpdateCardRequest
  >(API_URLS.UPDATE_CARD_API_URL, {
    isAlert: false,
    onSuccess: () => {
      const sonyFrame = document.getElementById('SPSVviewIframe');
      const htmlEl = document.querySelector('html');
      if (htmlEl?.style) {
        htmlEl.style.overflow = 'unset';
      }
      sonyFrame?.remove();
      setUpdateCreditCardSuccessedModal(true);
    },
  });

  const { mutate: mutateCheckValidCard } = useHgbMutationPost<
    string,
    CheckValidCardRequestType
  >(API_URLS.FOUR_MEM_ADD_API_URL, {
    isAlert: false,
    onSuccess(data) {
      const responseParams = new URLSearchParams(data?.result);
      const status = responseParams.get(
        'ResponseCd',
      ) as PaymentErrorType | null;
      const statusMsg = status ? paymentError[status] : '';
      setIsLoading(false);
      if (status === 'OK') {
        updateCardMutate({
          id: enterprisePayload.id,
          maskedCardNumber: card.card ?? '',
          email: enterprisePayload.picEmail,
          kaninId: KaiinId,
          kaninPass: KaiinPass,
        });
      } else {
        return navigate(routs_en['/temporarily-register/fail'].link, {
          state: statusMsg + ';/temporarily-register/' + 'enterpriseAdmin',
        });
      }
    },
  });

  const [card, setCard] = useState<CardType>({
    card: '',
    token: '',
  });

  const KaiinId = useMemo(() => {
    return (
      user.bankCode +
      enterprisePayload.enterpriseCode +
      dayjs(new Date()).format('YYMMDDHHmmss')
    );
  }, [enterprisePayload]);

  const KaiinPass = Math.floor(Math.random() * 100000000) + '';
  const updateCardMutation = useHgbMutationPut<undefined, UpdateCardRequest>(
    API_URLS.UPDATE_CARD_API_URL,
    {
      isAlert: false,
      onSuccess: () => {
        const sonyFrame = document.getElementById('SPSVviewIframe');
        const htmlEl = document.querySelector('html');
        if (htmlEl?.style) {
          htmlEl.style.overflow = 'unset';
        }
        sonyFrame?.remove();
        setUpdateCreditCardSuccessedModal(true);
      },
    },
  );

  const checkValidCardMutation = useHgbMutationPost<
    string,
    CheckValidCardRequestType
  >(API_URLS.FOUR_MEM_ADD_API_URL, {
    isAlert: false,
    onSuccess(data) {
      const responseParams = new URLSearchParams(data?.result);
      const status = responseParams.get(
        'ResponseCd',
      ) as PaymentErrorType | null;
      const statusMsg = status ? paymentError[status] : '';
      setIsLoading(false);
      if (status === 'OK') {
        updateCardMutation.mutate({
          id: enterprisePayload.id,
          maskedCardNumber: card.card ?? '',
          email: enterprisePayload.picEmail,
          kaninId: KaiinId,
          kaninPass: KaiinPass,
        });
      } else {
        return navigate(routs_en['/temporarily-register/fail'].link, {
          state: statusMsg + ';/temporarily-register/' + 'enterpriseAdmin',
        });
      }
    },
  });

  useEffect(() => {
    const currentDate = dayjs(new Date()).format('YYYYMMDD');
    (async () => {
      const merchantPass = await getMerchantPass();
      const merchantId = await getMerchantId();
      if (!isEmpty(card.card)) {
        checkValidCardMutation.mutate({
          merchantId: merchantId,
          merchantPass: merchantPass,
          transactionDate: currentDate,
          operateId:
            enterprisePayload.kaiinId && enterprisePayload.kaiinPass
              ? KAIN_ID_CHANGE
              : KAIN_ID_ADD,
          token: card.token ?? '',
          kaiinId: enterprisePayload.kaiinId
            ? enterprisePayload.kaiinId
            : KaiinId,
          kaiinPass: enterprisePayload.kaiinPass
            ? enterprisePayload.kaiinPass
            : KaiinPass,
        });
      }
    })();
  }, [JSON.stringify(card)]);

  const [isLoading, setIsLoading] = useState(false);

  const [closeCard, setCloseCard] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      const script: HTMLScriptElement = document.createElement('script');
      const src = new URL(REACT_APP_E_SCOTT_URL);
      const token = await getEscottToken();
      src.searchParams.set('k_TokenNinsyoCode', token);
      script.src = src.href;
      script.className = 'spsvToken';
      script.type = 'text/javascript';
      script.setAttribute('callBackFunc', 'setToken');
      document.body.appendChild(script);
      setCloseCard(false);
      return () => {
        document.body.removeChild(script);
      };
    })();
  }, [closeCard]);

  useEffect(() => {
    const setToken = (e: MessageEvent) => {
      if (typeof e.data === 'string' && !isEmpty(e.data)) {
        const dataJson = JSON.parse(e.data);
        if (
          dataJson.spsvOrigin.includes('e-scott.jp') &&
          !isEmpty(dataJson.cardNo)
        ) {
          setCard({ card: dataJson.cardNo, token: dataJson.token });
        }
        if (dataJson.token.includes('close')) {
          setCloseCard(true);
          const sonyFrame = document.getElementById('SPSVviewIframe');
          const htmlEl = document.querySelector('html');
          if (htmlEl?.style) {
            htmlEl.style.overflow = 'unset';
          }
          sonyFrame?.remove();
          setIsLoading(false);
        }
      }
    };
    window.addEventListener('message', setToken);

    return () => {
      window.removeEventListener('message', setToken);
    };
  }, []);

  return !isLoading ? (
    <ManagementTemplate
      pageName={D01.pageTitleEnterprise}
      screenId="EnterpriseAdmin"
    >
      <FormProvider {...updateMethods}>
        <DataTableTemplate
          title={D01.pageTitleEnterprise}
          className="tw-flex-auto"
        >
          <form
            className="tw-flex tw-flex-col tw-gap-16 tw-bg-white pc:tw-flex-row pc:tw-gap-24"
            onSubmit={handleSubmitUpdate(onSubmit, onError)}
          >
            <HgbResponsive
              gap
              className="tw-grid tw-w-full tw-flex-auto tw-grid-cols-1 tw-items-start tw-self-start pc:tw-grid-cols-2"
            >
              <HgbAntdInput
                disabled
                {...register('enterpriseCode')}
                maxLength={100}
                type={'K'}
                label={common.label.enterpriseCode}
                placeholder={D01.placeholder.enterpriseCode}
              />
              <HgbAntdInput
                {...register('enterpriseName')}
                maxLength={100}
                type="K"
                required
                className="tw-col-start-1"
                label={common.label.enterpriseName}
                placeholder={common.placeholder.enterpriseName}
              />
              <HgbAntdInput
                {...register('enterpriseNameKana')}
                maxLength={100}
                type="K"
                required
                label={common.label.enterpriseNameKana}
                placeholder={common.placeholder.enterpriseNameKana}
              />
              <HgbAntdInput
                {...register('representative')}
                maxLength={50}
                type="K"
                required
                className="tw-col-start-1"
                label={common.label.representative}
                placeholder={D01.placeholder.representative}
              />
              <HgbAntdInput
                {...register('representativeKana')}
                maxLength={50}
                type="K"
                required
                label={common.label.representativeKana}
                placeholder={D01.placeholder.representativeKana}
              />
              <HgbAntdInput
                {...register('postalCode')}
                maxLength={7}
                type={'integer'}
                required
                label={common.label.postalCode}
                placeholder={common.placeholder.postalCode}
                onChangeValue={() => setEdited(true)}
              />
              <HgbAntdInput
                {...register('phoneNumber')}
                maxLength={11}
                type={'integer'}
                label={common.label.phoneNumber}
                placeholder={common.placeholder.phoneNumber}
              />
              <HgbAntdInput
                {...register('address1')}
                maxLength={100}
                type="K"
                required
                className="tw-col-start-1"
                label={common.label.fullAddress}
                placeholder={D01.placeholder.address1}
              />
              <HgbAntdInput
                {...register('address2')}
                maxLength={100}
                type="K"
                // required
                className="tw-col-start-1"
                label={common.label.address2}
                placeholder={D01.placeholder.address2}
              />
              <HgbSelect
                label={common.label.industryMajorCodeClassification}
                className="tw-col-start-1"
                {...register('industryCodeMajorClassification')}
                placeholder={''}
                options={codeMajorOptions}
                onChangeValue={() => setIsEditedMajor(true)}
                required
              />
              <HgbSelect
                label={common.label.industryMediumCodeClassification}
                className="tw-self-start"
                {...register('industryCodeMediumClassification')}
                placeholder={''}
                options={codeMediumOptions}
                required
              />
              <HgbSelect
                {...register('startMonthOfYear')}
                options={MONTH_OF_YEAR_OPTIONS}
                label={common.label.startMonthOfYear}
                placeholder={D01.placeholder.startMonthOfYear}
                required
              />
              <HgbDatePicker
                format="YYYY/MM"
                required={user.subscriptionType !== 'COMPLIMENTARY'}
                {...register('billingStartDate')}
                label={common.label.billingDate}
                placeholder={D01.placeholder.billingDate}
                disabled
              />
              <HgbAntdRadioGroup
                {...register('hasAccount')}
                options={haveNoneOption}
                label={common.label.isCorporateAccount}
                required
              />
              {user.subscriptionType !== 'COMPLIMENTARY' && (
                <>
                  <HgbSelect
                    {...register('paymentType')}
                    options={paymentOption}
                    label={common.label.paymentMethod}
                    required
                    placeholder={''}
                  />
                  <HgbAntdButton
                    type="link"
                    className="!tw-flex tw-whitespace-normal !tw-p-0 tw-text-left !tw-no-underline pc:tw-col-start-2"
                    onClick={() => {
                      setIsLoading(true);
                      SpsvApi.spsvCardInfoScreen();
                    }}
                  >
                    {common.button.enterpriseAdminCard}
                  </HgbAntdButton>
                </>
              )}
              {enterprisePayload.hasAccount === '1' && (
                <HgbAntdInput
                  {...register('branchNumber')}
                  maxLength={3}
                  type={'integer'}
                  required
                  label={common.label.branchNumber}
                />
              )}
              {enterprisePayload.hasAccount === '1' && (
                <HgbAntdInput
                  {...register('corporateAccountNumber')}
                  maxLength={7}
                  type={'integer'}
                  required
                  label={common.label.corporateAccountNumber}
                />
              )}
              {user.isManagementBank && (
                <HgbAntdRadioGroup
                  {...register('isInvested')}
                  options={haveNoneOption}
                  label={D01.label.isInvested}
                  // required
                />
              )}

              {enterprisePayload.groupStatus === 'ACTIVE' &&
                !isEmpty(enterprisePayload.id?.toString()) && (
                  <HgbSelect
                    label={common.label.picName}
                    {...register('picId')}
                    placeholder={''}
                    options={picIdOption}
                  />
                )}
              <HgbAntdInput
                {...register('picEmail')}
                label={common.label.picEmail}
                type="H"
                maxLength={100}
                disabled={
                  enterprisePayload.groupStatus === 'ACTIVE' &&
                  !isEmpty(enterprisePayload.id?.toString())
                }
              />
              {isEmpty(enterprisePayload.id?.toString()) && (
                <div className="tw-grid tw-flex-auto tw-grid-cols-1 tw-items-start tw-self-start pc:tw-grid-cols-2">
                  <HgbAntdInput
                    {...register('picName')}
                    maxLength={50}
                    type={'K'}
                    required={isEmpty(enterprisePayload.id?.toString())}
                    label={common.label.picName}
                    className="tw-col-start-1"
                  />
                  <HgbAntdInput
                    {...register('picNameKana')}
                    maxLength={50}
                    type={'K'}
                    required={isEmpty(enterprisePayload.id?.toString())}
                    label={common.label.picNameKana}
                  />
                </div>
              )}

              <HgbSelect
                label={D01.label.scope3DirectInput}
                {...register('availableScope3CategoryArray')}
                placeholder={''}
                options={categoryCodeOptions}
                mode="multiple"
                required
              />
            </HgbResponsive>
            <HgbResponsive className="tw-flex tw-items-center tw-justify-center tw-gap-24">
              <HgbAntdButton
                className="tw-shrink-0 tw-self-start"
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                <>{common.button.save}</>
              </HgbAntdButton>
            </HgbResponsive>
          </form>
        </DataTableTemplate>
      </FormProvider>
      <HgbAntdModal
        open={updateModal}
        okText={common.button.ok}
        cancelText={common.button.cancel}
        title={D01.modal.enterpriseUpdateConfirmModalTitle}
        onCancel={() => setUpdateModal(false)}
        onOk={() => submitEnterprise(enterprisePayload)}
      >
        {D01.label.updateConfirmContent}
      </HgbAntdModal>
      <HgbAntdModal
        open={updateCreditCardSuccessedModal}
        cancelText={common.button.close}
        hgbLogo={true}
        title={D01.modal.enterpriseUpdateCradModalTitle}
        successModal={true}
        onCancel={() => {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          setUpdateCreditCardSuccessedModal(false), window.location.reload();
        }}
      >
        {D01.label.updateCardContent}
      </HgbAntdModal>
    </ManagementTemplate>
  ) : (
    <div
      className={`tw-fixed tw-z-10 tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center`}
    >
      <svg
        aria-hidden="true"
        className="tw-h-80 tw-w-80 tw-animate-spin tw-fill-primary-5 tw-text-white"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
    </div>
  );
};
