import { HgbAntdButton } from '@common/antd/HgbAntdButton';
import HgbAntdModalForm from '@common/antd/HgbAntdModalForm';
import { HgbAntdModalFormBody } from '@common/antd/HgbAntdModalFormBody';
import { HgbAntdModalFormFooter } from '@common/antd/HgbAntdModalFormFooter';
import { HgbAntdModalTitle } from '@common/antd/HgbAntdModalTitle';
import { HgbFieldError } from '@common/contents/HgbFieldError/HgbFieldError';
import {
  HgbAntdInput,
  HgbDatePicker,
  HgbSelect,
  parseScopeToHgbSelectOptions,
  parseToHgbSelectOptions,
} from '@common/forms';
import { HgbTreeSelect } from '@common/forms/HgbTreeSelect/HgbTreeSelect';
import { HgbExpandMoreOutlinedIcon } from '@common/icons/outlined';
import { API_URLS, QUERY_KEYS } from '@constants/API_URLS';
import { LanguageContext } from '@contexts/LanguageContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGet } from '@hooks/useApi';
import { BooleanState } from '@hooks/useBooleanState';
import { useConfirm } from '@hooks/useConfirm';
import { useEnabledMonths, useTargetMonths } from '@hooks/useMasterData';
import { cn } from '@utils/cn';
import {
  currentYearMonth,
  getFiscalYearFromMonth,
  validateMonthRange,
} from '@utils/date.util';
import { debug } from '@utils/debug';
import { floorNumber } from '@utils/input.utils';
import { calculationFormula } from '@utils/number';
import {
  convertObjectToTree,
  getItemFromOptions,
  getParentKey,
} from '@utils/object.utils';
import { Modal } from 'antd';
import { debounce, isEmpty } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import {
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useHgbMutationPost } from 'services/common/mutation';
import { useHgbQuery } from 'services/common/query';
import {
  BaseOrganizationListBoxRequest,
  BaseOrganizationListBoxResponse,
} from 'services/types/base';
import {
  GetFiscalYearMonthListBoxRequest,
  GetFiscalYearMonthListBoxResponse,
  GetLast10FiscalYearListBoxRequest,
  GetLast10FiscalYearListBoxResponse,
} from 'services/types/year';
import * as yup from 'yup';
import { SupplierProduct } from '../D19/D19';
import { SupplierResponse } from '../H01/H01';
import { SUPPLIER_RESPONSE_TYPE } from '../H02/H02';
import {
  convert2StepsToSupplierResponsePayload,
  convertDataAllocationRates,
  getFieldsInfo,
  step1DefaultValues,
  step2DefaultValues,
} from './utils';

export type SupplierPattern = 'PATTERN_1' | 'PATTERN_2';

export type Step1Fields = {
  scope: string;
  category: string;
  baseId: string;
  calculationTarget: string;
};

export type Step2Fields = {
  targetStartMonth?: string;
  targetEndMonth?: string;
  targetYearMonth?: string;
  supplierResponseId: string;
  fiscalYear: string;
  productEmissionIntensity: string;
  productEmissionIntensityUnit: string;
  unit?: string;
  quantity: string;
  productId: string;
  supplierId: string;
  requestTimestamp: string;
  signature: string;
  productSerialNumber: string;
  emissionIntensity: string;
  emissionVolume: string;
  supplyChainGroupName: string;
  lastModifiedDateTime: string;
  m01: string;
  m02: string;
  m03: string;
  m04: string;
  m05: string;
  m06: string;
  m07: string;
  m08: string;
  m09: string;
  m10: string;
  m11: string;
  m12: string;
  total: string;
};

export type SupplierResponseProductPayload = {
  targetStartMonth: string;
  targetEndMonth: string;
  targetMonthPayload: Record<string, number>;
  category: string;
  calculationTarget: string;
  baseId: number;
  supplierResponseId: string;
  fiscalYear: number;
  productEmissionIntensity: string;
  productEmissionIntensityUnit: string;
  quantity: number;
  productId: number;
  supplierId: number;
  mode: Exclude<SupplierAnswerModalMode, 'DUPLICATE'>;
  status: string;
  buyerInputEmissionVolume: string;
  lastModifiedDateTime: string;
};

export type SupplierResponseOrganizationPayload = {
  targetStartMonth: string;
  targetEndMonth: string;
  targetMonthPayload: Record<string, number>;
  category: string;
  calculationTarget: string;
  baseId: number;
  supplierResponseId: string;
  fiscalYear: number;
  supplierId: number;
  mode: Exclude<SupplierAnswerModalMode, 'DUPLICATE'>;
  status: string;
  buyerInputEmissionVolume: string;
  lastModifiedDateTime: string;
};

export type BuyerSupplierProductPayload = {
  targetStartMonth: string;
  targetEndMonth: string;
  category: string;
  calculationTarget: string;
  baseId: number;
  fiscalYear: number;
  supplierId: number;
  mode: Exclude<SupplierAnswerModalMode, 'DUPLICATE'>;
  status?: string;
  targetMonthPayload: Record<string, number>;
  quantity: number;
  productId: number;
  productEmissionIntensity: string;
  productEmissionIntensityUnit: string;
  supplierResponseId?: string;
  buyerInputEmissionVolume?: string;
};

export type BuyerManualInputPayload = {
  category: string;
  calculationTarget: string;
  baseId: number;
  fiscalYear: number;
  targetYearMonth: string;
  supplierId: number;
  mode: Exclude<SupplierAnswerModalMode, 'DUPLICATE'>;
  status: string;
  buyerInputEmissionVolume: string;
  supplierResponseId?: string;
};

type SupplierResponsePayload =
  | SupplierResponseProductPayload
  | SupplierResponseOrganizationPayload
  | BuyerSupplierProductPayload
  | BuyerManualInputPayload;

export type ValueOf<T> = T[keyof T];

type Category = {
  value: string;
  name: string;
  note: string;
  childListBoxItem: ChildListBoxItem[];
};

type ChildListBoxItem = {
  value: string;
  name: string;
  note: string;
  responseType: string;
  childListBoxItem: any;
};

export type SupplierAnswerModalMode = 'ADD' | 'EDIT' | 'DUPLICATE';

interface SupplierAnswerModalProps {
  modalState: BooleanState;
  supplier?: SupplierResponse;
  pattern: SupplierPattern;
  mode: SupplierAnswerModalMode;
}

export const SupplierEmissionInputModal: React.FunctionComponent<
  SupplierAnswerModalProps
> = ({ modalState, supplier, pattern, mode }) => {
  const queryClient = useQueryClient();
  const {
    text: { common, H03, H04, E0049, E0100, E0000, E0047, E0048, E0089, E0106 },
  } = React.useContext(LanguageContext)!;

  const months = useTargetMonths();
  const newDate = new Date();
  const fiscalYear = getFiscalYearFromMonth(
    `${newDate.getFullYear()}/${newDate.getMonth()}`,
  );

  const { ConfirmModal, hgbConfirm } = useConfirm();
  const [step, setStep] = React.useState<'STEP_1' | 'STEP_2'>('STEP_1');
  const [supplierResponseType, setSupplierResponseType] = React.useState<
    ValueOf<typeof SUPPLIER_RESPONSE_TYPE>
  >(SUPPLIER_RESPONSE_TYPE.PRODUCT);
  const [checkUpdateData, setCheckUpdateData] = React.useState(false);

  const step2Schema = yup.object({
    supplyChainGroupName: yup.string().when({
      is: () => supplierResponseType === SUPPLIER_RESPONSE_TYPE.ORG,
      then: (schema) =>
        schema.required(E0000(common.columns.supplyChainGroupName)),
      otherwise: (schema) => schema,
    }),
    supplierId: yup.string().required(E0000(H04.supplierName)),
    productId: yup.string().when({
      is: () => supplierResponseType === SUPPLIER_RESPONSE_TYPE.PRODUCT,
      then: (schema) => schema.required(E0000(H04.supplierProduct)),
      otherwise: (schema) => schema.notRequired(),
    }),
    quantity: yup.string().when({
      is: () => supplierResponseType === SUPPLIER_RESPONSE_TYPE.PRODUCT,
      then: (schema) => schema.required(E0000(H03.quantity)),
      otherwise: (schema) => schema.notRequired(),
    }),
    emissionVolume: yup
      .string()
      .when({
        is: () =>
          supplierResponseType === SUPPLIER_RESPONSE_TYPE.MANUAL &&
          pattern === 'PATTERN_2',
        then: (schema) => schema.required(E0000(common.label.emissionVolume)),
        otherwise: (schema) => schema.notRequired(),
      })
      .max(14, E0106),
    targetStartMonth: yup.string().when({
      is: () => supplierResponseType !== SUPPLIER_RESPONSE_TYPE.MANUAL,
      then: (schema) => schema.required(E0047),
      otherwise: (schema) => schema.notRequired(),
    }),
    targetEndMonth: yup.string().when({
      is: () => supplierResponseType !== SUPPLIER_RESPONSE_TYPE.MANUAL,
      then: (schema) => schema.required(E0048),
      otherwise: (schema) => schema.notRequired(),
    }),
    targetYearMonth: yup.string().when({
      is: () => supplierResponseType === SUPPLIER_RESPONSE_TYPE.MANUAL,
      then: (schema) => schema.required(E0000(H04.month)),
      otherwise: (schema) => schema.notRequired(),
    }),
    total: yup
      .number()
      .test(
        '',
        E0089,
        (value) =>
          supplierResponseType === SUPPLIER_RESPONSE_TYPE.MANUAL ||
          value === 100,
      ),
    fiscalYear: yup.string().test('', '', (_, context) => {
      if (
        pattern === 'PATTERN_2' &&
        (supplierResponseType === SUPPLIER_RESPONSE_TYPE.MANUAL ||
          !context.parent.targetStartMonth ||
          !context.parent.targetEndMonth)
      ) {
        return true;
      }
      const value = validateMonthRange(
        moment(context.parent.targetStartMonth, 'YYYYMM').format('YYYY/MM'),
        moment(context.parent.targetEndMonth, 'YYYYMM').format('YYYY/MM'),
      );
      if (value === 'ORDER') {
        return context.createError({ message: E0049, path: 'fiscalYear' });
      }
      if (value === 'RANGE') {
        return context.createError({ message: E0100, path: 'fiscalYear' });
      }
      return true;
    }),
  });

  const step1Schema = yup.object({
    scope: yup.string().required(E0000(common.label.scope3)),
    category: yup.string().required(E0000(common.label.category)),
    baseId: yup.string().required(E0000(H04.baseOrganization)),
    calculationTarget: yup.string().required(E0000(H04.calculationTarget)),
  });

  const step1Methods = useForm<Step1Fields>({
    values: {
      ...step1DefaultValues,
      scope: common.label.scope3,
      category: supplier?.category?.value || '',
      baseId: supplier?.buyerBaseOrganizationId?.toString() || '',
      calculationTarget: supplier?.calculationTargetCode || '',
    },
    resolver: yupResolver(step1Schema),
  });

  const step2Methods = useForm<Step2Fields>({
    resolver: yupResolver(step2Schema),
    values: {
      ...step2DefaultValues,
      ...convertDataAllocationRates(supplier?.allocationRates),
      supplierResponseId: supplier?.id?.toString() || '',
      emissionVolume: supplier?.emissionVolume?.toString() || '',
      supplyChainGroupName: supplier?.supplyChainGroupName || '',
      supplierId: supplier?.supplierId?.toString() || '',
      productId: supplier?.productId?.toString() || '',
      productSerialNumber: supplier?.productSerialNumber || '',
      quantity: supplier?.quantity?.toString() || '',
      productEmissionIntensity: supplier?.emissionIntensity?.toString() || '',
      productEmissionIntensityUnit: [
        supplier?.emissionVolumeUnit || '',
        supplier?.unit || '',
      ]
        .filter((item) => item !== '')
        .join('/'),
      targetStartMonth: supplier?.targetStartMonth
        ? moment(supplier.targetStartMonth, 'YYYYMM').format('YYYY/MM')
        : fiscalYear + '/04',
      targetEndMonth: supplier?.targetEndMonth
        ? moment(supplier.targetEndMonth, 'YYYYMM').format('YYYY/MM')
        : fiscalYear + 1 + '/03',
      targetYearMonth: supplier?.targetYearMonth || '',
      fiscalYear: supplier?.fiscalYear || '',
      lastModifiedDateTime: supplier?.lastModifiedDateTime || '',
    },
  });

  const categoryQuery = useHgbQuery<Category[], { prevScreenCode: string }>(
    API_URLS.LISTBOX_CATEGORY_RESPONSE,
    {
      prevScreenCode: pattern === 'PATTERN_1' ? 'H01' : 'C01',
    },
  );

  const { data: supplierNameQuery } = useHgbQuery<Category[]>(
    API_URLS.LISTBOX_SUPPLIER_NAME,
  );

  const { data: supplierProductNameQuery } = useHgbQuery<
    Category[],
    { supplierId: string }
  >(
    API_URLS.LISTBOX_SUPPLIER_PRODUCT_NAME,
    {
      supplierId:
        step2Methods.watch('supplierId') ||
        supplier?.supplierId?.toString() ||
        '',
    },
    {
      enabled:
        !!(
          supplier?.supplierId?.toString() || step2Methods.watch('supplierId')
        ) && !(pattern === 'PATTERN_1' && mode === 'ADD'),
      queryKey: step2Methods.watch('supplierId'),
    },
  );

  const { data: detailSupplierProductQuery } = useHgbQuery<
    SupplierProduct,
    { supplierId: string }
  >(
    `${API_URLS.SUPPLIER_PRODUCT}/${step2Methods.watch('productId')}`,
    {
      supplierId:
        step2Methods.watch('supplierId') ||
        supplier?.supplierId?.toString() ||
        '',
    },
    {
      enabled:
        !!(
          supplier?.supplierId?.toString() || step2Methods.watch('supplierId')
        ) &&
        !!(supplier?.productId || step2Methods.watch('productId')) &&
        pattern === 'PATTERN_2',
      queryKey: step2Methods.watch('productId'),
    },
  );

  const { data: BOsQuery } = useHgbQuery<
    BaseOrganizationListBoxResponse,
    BaseOrganizationListBoxRequest
  >(API_URLS.ORGANIZATION_BASE, {
    enterpriseId: '',
    isLoadAllOption: false,
  });

  const { data: yearLBQuery } = useHgbQuery<
    GetLast10FiscalYearListBoxResponse,
    GetLast10FiscalYearListBoxRequest
  >(API_URLS.LAST_TEN_FISCAL_YEAR, undefined);

  const targetYearOptions = React.useMemo(
    () =>
      parseToHgbSelectOptions(yearLBQuery?.result).filter(
        (word) => Number(word.value) > 2018,
      ),
    [yearLBQuery?.result],
  );

  const registerSupplierEmissionMutation = useHgbMutationPost<
    unknown,
    SupplierResponsePayload
  >(API_URLS.REGISTER_SUPPLIER_EMISSION, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.EMISSION_LIST],
      });
      modalState.turnOff();
    },
  });

  const categoryOptions = React.useMemo(
    () => parseScopeToHgbSelectOptions(categoryQuery.data?.result),
    [JSON.stringify(categoryQuery.data?.result)],
  );

  const calculationTarget = React.useMemo(() => {
    const selectedCategory =
      step1Methods?.watch('category') || categoryOptions[0]?.value;

    return categoryQuery.data?.result
      ?.find((p) => p?.value === selectedCategory)
      ?.childListBoxItem?.filter(
        (v) =>
          !supplier?.responseType?.value ||
          v?.responseType === supplier?.responseType?.value,
      );
  }, [
    JSON.stringify(categoryQuery.data?.result),
    supplier?.responseType?.value,
    step1Methods?.watch('category'),
  ]);

  const calculationTargetOptions = React.useMemo(
    () => parseToHgbSelectOptions(calculationTarget),
    [calculationTarget],
  );

  const baseIdOptions = React.useMemo(() => {
    const BOs = BOsQuery?.result ?? [];
    return convertObjectToTree(
      BOs.filter((item) => !getParentKey(item.hierarchicalPath ?? '')),
      BOs,
    );
  }, [JSON.stringify(BOsQuery?.result)]);

  const supplierNamesOptions = React.useMemo(
    () => parseToHgbSelectOptions(supplierNameQuery?.result),
    [JSON.stringify(supplierNameQuery?.result), supplier?.supplierId],
  );

  const supplierProductOptions = React.useMemo(
    () =>
      pattern === 'PATTERN_1' && mode === 'ADD'
        ? [
            {
              label: supplier?.productName || '',
              value: supplier?.productId?.toString() || '',
            },
          ]
        : parseToHgbSelectOptions(supplierProductNameQuery?.result),
    [
      JSON.stringify(supplierProductNameQuery?.result),
      supplier?.productName,
      supplier?.productId,
    ],
  );

  const enabledMonths = useEnabledMonths(
    step2Methods.watch('targetStartMonth'),
    step2Methods.watch('targetEndMonth'),
  );

  React.useEffect(() => {
    if (
      !supplier?.allocationRates ||
      Object.keys(supplier?.allocationRates || {}).length === 0
    ) {
      //tính toán value lúc khởi tạo
      calculateMonthRate(enabledMonths);
    }
  }, [
    JSON.stringify(enabledMonths),
    modalState.value,
    supplier?.allocationRates,
  ]);

  React.useEffect(() => {
    const value = (
      Number(step2Methods.watch('m01')!) +
      Number(step2Methods.watch('m02')!) +
      Number(step2Methods.watch('m03')!) +
      Number(step2Methods.watch('m04')!) +
      Number(step2Methods.watch('m05')!) +
      Number(step2Methods.watch('m06')!) +
      Number(step2Methods.watch('m07')!) +
      Number(step2Methods.watch('m08')!) +
      Number(step2Methods.watch('m09')!) +
      Number(step2Methods.watch('m10')!) +
      Number(step2Methods.watch('m11')!) +
      Number(step2Methods.watch('m12')!)
    ).toFixed(1);
    step2Methods.setValue('total', value);
  }, [JSON.stringify(step2Methods.watch())]);

  React.useEffect(() => {
    if (detailSupplierProductQuery?.result) {
      const { emissionIntensity, emissionIntensityUnit, unit, serialNumber } =
        detailSupplierProductQuery?.result as SupplierProduct;
      step2Methods.setValue('productEmissionIntensity', emissionIntensity);
      step2Methods.setValue('unit', unit);
      step2Methods.setValue(
        'productEmissionIntensityUnit',
        [emissionIntensityUnit, unit].filter((item) => item !== '').join('/'),
      );
      step2Methods.setValue('productSerialNumber', serialNumber);
    }
  }, [JSON.stringify(detailSupplierProductQuery?.result)]);

  React.useEffect(() => {
    if (
      step2Methods.watch('quantity') &&
      step2Methods.watch('productEmissionIntensity')
    ) {
      step2Methods.setValue(
        'emissionVolume',
        floorNumber(
          Number(
            calculationFormula(
              '1',
              '',
              step2Methods.watch('quantity'),
              step2Methods.watch('productEmissionIntensity'),
              '',
            ),
          ),
          3,
        ).toString(),
      );
    }
  }, [
    step2Methods.watch('quantity'),
    step2Methods.watch('productEmissionIntensity'),
  ]);

  const step1SubmitHandler = () => {
    setStep('STEP_2');
  };

  const step2SubmitErrorHandler: SubmitErrorHandler<Step2Fields> = (error) => {
    debug.error('error', error);
  };

  const step2SubmitHandler: SubmitHandler<Step2Fields> = async (
    step2Values,
  ) => {
    const result = await hgbConfirm();
    if (!result) return;

    const payload = convert2StepsToSupplierResponsePayload(
      step1Methods.getValues(),
      step2Values,
      enabledMonths,
      pattern,
      supplierResponseType,
      mode,
      supplier?.status?.value,
    );

    if (!payload) return;
    registerSupplierEmissionMutation.mutate(payload);
  };

  const handleSubmitDebounce = debounce(step2SubmitHandler, 200);

  const calculateMonthRate = (rangeMonth: string[]) => {
    const { length } = rangeMonth;

    months.forEach((month) => {
      if (rangeMonth.includes(month.key)) {
        // Nếu tháng hiện có trong enabledMonths
        const average = 100 / length;
        //display 100 => 100, 20 => 20.0
        const displayAverage = Number.isInteger(average)
          ? average
          : average.toFixed(1);

        step2Methods.setValue(
          ('m' + month.key) as 'm01',
          displayAverage.toString(),
        );
      } else {
        // Nếu tháng không có trong enabledMonths
        step2Methods.setValue(('m' + month.key) as 'm01', '');
      }
    });
  };

  const handleCalculateMonthRate = (timeStart: string, timeEnd: string) => {
    //tính toán lại enable month
    let rs: string[] = [];
    const startMonth = timeStart || step2Methods.watch('targetStartMonth');
    const endMonth = timeEnd || step2Methods.watch('targetEndMonth');

    if (!startMonth || !endMonth) return;
    const fiscalYear = getFiscalYearFromMonth(startMonth);
    months.forEach((item) => {
      let currentTime = moment();
      currentTime = moment(`${fiscalYear}${item.key}`, 'YYYYMM');
      if (['01', '02', '03'].includes(item.key)) {
        currentTime = moment(`${fiscalYear + 1}${item.key}`, 'YYYYMM');
      }

      const startTime = moment(startMonth, 'YYYYMM');
      const endTime = moment(endMonth, 'YYYYMM');

      if (
        currentTime.isSameOrBefore(endTime) &&
        currentTime.isSameOrAfter(startTime)
      ) {
        rs.push(item.key);
      }
    });

    calculateMonthRate(rs);
  };

  const onCancel = () => {
    modalState.turnOff();
    setStep('STEP_1');
    step1Methods.reset();
    step2Methods.reset();
  };

  const fieldsInfo = getFieldsInfo(mode, pattern, supplierResponseType);

  const [yearResult, getYearResult] = useGet<
    GetFiscalYearMonthListBoxResponse,
    GetFiscalYearMonthListBoxRequest
  >();

  React.useEffect(() => {
    let targetFiscalYear = step2Methods.watch('fiscalYear');
    if (targetFiscalYear) {
      getYearResult(API_URLS.FISCAL_YEAR_MONTH, {
        targetYear: targetFiscalYear,
        isLCIDBYear: false,
      });
    }
  }, [step2Methods.watch('fiscalYear')]);

  React.useEffect(() => {
    const shouldSetTargetYearMonth =
      yearResult?.data &&
      yearResult?.data?.length > 0 &&
      (!supplier?.targetYearMonth || checkUpdateData);
    if (shouldSetTargetYearMonth) {
      if (fiscalYear === Number(step2Methods.watch('fiscalYear'))) {
        step2Methods.setValue('targetYearMonth', currentYearMonth());
      } else {
        step2Methods.setValue(
          'targetYearMonth',
          yearResult?.data?.[0]?.value ?? '',
        );
      }
    }
  }, [JSON.stringify(yearResult?.data), step2Methods.watch('fiscalYear')]);

  React.useEffect(() => {
    if (supplierProductOptions?.length > 0) {
      const findValueSuplierProduct = supplierProductOptions?.find(
        (v) => supplier?.productId && Number(v?.value) === supplier?.productId,
      );
      if (!findValueSuplierProduct || checkUpdateData) {
        step2Methods.setValue('productId', supplierProductOptions[0]?.value);
      }
    }
  }, [JSON.stringify(supplierProductOptions)]);

  React.useEffect(() => {
    //case H01
    const value = getItemFromOptions(
      step1Methods.watch('calculationTarget'),
      calculationTarget,
      'value',
    )?.responseType as ValueOf<typeof SUPPLIER_RESPONSE_TYPE>;
    if (value) {
      setSupplierResponseType(value);
    }
  }, [step1Methods.watch('calculationTarget'), calculationTarget]);

  React.useEffect(() => {
    //case H01
    if (
      calculationTargetOptions?.length > 0 &&
      (calculationTargetOptions?.length === 1 ||
        !supplier?.calculationTargetCode)
    ) {
      const value =
        calculationTarget?.find((p) => p?.responseType === supplierResponseType)
          ?.value || calculationTargetOptions[0].value;
      step1Methods.setValue('calculationTarget', value);
    }
  }, [calculationTargetOptions, supplierResponseType]);

  return (
    <>
      <Modal
        className="ant-modal-hgb-custom"
        centered
        title={
          <HgbAntdModalTitle className="tw-flex tw-items-start tw-self-stretch">
            {step === 'STEP_2' && (
              <div className="tw-grid tw-h-48 tw-w-fit tw-shrink-0 tw-place-content-center">
                <HgbExpandMoreOutlinedIcon
                  onClick={() => setStep('STEP_1')}
                  size="24"
                  className="tw-h-24 tw-w-24 tw-rotate-90 tw-cursor-pointer tw-justify-self-start"
                />
              </div>
            )}
            <h1 className="tw-flex-auto tw-text-center tw-font-bold tw-leading-[48px]">
              {H04.title}
            </h1>
          </HgbAntdModalTitle>
        }
        open={modalState.value}
        onCancel={onCancel}
        footer={null}
        closable={true}
        maskClosable={false}
        destroyOnClose={true}
      >
        {step === 'STEP_1' ? (
          <FormProvider {...step1Methods}>
            <HgbAntdModalForm
              onSubmit={step1Methods.handleSubmit(step1SubmitHandler)}
            >
              <HgbAntdModalFormBody>
                <HgbAntdInput
                  label={common.label.scope}
                  value={common.label.scopeNote3}
                  {...step1Methods.register('scope')}
                  disabled
                  required
                />

                <HgbSelect
                  label={common.label.category}
                  {...step1Methods.register('category')}
                  placeholder={H04.placeholder.category1}
                  options={categoryOptions}
                  required
                  showSearch
                  disabled={fieldsInfo.disabledFields.includes('category')}
                />

                <HgbTreeSelect
                  label={H04.baseOrganization}
                  disabled={
                    isEmpty(baseIdOptions) ||
                    fieldsInfo.disabledFields.includes('baseId')
                  }
                  treeData={baseIdOptions}
                  {...step1Methods.register('baseId')}
                  showSearch
                  required
                />

                <HgbSelect
                  label={H04.calculationTarget}
                  {...step1Methods.register('calculationTarget')}
                  options={calculationTargetOptions}
                  required
                  disabled={fieldsInfo.disabledFields.includes(
                    'calculationTarget',
                  )}
                />
              </HgbAntdModalFormBody>
              <HgbAntdModalFormFooter>
                <HgbAntdButton className="tw-min-w-100" onClick={onCancel}>
                  {common.button.cancel}
                </HgbAntdButton>

                <HgbAntdButton
                  type="primary"
                  className="tw-min-w-100"
                  htmlType="submit"
                >
                  {common.button.next}
                </HgbAntdButton>
              </HgbAntdModalFormFooter>
            </HgbAntdModalForm>
          </FormProvider>
        ) : null}

        {step === 'STEP_2' ? (
          <FormProvider {...step2Methods}>
            <HgbAntdModalForm
              onSubmit={step2Methods.handleSubmit(
                handleSubmitDebounce,
                step2SubmitErrorHandler,
              )}
            >
              <HgbAntdModalFormBody>
                <ConditionalCmp
                  show={fieldsInfo.availableFields.includes(
                    'supplyChainGroupName',
                  )}
                >
                  <HgbAntdInput
                    label={common.columns.supplyChainGroupName}
                    {...step2Methods.register('supplyChainGroupName')}
                    disabled={fieldsInfo.availableFields.includes(
                      'supplyChainGroupName',
                    )}
                    required
                  />
                </ConditionalCmp>
                {supplierResponseType === SUPPLIER_RESPONSE_TYPE.MANUAL ? (
                  <div className="tw-space-y-4">
                    <div className="tw-flex tw-items-start tw-justify-between">
                      <HgbSelect
                        label={H04.fiscalYear}
                        disabled={isEmpty(targetYearOptions)}
                        options={targetYearOptions}
                        {...step2Methods.register('fiscalYear')}
                        autoInitValue={!step2Methods.getValues('fiscalYear')}
                        required
                        onChangeValue={() => setCheckUpdateData(true)}
                      />

                      <HgbSelect
                        label={H04.month}
                        {...step2Methods.register('targetYearMonth')}
                        options={(yearResult?.data ?? []).map(
                          ({ name: label, value }) => ({
                            label,
                            value,
                          }),
                        )}
                        className="tw-cursor-pointer"
                        required
                        onChangeValue={() => setCheckUpdateData(true)}
                      />
                    </div>
                  </div>
                ) : null}

                <ConditionalCmp
                  show={fieldsInfo.availableFields.includes('supplierId')}
                >
                  <HgbSelect
                    label={H04.supplierName}
                    {...step2Methods.register('supplierId')}
                    options={supplierNamesOptions}
                    required
                    disabled={fieldsInfo.disabledFields.includes('supplierId')}
                    onChangeValue={() => setCheckUpdateData(true)}
                  />
                </ConditionalCmp>

                <ConditionalCmp
                  show={fieldsInfo.availableFields.includes('productId')}
                >
                  <HgbSelect
                    label={H04.supplierProduct}
                    {...step2Methods.register('productId')}
                    options={supplierProductOptions}
                    required
                    disabled={fieldsInfo.disabledFields.includes('productId')}
                  />
                </ConditionalCmp>

                <ConditionalCmp
                  show={fieldsInfo.availableFields.includes(
                    'productSerialNumber',
                  )}
                >
                  <HgbAntdInput
                    label={H04.serialNumber}
                    {...step2Methods.register('productSerialNumber')}
                    disabled={fieldsInfo.disabledFields.includes(
                      'productSerialNumber',
                    )}
                  />
                </ConditionalCmp>

                <ConditionalCmp
                  show={fieldsInfo.availableFields.includes('quantity')}
                >
                  <HgbAntdInput
                    label={H03.quantity}
                    subLabel={'0-9999999999'}
                    maxLength={13}
                    maxValue={9999999999}
                    type="positiveDecimal"
                    {...step2Methods.register('quantity')}
                    suffix={step2Methods.getValues('unit') || supplier?.unit}
                    disabled={fieldsInfo.disabledFields.includes('quantity')}
                    required
                  />
                </ConditionalCmp>

                <ConditionalCmp
                  show={fieldsInfo.availableFields.includes(
                    'productEmissionIntensity',
                  )}
                >
                  <HgbAntdInput
                    label={common.label.emissionIntensity}
                    suffix={step2Methods.getValues(
                      'productEmissionIntensityUnit',
                    )}
                    {...step2Methods.register('productEmissionIntensity')}
                    disabled
                  />
                </ConditionalCmp>

                <ConditionalCmp
                  show={fieldsInfo.availableFields.includes('emissionVolume')}
                >
                  <HgbAntdInput
                    label={common.label.emissionVolume}
                    suffix="tCO2"
                    maxLength={14}
                    type="positiveDecimal"
                    {...step2Methods.register('emissionVolume')}
                    disabled={fieldsInfo.disabledFields.includes(
                      'emissionVolume',
                    )}
                    required={
                      supplierResponseType === SUPPLIER_RESPONSE_TYPE.MANUAL &&
                      pattern === 'PATTERN_2'
                    }
                  />
                </ConditionalCmp>

                {supplierResponseType !== SUPPLIER_RESPONSE_TYPE.MANUAL ? (
                  <>
                    <div className="tw-space-y-4">
                      <div className="tw-flex tw-justify-between tw-gap-16">
                        <HgbDatePicker
                          picker="month"
                          label={H04.startMonth}
                          infoTooltip={H04.startDateTooltip}
                          {...step2Methods.register('targetStartMonth')}
                          format="YYYY/MM"
                          className="tw-cursor-pointer"
                          allowClear
                          required
                          disabled={fieldsInfo.disabledFields.includes(
                            'targetStartMonth',
                          )}
                          onChangeValue={(_e: any, time: string) =>
                            handleCalculateMonthRate(time, '')
                          }
                        />
                        <HgbDatePicker
                          picker="month"
                          label={H04.endMonth}
                          infoTooltip={H04.endDateTooltip}
                          {...step2Methods.register('targetEndMonth')}
                          format="YYYY/MM"
                          className="tw-cursor-pointer"
                          allowClear
                          required
                          disabled={fieldsInfo.disabledFields.includes(
                            'targetEndMonth',
                          )}
                          onChangeValue={(_e: any, time: string) =>
                            handleCalculateMonthRate('', time)
                          }
                        />
                      </div>
                      {step2Methods.formState.errors.fiscalYear ? (
                        <HgbFieldError>
                          {step2Methods.formState.errors.fiscalYear.message}
                        </HgbFieldError>
                      ) : null}
                    </div>
                    <ConditionalCmp
                      show={fieldsInfo.availableFields.includes(
                        'targetMonthPayload',
                      )}
                    >
                      <div className="tw-space-y-4">
                        <span className="tw-font-bold">
                          {H04.total}:{' '}
                          <span
                            className={cn({
                              'tw-text-success-8':
                                Number(step2Methods.watch('total')) === 100,
                            })}
                          >
                            {step2Methods.watch('total')}%
                          </span>
                        </span>
                        <div
                          className={cn(
                            'tw-rounded-16 tw-border tw-border-solid tw-border-transparent tw-bg-neutral-1/10 tw-p-16 tw-px-8 pc:tw-p-24',
                            {
                              'tw-border-error-7 tw-bg-error-1/50':
                                step2Methods.formState.errors.total,
                            },
                          )}
                        >
                          <div
                            className={cn(
                              'tw-grid tw-grid-cols-3 tw-gap-x-8 tw-gap-y-24 pc:tw-gap-x-24 [&_.ant-input-affix-wrapper]:tw-w-60 [&_.ant-input-affix-wrapper]:tw-px-6 [&_input]:tw-text-end [&_input]:tw-text-[12px]',
                            )}
                          >
                            {months.map((month) => (
                              <TargetInputBounding
                                month={month.key}
                                key={month.key}
                                label={month.label}
                                disabled={!enabledMonths.includes(month.key)}
                              >
                                <HgbAntdInput
                                  maxLength={4}
                                  type="positiveDecimal"
                                  {...step2Methods.register(
                                    ('m' + month.key) as 'm12',
                                  )}
                                  disabled={!enabledMonths.includes(month.key)}
                                  suffix="%"
                                />
                              </TargetInputBounding>
                            ))}
                          </div>
                        </div>

                        {step2Methods.formState.errors.total ? (
                          <HgbFieldError>
                            {step2Methods.formState.errors.total?.message}
                          </HgbFieldError>
                        ) : null}
                      </div>
                    </ConditionalCmp>
                  </>
                ) : null}
              </HgbAntdModalFormBody>
              <HgbAntdModalFormFooter>
                <HgbAntdButton className="tw-min-w-100" onClick={onCancel}>
                  {common.button.cancel}
                </HgbAntdButton>

                <HgbAntdButton
                  type="primary"
                  className="tw-min-w-100"
                  htmlType="submit"
                  loading={registerSupplierEmissionMutation.isLoading}
                >
                  {mode === 'EDIT' ? common.button.change : common.button.add}
                </HgbAntdButton>
              </HgbAntdModalFormFooter>
            </HgbAntdModalForm>
          </FormProvider>
        ) : null}
      </Modal>

      <ConfirmModal
        title={mode === 'EDIT' ? H04.modalEdit.title : H04.modalRegister.title}
        subTitle={
          mode === 'EDIT' ? H04.modalEdit.subTitle : H04.modalRegister.subTitle
        }
        okText={mode === 'EDIT' ? common.button.change : common.button.add}
        cancelText={common.button.cancel}
        className="tw-grid tw-grid-cols-[minmax(100px,_auto),_1fr] [&_p]:tw-font-regular"
      />
    </>
  );
};

const TargetInputBounding = ({
  label,
  children,
  disabled,
  month,
}: {
  label: string;
  children: React.ReactNode;
  disabled: boolean;
  month: string;
}) => {
  return (
    <div
      className={cn('tw-flex tw-items-center  tw-gap-8', {
        'tw-justify-center': ['05', '08', '11', '02'].includes(month),
        'tw-justify-end': ['06', '09', '12', '03'].includes(month),
      })}
    >
      <label className="tw-min-w-[27px] tw-whitespace-nowrap tw-text-end tw-text-[12px] tw-font-bold pc:tw-text-[13px]">
        {label}
      </label>
      {disabled ? (
        <HgbAntdInput
          name=""
          value="－"
          className="tw-w-60 tw-shrink-0 [&_.ant-input]:tw-text-center"
          disabled
        />
      ) : (
        children
      )}
    </div>
  );
};

const ConditionalCmp = ({
  children,
  show,
}: {
  children: React.ReactNode;
  show: boolean;
}): JSX.Element | null => {
  return show ? <>{children}</> : null;
};
